<script>
import { defineComponent, computed } from "vue";
import SidebarRouteLink from "@kit/components/SidebarRouteLink.vue";
import NavMainSearch from "@kit/components/NavMainSearch.vue";
import SideNavDrawerBase from "@kit/components/SideNavDrawerBase.vue";
import { searchWidgetMobile, sideDrawer  } from "@project/themes";
import IconButton from '@kit/components/IconButton.vue'
import { useStore } from "vuex"
import { getCurrentYear } from '@kit/utils/Formats'

export default defineComponent({
  name: "SizeNavDrawer",
  setup() {
    const store = useStore()

    const userAccount = computed(() => {
      return store.state.account
    })

    return { searchWidgetMobile, sideDrawer, userAccount,  getCurrentYear };

  },
  components: {
    SideNavDrawerBase,
    SidebarRouteLink,
    NavMainSearch,
    IconButton
  },
});
</script>

<style>

.ics-side-nav-drawer {
  /* background: rgb(42,73,1);
  background: linear-gradient(90deg, rgba(42,73,1,1) 0%, rgba(44,92,51,1) 47%, rgba(32,93,91,1) 100%); */
  background: rgb(65,82,43);
  background: linear-gradient(90deg, rgba(65,82,43,1) 0%, rgba(44,92,51,1) 47%, rgba(32,93,91,1) 100%);
  opacity: 1;
}


.ics-sidenav-item > a {
  color:white;
  font-size:1.5rem !important;
}
.ics-sidenav-copyright {
  color:white;
  font-size:1.2rem;
}

</style>

<template>
  <SideNavDrawerBase :theme="sideDrawer">
    <template #nav-items="{ active }">

      <div v-if="userAccount" class="sb ics-sidenav-item">
        {{userAccount.name}}
      </div>
      <div class="sb ics-sidenav-item">
        <SidebarRouteLink to="/home">Home</SidebarRouteLink>
      </div>
      <div class="sb ics-sidenav-item">
        <SidebarRouteLink to="/services">Services</SidebarRouteLink>
      </div>
      <div class="sb ics-sidenav-item">
        <SidebarRouteLink to="/about">About Us</SidebarRouteLink>
      </div>
      <div class="sb ics-sidenav-item">
        <SidebarRouteLink to="/contact-us">Contact Us</SidebarRouteLink>
      </div>

      <NavMainSearch :theme="searchWidgetMobile" :active="active" />
      
      <div class="sb sb-text ics-sidenav-copyright">
        © {{ getCurrentYear() }} ICS. All rights reserved
      </div>

    </template>
  </SideNavDrawerBase>
</template>
