////////////////////////////////////////////////////////////////////
//
//  A simple utility to add callbacks for different screen-sizes.
//  Follows the pattern of other utilities, i.e. it conserves 
//  and reuses as much as possible, in this case the 
//  window.matchMedia so that we don't accidentally end up with 
//  thousands of them.
//
////////////////////////////////////////////////////////////////////

const mQKVP = {}

class MQLink {
  constructor(mqChain, callback) {
    this.chain = mqChain
    this.prev = null 
    this.next = null
    this.callback = callback
  }
  destroy() {
    this.chain.removeFromChain(this)
    this.chain = null
    this.prev = null 
    this.next = null 
    this.mqChain = null
  }
}

class MQChain {

  constructor(mediaQuery) {
    this.query = mediaQuery
    this._mqList = window.matchMedia(mediaQuery)
    
    this._runChain = (e) => {
      let  c = this._first 
      const matches = !!e.matches
      while(c) {
        c.callback(matches)
        const next = c.next 
        c = next
      }
    }

    this._mqList.addEventListener("change", this._runChain)
    this._length = 0
    this._first = null
    this._last = null
  }

  addToChain(mqLink) {
    if(this._length == 0) {
      this._first = mqLink
      this._last = mqLink
    } else {

      if(this._last) {
        this._last.next = mqLink
      }
      mqLink.prev = this._last
      this._last = mqLink
    }
    this._length++
  }
  removeFromChain(mqLink) {
    if(mqLink.prev) {
      mqLink.prev.next = mqLink.next
    } else {
      this._first = mqLink.next
    }
    if(mqLink.next) {
      mqLink.next.prev = mqLink.prev
    } else {
      this._last = mqLink.prev
    }
    this._length--
  }
  isEmpty() {
    return (!this._first && !this._last) 
  }
  destroy() {
    this._mqList.removeEventListener("change", this._runChain)
    this._mqList = null 
    this.query = null
  }

}



const _mqQObjToString = ({type, value}) => {
  return `(${type} : ${value})`
} 


//Raw watch and unwatch
//We have a simple set of queryde
//{ type:'max-width', value:'600px'}
export const mqWatch = (query, callback) => {
  //const query = _mqQObjToString(qObj)
  if(!mQKVP[query]) {
    mQKVP[query] = new MQChain(query)
  }
  const mqChain = mQKVP[query]
  const mqLink = new MQLink(mqChain, callback)
  mqChain.addToChain(mqLink)
  return mqLink
}
export const mqUnwatch = (mqLink) => {
  const chain = mqLink.chain
  mqLink.destroy()

  if(chain.isEmpty()) {
    const q = chain.query
    mQKVP[q] = null 
    delete mQKVP[q]
    chain.destroy()
  }
}

//Handy for a whole set. hmmm not sure how to do this.
// const watchSet = (queries, callback) => {
//   const c = (matches) => {
//   }
// }

