import { getResize, getScrollY } from '@kit/utils/EnvironmentHelper'

///////////////////////////////////////////////////////////////////////////////////
//
//  Get the bounds of a dom element asynchronously 
//  If the bounds aren't changing, then you can use the caching option 
//  and it will just use the cached bounds and recompute the y values to 
//  account for changes in the scrolling. 
//
//  The caching assumes that this is an ordinary webpage we we're scrolling 
//  the page up and down. Horizontal scrolling is much less common and I'd 
//  have to build something in where it gets the scrolling position of 
//  a certain element da-de-daa that's for another day. 
// 
//  Thank you you clever bastard
//  https://toruskit.com/blog/how-to-get-element-bounds-without-reflow/
//
//  Another clever bastard:
//  https://stackoverflow.com/questions/5598743/finding-elements-position-relative-to-the-document
//  You can use element.getBoundingClientRect() to retrieve element position relative to the viewport.
//  Then use document.documentElement.scrollTop to calculate the viewport offset.
//  const y = top + scrollY
//
//
///////////////////////////////////////////////////////////////////////////////////

const BoundingClientRectScY = "_504321"
const BoundingClientRectCached = "_95833A"
const BoundingClientResize = "_5kfd02"


export const getBoundingClientRect = async(element, cache) => {

  let forceRefresh = false
  const screenResizeIdx = getResize()
  if(element[BoundingClientResize] != screenResizeIdx) {
    forceRefresh = true
  }

  //If we're caching
  if(cache && !forceRefresh) {
    const bounds = element[BoundingClientRectCached]
    
    if(bounds) {

      //the scrolling might have changed, so we're going to just get the delta
      const newY = getScrollY()
      const oldY = element[BoundingClientRectScY]
      const dY = newY-oldY

      //recompute and return. Can't mutate the domrect it's readonly hence a new object.
      let { top, bottom, right, left, width, height } = bounds 
      bottom -= dY 
      top -= dY 
      return { top, bottom, right, left, width, height}

    }
  }


  //If the cache didn't handle it, then make a new IntersectionObserver here
  const promise = new Promise((resolve) => {

    // new `IntersectionObserver` constructor
    const observer = new IntersectionObserver((entries) => {
      // Loop through all `entries` returned by the observer
      for (const entry of entries) {
        // The `entry.boundingClientRect` is where all the dimensions are stored
        const bounds = entry.boundingClientRect;
        // Log the `bounds` for every element
        const scY = getScrollY()
    
        const { top, bottom, right, left, width, height } = bounds 
        const boundsObj = { top, bottom, right, left, width, height }
        
        if(cache) {
        element[BoundingClientRectScY] = scY
        element[BoundingClientRectCached] = boundsObj
        element[BoundingClientResize] = screenResizeIdx
        }
        
        resolve(boundsObj)
        // Then do whatever with `bounds`
      }
    
      // Disconnect the observer to stop from running in the background
      observer.disconnect();
    });
    
    observer.observe(element);
  })

  return promise

}




export const _getScrollY = async() => {

  //If the cache didn't handle it, then make a new IntersectionObserver here
  const promise = new Promise((resolve) => {

    // new `IntersectionObserver` constructor
    const observer = new IntersectionObserver((entries) => {
      // Loop through all `entries` returned by the observer
      for (const entry of entries) {
        // The `entry.boundingClientRect` is where all the dimensions are stored
        const bounds = entry.boundingClientRect;      
        resolve(bounds)
        // Then do whatever with `bounds`
      }
    
      // Disconnect the observer to stop from running in the background
      observer.disconnect();
    });
    
    observer.observe(document.body);
  })

  return promise

}

