import { getEnv, isLocationLocal, inBrowser } from '@kit/utils/EnvironmentHelper'
import { event, pageview } from 'vue-gtag'


export const googleAnalyticsConfig = () => {
  const measurementID = getEnv("GOOGLE_ANALYTICS_MID")

  return {
  config: { 
      id: measurementID,
      params: {
      transport_type: "beacon"
      }
    }
  }
}

export const gaEvent = (eventString) => {
  if(!isLocal && inBrowser) {
    event(eventString, { method: 'Google' })
  }
}

export const gaPageView = (routeString) => {
  if(!isLocationLocal && inBrowser) {
    pageview(routeString)
  }
}