<script>
import { defineComponent, computed, ref, watch, onBeforeUnmount, onMounted, inject } from "vue"
import NavMainSearch from "@kit/components/NavMainSearch.vue"
import { searchWidgetMain, navbar } from "@project/themes"
import NavBarBase from "@kit/components/NavBarBase.vue"
import { cmsasset } from '@kit/utils/Formats'
import IconButton, { ButtonIconActiveHeight } from "@kit/components/IconButton.vue"
import { useStore } from "vuex"
import { useRoute } from 'vue-router'
import { mqWatch, mqUnwatch } from "@kit/utils/MediaQuery"


export default defineComponent({
  name: "NavBar",
  setup() {
    const store = useStore()
    const atTop = ref(true)
    const navMainSearch = ref(null)
    const navBarBase = ref(null)
    const route = useRoute()
    const themer = inject("themer")

    //ALEX-RESKIN
    const navbarTheme = themer(navbar)
    //const 

    let backgroundStyle = "linear-gradient(-90deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.7) 60%, rgba(0,20,100,0.3) 100%)"
          
    const userAccount = computed(() => {
      return store.state.account
    })
    const inMobile = computed(() => {
      return store.state.inMobile
    })
    const drawerOut = computed(() => {
      return store.state.drawerOut
    })

    let mq = null
    onMounted(() => {
      // mqWatch("(min-width: 600px)", (matches) => {
      //   console.log("ALEX HEY IS THIS FIRING ON PAGE LOAD?")
      // })

      mq = mqWatch("(max-width : 600px)", (matches) => {
        if(atTop.value) {
          const { navText } = route.meta.navColors

          if(matches) {
            backgroundStyle = "linear-gradient(-90deg, rgba(255,255,255,0.7) 0%, rgba(0,20,100,0.3) 30%, rgba(0,20,100,0.4) 100%)"
            navBarBase.value.reskin([
              { key:'logoSrc', value:cmsasset("/2023/04/Praesidio-Icon_text-only-light.png") },
              { key:'hamburgerButtonClasses', value:`ics-nav-hamburger-nav-button--attop nav-text-${navText}` },
              { key:'outerContainerClasses', value:"ics-nav-outer ics-navbar-boxshadow--attop" },
              { key:'backgroundColor', value:backgroundStyle },
              { key:'animateBackgroundChange', value:false },
            ])
            
          } else {
            backgroundStyle = "linear-gradient(-90deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.7) 60%, rgba(0,20,100,0.3) 100%)"
            navBarBase.value.reskin([
              { key:'logoSrc', value:cmsasset("/2023/04/Praesidio-Icon_text-only-light.png") },
              { key:'hamburgerButtonClasses', value:`ics-nav-hamburger-nav-button--attop nav-text-${navText}` },
              { key:'outerContainerClasses', value:"ics-nav-outer ics-navbar-boxshadow--attop" },
              { key:'backgroundColor', value:backgroundStyle },
              { key:'animateBackgroundChange', value:false },
            ])
          }
        }
      })
    })
    onBeforeUnmount(() => {
      mqUnwatch(mq)
    })

    const colors = {
      "white-green": "#d2ffe2",
      "white-blue": "#d9e8fb",
      "white":"#ffffff",
      "dark-blue":"#243770",
    }

    //If the drawer comes out, then we're going to reskin
    //the ui to the top state.
    watch(drawerOut, (newVal, _oldVal) => {
      if(newVal) {
        topOff()
      }
    })

    const topOn = (_e) => {
      atTop.value = true

      if(route.meta && route.meta.navColors) {
        const { navText } = route.meta.navColors

        //Change the logo to the light version
        //ALEX-RESKIN
        // navBarBase.value.reskin({
        //   logoSrc:cmsasset("/2023/04/Praesidio-Icon_text-only-light.png"),
        //   hamburgerButtonClasses: `ics-nav-hamburger-nav-button--attop nav-text-${navText}`,
        //   outerContainerClasses: "ics-nav-outer ics-navbar-boxshadow--attop",
        //   backgroundColor: backgroundStyle,
        //   animateBackgroundChange:false,
        // })
        navBarBase.value.reskin([
          { key:'logoSrc', value:cmsasset("/2023/04/Praesidio-Icon_text-only-light.png") },
          { key:'hamburgerButtonClasses', value: `ics-nav-hamburger-nav-button--attop nav-text-${navText}` },
          { key:'outerContainerClasses', value: "ics-nav-outer ics-navbar-boxshadow--attop" },
          { key:'backgroundColor', value: backgroundStyle },
          { key:'animateBackgroundChange', value:false },
        ])

        //Change the search button
        navMainSearch.value.reskinSearchButton({
          foreground: colors[navText],
          iconDimension: {
            style: ButtonIconActiveHeight,
            active: '30px'
          },
        })
      }
    }
    const topOff = (_e) => {
      atTop.value = false

      if(route.meta && route.meta.navColors) {

        //Change the logo to the dark version
        //ALEX-RESKIN////
        // navBarBase.value.reskin({
        //   logoSrc:cmsasset("/2023/04/Praesidio-Icon_text-only-dark.png"),
        //   hamburgerButtonClasses: "ics-nav-hamburger-nav-button",
        //   outerContainerClasses: "ics-nav-outer ics-navbar-boxshadow"
        // })
        navBarBase.value.reskin([
          { key:'logoSrc', value:cmsasset("/2023/04/Praesidio-Icon_text-only-dark.png") },
          { key:'hamburgerButtonClasses', value:"ics-nav-hamburger-nav-button" },
          { key:'outerContainerClasses', value:"ics-nav-outer ics-navbar-boxshadow" }
        ])
        ////

        //chang the search button back.
        navMainSearch.value.reskinSearchButton({
          foreground: "#555555", 
          iconDimension: {
            style: ButtonIconActiveHeight,
            active: '20px'
          },
        })

      }
    }

    const navSubOptions = computed(() => {
      const { navOptions, navText } = route.meta.navColors
      return atTop.value ? `sb sb-v navbar-subitems nav-sub-options--attop nav-sub-options-${navOptions} nav-text-${navText}` : 'sb sb-v navbar-subitems nav-sub-options'
    })

    const nonDropDownNav = computed(() => {
        let navTextClass = ""
        if(route.meta && route.meta.navColors) {
          const { navText } = route.meta.navColors
          navTextClass = ` nav-text-${navText}`
        }
        return atTop.value ? `sb sb-h sb-align-cv navbar-item--attop nav-option--attop${navTextClass}` : 'sb sb-h sb-align-cv navbar-item nav-option'
    })

    const dropDownNav = computed(() => {
      let navTextClass = ""
        if(route.meta && route.meta.navColors) {
          const { navText } = route.meta.navColors
          navTextClass = ` nav-text-${navText}`
        }
      return atTop.value ? `sb sb-h sb-align-cv navbar-item--attop navbar-dropdown nav-option--attop${navTextClass}` : 'sb sb-h sb-align-cv navbar-item navbar-dropdown nav-option' 
    })
 

    return { 
      searchWidgetMain, navBarBase, 
      navbarTheme, userAccount, atTop, 
      topOn, topOff, navMainSearch, 
      inMobile, navSubOptions, 
      nonDropDownNav, dropDownNav,
      drawerOut
    };
  },
  components: {
    NavMainSearch,
    NavBarBase,
    IconButton
  },
});
</script>

<style>

/*-------------*\
   Navbar CSS 
\*-------------*/

.ics-logo-wrapper {
  width:300px !important;
}

.ics-navbar-boxshadow {
  box-shadow: 5px 5px 10px rgba(0,0,0, 0.5);
}
.ics-navbar-boxshadow--attop {
  box-shadow: none;
}

.ics-nav-hamburger-nav-button--attop, .ics-nav-hamburger-nav-button  {
  margin-top:10px; 
  margin-bottom:10px;
  margin-right:20px; 
  width: 50px;
  height:30px;
  cursor:pointer;
}
.ics-nav-hamburger-nav-button {
  border-top:4px solid #444444; 
  border-bottom:4px solid #444444;
}
.ics-nav-hamburger-nav-button--attop::after, .ics-nav-hamburger-nav-button::after {
  content:"";
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  height:4px;
  left:0;
  right:0;
}
.ics-nav-hamburger-nav-button::after {
  background: #555555;
}


.nav-option, .nav-option--attop {
  cursor: pointer;
  padding:20px;
  z-index:2;
}

.navbar-item > a {
  white-space: nowrap;
}

.nav-sub-options > a, 
.nav-sub-options > a.router-link-exact-active,
.nav-option > a, 
.nav-option > a.router-link-exact-active {
  color: var(--text-regular);
  font-weight: normal;
  text-decoration: none;
  padding:5px 10px 5px 10px;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}



/* deep-grey-green nav-sub-options */
.nav-sub-options--attop.nav-sub-options-deep-grey-green {
  background:rgba(49, 54, 48, 0.95);
}

/* deep-grey-blue nav-sub-options */
.nav-sub-options--attop.nav-sub-options-deep-grey-blue {
  background: rgba(33, 36, 62, 0.95);
}


/* dark-blue nav text */
.ics-nav-hamburger-nav-button--attop.nav-text-dark-blue::after {
  background: var(--dark-blue); 
}
.ics-nav-hamburger-nav-button--attop.nav-text-dark-blue {
  border-top:4px solid var(--dark-blue); 
  border-bottom:4px solid var(--dark-blue); 
}
.nav-sub-options--attop.nav-text-dark-blue > a, 
.nav-sub-options--attop.nav-text-dark-blue > a.router-link-exact-active,
.nav-option--attop.nav-text-dark-blue > a, 
.nav-option--attop.nav-text-dark-blue > a.router-link-exact-active {
  color: var(--dark-blue);
  font-weight: normal;
  text-decoration: none;
  padding:5px 10px 5px 10px;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}
.nav-option--attop.nav-text-dark-blue {
  color: var(--dark-blue);
} 



/* white nav text */
.ics-nav-hamburger-nav-button--attop.nav-text-white::after {
  background: var(--white); 
}
.ics-nav-hamburger-nav-button--attop.nav-text-white {
  border-top:4px solid var(--white); 
  border-bottom:4px solid var(--white); 
}

.nav-sub-options--attop.nav-text-white > a, 
.nav-sub-options--attop.nav-text-white > a.router-link-exact-active,
.nav-option--attop.nav-text-white > a, 
.nav-option--attop.nav-text-white > a.router-link-exact-active {
  color: var(--white);
  font-weight: normal;
  text-decoration: none;
  padding:5px 15px 5px 15px;
}
.nav-option--attop.nav-text-white {
  color: var(--white);
} 


/* white-blue nav text */
.ics-nav-hamburger-nav-button--attop.nav-text-white-blue::after {
  background: var(--white-blue); 
}
.ics-nav-hamburger-nav-button--attop.nav-text-white-blue {
  border-top:4px solid var(--white-blue); 
  border-bottom:4px solid var(--white-blue); 
}

.nav-sub-options--attop.nav-text-white-blue > a, 
.nav-sub-options--attop.nav-text-white-blue > a.router-link-exact-active,
.nav-option--attop.nav-text-white-blue > a, 
.nav-option--attop.nav-text-white-blue > a.router-link-exact-active {
  color: var(--white-blue);
  font-weight: normal;
  text-decoration: none;
  padding:5px 15px 5px 15px;
}
.nav-option--attop.nav-text-white-blue {
  color: var(--white-blue);
} 


.nav-sub-options > a, 
.nav-sub-options > a.router-link-exact-active {
  padding: 10px 15px 15px 15px;
}
.nav-sub-options--attop > a, 
.nav-sub-options--attop > a.router-link-exact-active {
  padding: 10px 15px 15px 15px;
}

.nav-sub-options---attop > a, 
.nav-sub-options--attop > a.router-link-exact-active {
  padding: 10px 15px 15px 15px;
  color:var(--green);
}

.nav-option > i, .nav-sub-options > a > i, .nav-option--attop > i, .nav-sub-options--attop > a > i  {
  border-radius: 2px;
  display: inline;
  height: 4px;
  left: 0;
  position: absolute;
  right: 0;
  top: calc(50% + 12px);
  transform: scaleX(0);
  transition: transform .3s ease;
  z-index: 0;
}
.nav-option > i, .nav-sub-options > a > i {
  background: var(--green);
}
.nav-option--attop.nav-text-white > i, .nav-sub-options--attop.nav-text-white > a > i {
  background: var(--white);
}
.nav-option--attop.nav-text-white-blue > i, .nav-sub-options--attop.nav-text-white-blue > a > i {
  background: var(--white-blue);
}
.nav-option--attop.nav-text-dark-blue > i, .nav-sub-options--attop.nav-text-dark-blue > a > i {
  background: var(--dark-blue);
}


.nav-option:hover > i, .nav-sub-options > a:hover > i, .nav-option--attop:hover > i, .nav-sub-options--attop > a:hover > i {
  transform: scaleX(80%);
}


.ics-nav-main-searchoption {
  width:100px !important;
}
.ics-nav-main-search-iconbutton {
  width:40px !important;
}
.ics-nav-main-search-widget-button {
  width:100px !important;
}


/*-------------*\
   Search UI CSS 
\*-------------*/


/* Search UI CSS */
.ics-search-modal-window {
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 1000;
  min-height: 335px;
  padding-top: 107px;
  padding-bottom: 50px;
  background-color: rgba(6, 42, 70, 0.92);
  background-image: linear-gradient(257deg, rgba(19, 95, 129, 0.68), rgba(6, 42, 70, 0.73));
  opacity: 1;
}

.ics-search-results-outer h1 {
  font-size:1.5rem;
}

.ics-main-search-widget-headline {
  font-size:1.2em;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
}
.ics-mobile-search-widget-headline {
  font-size:1.5em;
  color:white;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
}

input.nav-mobile-search-input:focus, input.nav-main-search-input:focus, input.nav-main-search-nonmodal-input:focus  {
  background-color:#e6ebe1 !important;
}

input.nav-mobile-search-input, input.nav-main-search-input, input.nav-main-search-nonmodal-input {
  border-style: none none solid;
  background-color: #EEEEEE;
  font-family: "Roboto", sans-serif;
  color: #000;
  font-size: 18px;
  font-weight: 300;
  padding:10px !important;
}
input.nav-main-search-nonmodal-input {
  /* border:1px solid #CCCCCC; */
  /* border-bottom: 2px solid #516b38; */
  border-radius: 3px;
}
@media (max-width : 960px) {
  input.nav-mobile-search-input {
    font-size: 25px;
  }
}

.nav-main-search-ui-container {
  background-color:white;
  border-radius:5px;
}
.nav-mobile-search-ui-container {
  background-color:white;
  border-radius:5px;
}

.nav-main-search-nonmodal-completion-ui-container {
  box-shadow: 0px 5px 5px rgba(0,0,0, 0.5); 
}

/*modal styles*/
.ics-search-results-prevnext-wrapper {
  padding:0px !important;
  margin-top:10px !important;
}
.ics-search-result {
  padding: 0px !important;
}
.ics-results-block {
  padding:10px !important;
  background: #F2F2F2;
}


/*nonmodal widget*/
.ics-search-modal-search-nonmodal-button {
  width: 50px !important;
}
.ics-search-nonmodal-headline {
  font-weight:400;
  color: var(--dark-text);
}

/*styles for the mobile side-nav drawer ui*/
.ics-mobile-search-button {
  width: 50% !important;
}
.ics-mobile-search-button svg {
  transform: rotate(45deg);
}
.ics-mobile-search-headline {
  font-size: 36px;
  line-height: 36px;
  font-weight: 400;
  color:white; 
}
.ics-mobile-search-wrapper {
  padding-top:20px;
  padding-right:20px;
}
.ics-mobile-search-completion-wrapper, .ics-main-search-completion-wrapper  {
  color:black;
  font-size: 20px;
}
.ics-mobile-completion-result {
  padding:7px;
}
.ics-main-completion-result {
  padding:7px;
  font-size: 18px;
}
.ics-mobile-completion-result:hover, .ics-main-completion-result:hover  {
  background-color:#EEEEEE;
}

</style>

<template>
    <NavBarBase ref="navBarBase" :theme="navbarTheme" @TopOn="topOn" @TopOff="topOff">
      <template v-slot:nav-items>
          <div :class="nonDropDownNav">
            <router-link class="sb" to="/">Home</router-link><i></i>
          </div>
    
          <div :class="nonDropDownNav">
            <router-link class="sb" to="/services">Services</router-link><i></i>
          </div>
          <!-- <div :class="dropDownNav">
            Services<i></i>
            <div :class="navSubOptions">
              <router-link class="sb" style="white-space:nowrap;" to="/services/managed-services">Managed Services<i class="sb"></i></router-link>
              <router-link class="sb" style="white-space:nowrap;" to="/services/consulting">Consulting<i class="sb"></i></router-link>
              <router-link class="sb" style="white-space:nowrap;" to="/services/support">Support<i class="sb"></i></router-link>
            </div>
          </div> -->

          <div :class="nonDropDownNav">
            <router-link class="sb" to="/about">About Us</router-link><i></i>
          </div>
          <div :class="nonDropDownNav">
            <router-link class="sb" to="/contact-us">Contact Us</router-link><i></i>
          </div>
          <div v-if="userAccount" :class="nonDropDownNav">
            {{userAccount.name}}<i></i>
          </div>
          <!-- <div :class="atTop ? 'sb sb-h sb-align-cv navbar-item--attop nav-option--attop' : 'sb sb-h sb-align-cv navbar-item nav-option'">
            {{userAccount.name}}<i></i>
          </div> -->
          <NavMainSearch ref="navMainSearch" :theme="searchWidgetMain" />
          
      </template>
    </NavBarBase>

</template>
