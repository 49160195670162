
<script>
import { defineComponent, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { footerButton } from '@project/themes'
import IconButton from '@kit/components/IconButton.vue'
import { inBrowser } from '@kit/utils/EnvironmentHelper'
import { cmsasset } from '@kit/utils/Formats'

import { useMSAL } from '@kit/utils/auth/msal/useMSAL'
import { loginRequest } from "@kit/utils/auth/msal/MSALConfig"
import { userIsAuthenticated } from "@kit/utils/auth/msal/userIsAuthenticated"
import { getCurrentYear } from '@kit/utils/Formats'

export default defineComponent({
  name: "Footer",
  setup() {
    const store = useStore()
    const locations = computed(() => store.state.locations)

    const footerIcon = computed(() => {
      return cmsasset('https://localhost/cms-service/uploads/2023/04/Praesidio-Icon.png')
    })

    const isAuthenticated = userIsAuthenticated()

    const { instance, accounts } = useMSAL()
    
    const msalLogin = () => {
     instance.loginPopup(loginRequest);
    }
    const msalLogout = () => {
     instance.logoutPopup({
       mainWindowRedirectUri: "/"
     });
    }
    /////////

    onMounted(() => {
      if(inBrowser) {
        const key = localStorage.getItem("alex-testing-123")
        console.log("ALEX-DEBUG onMounted: Footer.vue! IS THIS DAMN THING FIRING OR NOT? "+key)
        if(key) {
          const accountFromStorage = localStorage.getItem(key)
          console.log("ALEX-DEBUG: When we reload the page after logging in, this should be showing us the account with the key:"+key)
          console.log(accountFromStorage)

          //There's thing in here I've seen where the account is just a bit flaky and we have to 
          //handle this case where it's authenticated, we're getting the account, but for some 
          //reason the userAccount isn't available. "hydrate" the account from the local storage
          if(isAuthenticated.value && accountFromStorage && !userAccount.value) {
            store.dispatch("setAccount", accountFromStorage)
          }

        }
      }
    })

    
    const userAccount = computed(() => {
      console.log("ALEX-DEBUG: Step 4: userAccount. returning "+JSON.stringify(store.state.account || null))
      return store.state.account || null
    })

    const userAccountForStore = computed(() => {
      console.log("ALEX-DEBUG: Step 1: userAccountForStore. ")
      const accountsVal = accounts.value
      if(accountsVal && accountsVal.length > 0) {
        console.log("ALEX-DEBUG: Step 2: userAccountForStore. returning account info: "+JSON.stringify(accountsVal[0]))
        return accountsVal[0]
      } 
      return null
    })

    const localStorageKey = computed(() => {
      console.log("ALEX-DEBUG: Step 5 localStorageKey")
      if(userAccount.value) {
        //console.log("ALEX-DEBUG: Step 6 returning: "+`${userAccount.value.homeAccountId}-login.windows.net-${userAccount.value.tenantId}`)
        //the storage key is like this
        //<homeAccountId>-login.windows.net-<tenantId>
          //tenantId
        return `${userAccount.value.homeAccountId}-login.windows.net-${userAccount.value.tenantId}`
      }
      return null
    })

    watch(localStorageKey, () => {
      console.log("ALEX-DEBUG: Step 7: watcher for localStorageKey")
      if(inBrowser && localStorageKey.value) {
        //console.log("ALEX-DEBUG: Step 8: ALEX-FOR-MONDAY-2022-10-17")
        console.log("ALEX-DEBUG Ok when we click login, we should see this appear shortly after:"+localStorageKey.value)
        localStorage.setItem("alex-testing-123", localStorageKey.value)
      }
    }, {immediate:true})

    watch(userAccountForStore, () => {
      //console.log("ALEX-DEBUG: STEP 3: watcher for userAccountForStore")
      if(userAccountForStore.value) {
        console.log("ALEX-DEBUG: STEP 4: watcher for userAccountForStore: dispatching the setAccount")
        store.dispatch("setAccount", userAccountForStore.value)
      }  
    }, {immediate:true})
    watch(isAuthenticated, () => {
      if(!isAuthenticated.value) {
        store.dispatch("setAccount", null)
      }
    })

    return { 
      locations, 
      footerButton, 
      footerIcon,

      msalLogin, 
      msalLogout, 
      accounts, 
      userAccount, 
      userAccountForStore, 
      localStorageKey, 
      
      inBrowser,
      getCurrentYear
    }

  },
  components: {
    IconButton
  }
});
</script>

<style>
  .footer-button svg.sb-abox {
    transform:translate(-10px, -3px) !important; 
  }
</style>

<style scoped>

  .roboto {
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 900;
  }

  .logo-box {
    width:140px;
    height:80px;
  }
  .logo-img {
    width:100%;
  }

  .footer-top {
    padding:0px;
  }

  .footer-top-links {
    padding:30px;
  }
  .footer-bottom-links {
    padding:30px; 
    padding-top:0px;
  }


  @media (max-width : 960px) {
    .footer-button {
      width:300px !important;
    }

    .footer-top {
      padding-bottom:20px;
    }
    .footer-top-links {
      padding-bottom:20px;
    }
    .logo-box {
      width:100%;
    }
    .logo-img {
      width:200px;
    }

  }






</style>

<template>

  <footer class="sb sb-v" style="color:#F2F2F2; margin-top:50px;">
    <div class="sb sb-h column-outer-800-100 sb-align-ch" style="background: #373a6e;">
      
      <div class="sb sb-h sb-explicit sb-ltm-h2v sb-greedy-1 sb-g20 footer-top">

        <router-link to="/" class="sb sb-h sb-explicit sb-ltm-align-ch logo-box" style="background:#FFFFFF;">
          <img class="sb sb-explicit logo-img" style="max-height:100%; height:auto; object-fit:contain;" :src="footerIcon" alt="Praesidio Icon">
        </router-link>

        <ul class="sb sb-h sb-greedy sb-uniform-4 sb-ltm-h2v sb-align-cv sb-ltm-alt2 sb-ltm-align-ch">
          <li class="sb sb-text" style="white-space:nowrap; text-align:center;">McKinleyville, CA</li>
          <li class="sb sb-text" style="white-space:nowrap; text-align:center;">San Diego, CA</li>
          <li class="sb sb-text" style="white-space:nowrap; text-align:center;">Phone: (707) 839-0100</li>
          <li class="sb sb-text" style="white-space:nowrap; text-align:center;">Fax: (707) 839-0200</li>
        </ul>

      </div>

    </div>
    <div class="sb sb-h column-outer-800-100 sb-align-ch" style="background:linear-gradient(90deg, rgba(235,64,52,1) 0%, rgba(16,17,102,1) 50%);">
      <div class="sb sb-v sb-explicit" style="height:10px;"></div>
    </div>
    <div class="sb sb-h column-outer-800-100 sb-align-ch" style="background: #4c5491;">
      <div class="sb sb-v sb-explicit">
        <div class="sb sb-h sb-g20 sb-ltm-h2v sb-ltm-alt2 sb-ltm-align-ch sb-uniform-3 footer-top-links">
          <IconButton class="footer-button sb" to="/" icon="home" :theme="footerButton" text="Home"/>
          <IconButton class="footer-button sb" to="/about" icon="info" :theme="footerButton" text="About Us"/> 
          <IconButton class="footer-button sb"  to="/contact-us" icon="mail" :theme="footerButton" text="Contact"/>         
        </div>
        <div class="sb sb-h sb-g20 sb-ltm-h2v sb-ltm-alt2 sb-ltm-align-ch sb-uniform-3 footer-bottom-links">
          <IconButton class="footer-button sb" to="/services/managed-services" icon="services" :theme="footerButton" text="Managed Services"/>
          <IconButton class="footer-button sb" to="/services/consulting" icon="consultant" :theme="footerButton" text="Consulting"/> 
          <IconButton class="footer-button sb" to="/services/support" icon="support" :theme="footerButton" text="Support"/>         
          <IconButton class="footer-button sb" @buttonClick="msalLogin" icon="support" :theme="footerButton" text="Login"/>   
          <IconButton class="footer-button sb" @buttonClick="msalLogout" icon="support" :theme="footerButton" text="Logout"/>   
        </div>
        <div class="sb sb-h sb-align-2right sb-ltm-align-ch sb-gr" style="font-size:0.8rem; padding:10px;">
          <b class="sb">© {{getCurrentYear()}} Infinite Consulting Services (build: v4)</b>
        </div>
      </div>
    </div>
  </footer>


</template>