import { googleCSE } from '@kit/utils/APIV1'
import { sleep } from '@kit/utils/Sleep'

const basePrefetch = {

async googleCSE({ searchTerm, startFrom}) {
    //bail out if the search term is absent
    if(!searchTerm || searchTerm.trim() == "") {
      return null
    }

    await sleep(5000)

    return await googleCSE(searchTerm, startFrom)

    // await sleep(3000)
    // return {
    //   "kind":"customsearch#search",
    //   "url":{"type":"application/json",
    //   "template":"https://www.googleapis.com/customsearch/v1?q={searchTerms}&num={count?}&start={startIndex?}&lr={language?}&safe={safe?}&cx={cx?}&sort={sort?}&filter={filter?}&gl={gl?}&cr={cr?}&googlehost={googleHost?}&c2coff={disableCnTwTranslation?}&hq={hq?}&hl={hl?}&siteSearch={siteSearch?}&siteSearchFilter={siteSearchFilter?}&exactTerms={exactTerms?}&excludeTerms={excludeTerms?}&linkSite={linkSite?}&orTerms={orTerms?}&relatedSite={relatedSite?}&dateRestrict={dateRestrict?}&lowRange={lowRange?}&highRange={highRange?}&searchType={searchType}&fileType={fileType?}&rights={rights?}&imgSize={imgSize?}&imgType={imgType?}&imgColorType={imgColorType?}&imgDominantColor={imgDominantColor?}&alt=json"},
    //   "queries":{
    //     "request":[
    //       {"title":"Google Custom Search - instagram","searchTerms":"instagram","count":10,"startIndex":1,"inputEncoding":"utf8","outputEncoding":"utf8","safe":"off","cx":"e606577d683fb44f9"}
    //     ]
    //   },
    //   "searchInformation":
    //   {"searchTime":0.11315,"formattedSearchTime":"0.11","totalResults":"0","formattedTotalResults":"0"},
    //   "items":[
    //     { "title":"Euler", "htmlSnippet":"<b>Euler 0</b", "link":"/", "pagemap":{}},
    //     { "title":"Dirac", "htmlSnippet":"<b>Euler 1</b", "link":"/", "pagemap":{}},
    //     { "title":"Cauchy", "htmlSnippet":"<b>Euler 2</b", "link":"/", "pagemap":{}},
    //     { "title":"Feynman", "htmlSnippet":"<b>Euler 3</b", "link":"/", "pagemap":{}},
    //   ],
    // }

  }

}

export default basePrefetch