import VueSmoothScroll from 'vue3-smooth-scroll'
import PrimeVue from 'primevue/config'
import createMSAL from '@kit/utils/auth/msal/MSAL'

export const modifyProvideApp = () => {
  return null
}

export const modifyApp = (app, router) => {
  app.use(VueSmoothScroll, {
    updateHistory: false
  })
  app.use(PrimeVue, { ripple:true })
  createMSAL(app, router)

}
