/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\

  Some basic math like rounding

  Author Alex Lowe

\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const _2PI = 2*Math.PI
const _2PId360 = _2PI/360
const _360d2PI = 360/_2PI

/**
 * round a number to numDec number of decimals. handy dandy.
 *
 */
export const roundTo = (input, numDec) => {
  var m = Math.pow(10,numDec);
  return Math.round(input*m)/m;
}

/**
 * returns a random float between two boundaries, inclusive
 *
 */
export const randomFloat = (floor, ceiling) => {
  return  (ceiling - floor)*(Math.random()) + floor;
}
        
/**
 * returns a random integer between two boundaries, inclusive.
 *
 */
export const randomInt = (floor, ceiling) => {
  return Math.round(randomFloat(floor,ceiling));
}

export const deg2rad = (deg) => {
  //2PI     X
  //___  = ___
  //360    deg
  return _2PId360 * deg
}

export const rad2deg = (rad) => {
  return _360d2PI * rad
}

//https://stackoverflow.com/questions/4780837/is-there-an-equivalent-to-e-pagex-position-for-touchstart-event-as-there-is-fo
export const pointerEventToXY = (e) => {
  const out = {x:0, y:0};
  if(e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel'){
    var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
    out.x = touch.pageX;
    out.y = touch.pageY;
  } else if (e.type == 'mousedown' || e.type == 'mouseup' || e.type == 'mousemove' || e.type == 'mouseover'|| e.type=='mouseout' || e.type=='mouseenter' || e.type=='mouseleave') {
    out.x = e.pageX;
    out.y = e.pageY;
  }
  return out;
};