<script>
import { defineComponent, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { dispatchEvent } from '@kit/utils/EventBus'

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\

  SidebarRouteLink

  Coordinates with the store so the links still close the drawer even 
  if they match the current path. Ordinarily this is done by the navigation
  guard on the router itself, except wah wah it won't fire for a path if
  you're already on that page. Well fine but we still need the drawer to
  close in those cases, so that's what this component does.

\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export default defineComponent({
  name: "SidebarRouteLink",
  props: ["to"],
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const isLink = computed(() => {
      return (route.path != props.to)
    })
    const handleClick = (e) => {
      e.preventDefault()
      if(isLink.value) {
        router.push(props.to)
      } 
      //let the world know that we're not really navigating to a new route. 
      //still, this is specifically for the side-nav-drawer and scrolling pages
      //like health-services
      else {
        dispatchEvent('navigateToCurrentPage', false)
      }
    }
    return { isLink, handleClick }
  },
});
</script>

<template>
  <a :href="to" @click="handleClick"><slot/></a>
</template>

