<script>
import { defineComponent, watch, onMounted, ref, computed } from 'vue'
import IconButton from '@kit/components/IconButton.vue'
import { bodyScrollOn, bodyScrollOff } from '@kit/utils/Scroller'
import { inBrowser } from '@kit/utils/EnvironmentHelper'
import Portal from '@kit/components/Portal.vue'
import { useStore } from 'vuex'
import { mergeClassesTheme, mergeStylesTheme } from '@kit/utils/Formats'

export const ModalPositionCenter = 1
export const ModalPositionTopCenter = 2 
export const ModalPositionBottomCenter = 3


/**

  export const blueModal = {

    //does the scrolling get suspended when the modal is open?
    suspendScrolling: true,

    //the style for the background. If both this and the background class are absent,
    //then it won't use a background at all.
    backgroundStyle: "opacity:0.8; background-color:#FFFFFF;",
    backgroundClass: "myclass",

    windowStyles: { append: "background:#FFFFFF; box-shadow:6px 6px 5px #999999; border-radius:10px; border-left:2px solid #DDDDDD; border-top:2px solid #DDDDDD;" },
    windowClasses: { append:"seven-tribes-search-modal-window" },

    //The percent widths of the view-width and view-height
    //for the modal to take up, as well as the same numbers 
    //for the mobile breakpoint.
    //These can be overriden by the props with the same names.
    vw: 80,
    vh: 80,
    mvw: 80,
    mvh: 80,

    //The position of the modal.
    //ModalPositionCenter, ModalPositionTopCenter, ModalPositionBottomCenter
    position: ModalPositionCenter

    //If false, then this won't perform positioning with the the vw,vh,mvw,mvh or position properties,
    //and it will leave the positioning up to whatever css styles and classes you give it.
    windowAutoPosition: false

    closeButton: {
      ...blueButton, 
      icon:"rounded-close-x_50", 
      iconDimension: {
        style: ButtonIconActiveHeight,
        active: "20px"
      },
      background: {
        ...blueButton.background,
        borderRadius: "50%"   
      },
    }
  }

 **/




export default defineComponent({
  name: "Modal",
  props: {
    "modelValue": { required:true, default:false },
    "suspendScrolling": { required:false, default:undefined },
    "theme": { required:true },
    "vw": { required: false, default: null },
    "vh": { required: false, default: null },
    "mvw": { required: false, default: null },
    "mvh": { required: false, default: null },
    "googleCSEUsecase": { required: false, default:false },
  },
  setup(props, context) {

    const store = useStore()

    const inMobile = computed(() => store.state.inMobile)

    const doTeleport = ref(false)

    const defaults = {}

    //Get a set of options. Give the props the priority.
    const getOptionSet = (which) => {
      const o1 = props[which]
      const o2 = props.theme[which] 
      const o3 = defaults[which]
      if(o1 !== undefined) {
        return o1
      }
      if(o2 !== undefined) {
        return o2
      }
      if(o3 !== undefined) {
        return o3 
      }
      return undefined
    }

    //This took days to figure out. Teleport doesn't play nicely with SSR. (Nothing seems to)
    //I tried using my inBrowser environmental variable with v-if to try and limit the behavior 
    //of Teleport to the client-side only, and I had read plenty of docs claiming that was possible,
    //but it never worked. No, the key is to use the onMounted hook. I don't pretend to understand, but 
    //if you use inBrowser, it wants to render during the critical period where Vue is comparing the 
    //client v-dom to the rendered server output. Or something.
    onMounted(() => {
      doTeleport.value = true

      if(!props.theme) {
        throw new Error("Modal.vue: expecting a theme.")
      }
    })

    const closeModal = (e) => {
      //close the window by emitting this event, and this is standard mechanics for the 
      //v-model directive. It's a little confusing IMO but that's how it works.
      context.emit('update:modelValue', false);
    }

    //Perform body scroll mechanics. These are designed to override the mechanics 
    //of the google cse ui that does it's own thing.
    watch(() => props.modelValue, async(newVal, _oldVal) => {

      const isGoogleCSE = props.googleCSEUsecase
     
      if(getOptionSet("suspendScrolling")) {
      
        if(newVal) {
          bodyScrollOff({ saveScroll:true, correctRight:true })
        } else {
          bodyScrollOn({ afterCallback() {

            //This is for Google Custom Search Engine. Google's UI 
            //adds a class to the body to disable scrolling. This function 
            //needs to override that, so we have this option in here to 
            //zero out the body class to eliminate google's influence.
            if(isGoogleCSE) {
              document.body.setAttribute("class","")
            }

          }})
        }
      }
    })

    const dimensions = computed(() => {
      if(props.theme.windowAutoPosition !== false) {
        const { vw:vw0, vh:vh0, mvw:mvw0, mvh:mvh0 } = props
        const { vw:vw1, vh:vh1, mvw:mvw1, mvh:mvh1, position } = props.theme

        const vw = vw0 || vw1 
        const vh = vh0 || vh1
        const mvw = mvw0 || mvw1 
        const mvh = mvh0 || mvh1 

        const w = inMobile.value ? mvw : vw 
        const h = inMobile.value ? mvh : vh 
        const left = (100 - w)/2

        if(position == ModalPositionCenter) {
          return `width:${w}vw; height:${h}vh; left:${left}vw; top:${(100 - h)/2}vh;`
        } else 
        if(position == ModalPositionBottomCenter) {
          return `width:${w}vw; height:${h}vh; left:${left}vw; bottom:0vh; top:auto;`
        } else 
        if(position == ModalPositionTopCenter) {
          return `width:${w}vw; height:${h}vh; left:${left}vw; top:0vh;`
        }
      }
      return ''
    })

    const hasBackground = computed(() => {
      return props.theme.backgroundStyle || props.theme.backgroundClasses
    })
    const backgroundClass = computed(() => {
      return props.theme.backgroundClasses ? `modal-background ${props.theme.backgroundClasses}` : 'modal-background'
    })
    const backgroundStyle = computed(() => {
      return props.theme.backgroundStyle || ""
    })
    //merge the classes with the theme classes. Note that we do not allow overrides of the hidden class.
    const windowClass = computed(() => {
      const hidden = props.modelValue ? "" : "modal-hidden "
      const classes = `${hidden}${mergeClassesTheme('modal-window', props.theme.windowClasses )}`
      return classes
    })
    //const windowStyle = computed(() => {
      //return props.theme.windowStyle ? `${dimensions.value} ${props.theme.windowStyle}` : dimensions.value
      //const style = `${mergeStylesTheme(dimensions.value, props.theme.windowStyles )}`
    //})



    return { closeModal, inBrowser,
      doTeleport, dimensions, 
      inMobile, hasBackground, 
      backgroundStyle, backgroundClass, windowClass,
      mergeStylesTheme
    }

  },
  components: {
    Portal,
    IconButton
  }
});
</script>

<style scoped>
  .close-button {
    position:absolute; 
    top:0px; 
    left:auto; 
    right:0px;
  }

  .modal-background {
    position:fixed; 
    top:0px; 
    left:0px; 
    width:100vw; 
    height:100vh; 
    z-index:1001; 
  }

  .modal-window {
    position:fixed; 
    z-index:1002; 
  }

  .modal-hidden {
    display:none;
  }

</style>

<template>
    
  <Teleport v-if="doTeleport" to="#teleport-target">
    <div v-if="modelValue && hasBackground" :class="backgroundClass" :style="backgroundStyle" @click="closeModal"></div>
    <div :class="windowClass" :aria-hidden="!modelValue" aria-role="dialog" :style="mergeStylesTheme(dimensions, theme.windowStyles )">
      <div class="sb sb-v sb-content-strict-height sb-g20 sb-greedy-1">
        <div class="sb sb-text">
          <slot name="headline"></slot>
          <div class="close-button">
            <IconButton :theme="theme.closeButton" @buttonClick="closeModal"/>
          </div>
        </div>
        <slot name="body"></slot>
      </div>
    </div>
  </Teleport>

</template>