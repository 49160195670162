import { createStore } from 'vuex'
import { modifyState, modifyMutations, modifyActions } from '@project/modify/store'

export const store = createStore({
  state () {
    return {
      drawerOut: false,
      drawerMotion: false,
      drawerOutImmediate: false,
      inMobile: false,
      ...modifyState()
    }
  },
  mutations: {
    setDrawerOut(state, inOrOut) {
      state.drawerOut = inOrOut
    },
    setDrawerOutImmediate(state, inOrOut) {
      state.drawerOutImmediate = inOrOut
    },
    setDrawerMotion( state, trueOrFalse) {
      state.drawerMotion = trueOrFalse
    },
    setScreenMobile(state, inMobile) {
      state.inMobile = inMobile
    },
    ...modifyMutations()
  },
  actions: {
    screenThreshold({commit}, inMobile) {
      commit("setScreenMobile", inMobile)
    },
    ...modifyActions()
  }
  
})
