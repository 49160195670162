import { home } from '@project/api/home/v1'
import { section } from '@project/api/sections/v1'
import { page as contactUsPage } from '@project/api/contact-us/v1'
import { aboutUs } from '@project/api/about-us/v1'
import { services } from '@project/api/services/v1'

export default {
  async home() {
    return await home()
  },
  async section(params) {
    return await section(params.which)
  },
  async contactUs() {
    return await contactUsPage()
  },
  async aboutUs() {
    return await aboutUs()
  },
  async services() {
    return await services()
  }
}

