/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\

  A universal builder utility to abstract away all the hideous details away from 
  prefetching data to "hydrate" a page with the initial data and meta-data tags for SEO,
  which allows us to write universal apps that will work in either SSR or non-SSR.

  This is pretty complex. Use the various projects to see examples of this in action and you'll
  get a feel for what this is supposed to do.

  The idea is this. One first-load from the server in SSR, the page html will have all of the
  components hydrated by the prefetch-data, as well as whatever metatags you add. When you're
  navigating around the app, the Vue framework will be mutating the components according to your
  router, i.e. the page source code isn't changing, but the DOM is changing, and in that case 
  the hydration is working its magic client-side. Clearly if you refresh the page, you'll get a 
  new render from the server.

  The Hydrate and Meta utilities together replace the vue-query and vue-meta dependencies 
  that were here before. I could not get those to work sensibly and not for lack of trying.

  The hydrate method is available with inject("hydrate") in the setup function.
  You call the hydrate method in the setup function, and you pass it an object 
  with these options:

  priority (optional): set the priority. This is important for meta-tags. Two components could both have
      a description meta-tag, and the priority will dictate which one makes it to the page.

  prefetch (optional): An array of string or objects. Each one corresponds to an item in the project prefetch 
      file. If it's a string, then it's the name of prefetch task. If it's an object, then it has the form:
      {
        //name of the task
        name: "name-of-prefetch-task",
        params() {
          return { "foo":"bar" } //or whatever return object or value you want
        }
      } 

      If object, then the params function will feed a parameter to the prefetch task. This is useful if you 
      want to use a query parameter from the url for the task, which is pretty common.

  mountData (optional): whatever other auxilliary data you want the page to have when it loads, return the 
      data asynchronously in this hook

  mounted (optional): whatever you want the page to do when it's mounted and all the data is available, both 
      prefetch and mount data. You shouldn't need to do any fetching here.

  metadata (optional): A callback that takes the metaManager and the prefetch data is the two arguments.
      use the api exposed by the metaManager to add meta-data to the page. Computed properties based on the 
      prefetch reference are available to use. In SSR, these meta-tags will be rendered by the server. 

  preload (optional): An array of files to preload or, a callback that takes the prefetch data as its 
      parameter, and returns an array of strings, which are urls of files to preload. This will be handled 
      output to the page in SSR-mode and it will improve performance of the first-load of the page. Not sure 
      how to deal with second-loads, so that's for another day. The server will look at the names of the 
      files and figure out what kind they are and what attributes are needed in the tag. 

  watch (optional): A callback which takes a state-object that counts how many components in the component
      tree have completed each lifecycle, and a watchReveal function. The idea is that you can check the
      state to see if a particular condition has been fulfilled, and if so, then use the watchReveal 
      function to trigger a nice reveal animation.

  extraJS (optional): An array of extra or external javascript dependencies to include. This is handy in the
      event that the page has some annoying things like custom jquery scripts that you have to include.

  See the hydrate() method for more detailed explanations of each of these hooks and how to use them.
      
  So here's a longer explanation of the design and goals of this code that I breezed through above:

  When the page is requested from the server, we want it to have all of the html already in there, meta-tags preload tags,
  markup and all the rest. There's two reasons for that. 
      1: It makes the page load faster so the first time the user opens 
        up your website on new browser window everything is just "there" and we're not waiting for javascript to render everything.
      2: SEO. It's much better for bots to be able to see the markup.

  After the page has loaded, you're going to click on a link in the page, and then normal Vue routing will take over. This is to 
  say that all subsequent page loads will not be rendered server-side, they'll be just dynamically rendered client-side. But 
  we still want the meta-tags to change. So this imposes of bifurcation on this code, which is that we have "first-load" and 
  "second-load". First load of course refers to the first time the page is loaded, and "second-load" refers to every time after
  that the page is rendered dynamically after the first-load because the user clicked a route button on the page. 

  Another thing is that hydrate() is called on multiple components in the component-tree. This presents two challenges:
      1: We don't want to have to worry about duplicate prefetching data. We want them to all be able to use whatever data it want
        for prefetching and it will "just work" and detect duplicates
      2: When everything is done hydrating, we want to write the metadata to the dom 

  So how to solve these issues? For #1, what we do is the projects have a file "prefetch.js". It lists all of the available prefetching services
  in a kvp store, and we use the key to cache the data from the service so that multiple hydrations can refer to them without
  worrying about them making uneccessary trips to the server. 
  
  For #2, we have to be clever, and we exploit the way that Vue orders 
  the onMounted calls, which is that it proceeds in post-order fashion, i.e. leaf-to-root. The leaves get the onMounted lifecycle 
  event first and the root gets it last. What we do therefore is this: on hydrate we call incrementTotal() before any mounting is done.
  Then onMounted starts getting called in post-order, and each time we incrementStack(). When the stack matches the total and that 
  signifies that all of the components in the component tree that called hydrate are done hydrating. All of the data is fetched and 
  that means that all of the data necessary to build the meta-tags has been gathered and catalogued. Then we call mapToDOM on the 
  meta-manager.


  Author Alex Lowe

\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { onServerPrefetch, onMounted, onBeforeMount, getCurrentInstance, nextTick } from 'vue'
import { SSR } from '@kit/utils/EnvironmentHelper'
import { ref, computed } from 'vue' 
import { inBrowser } from '@kit/utils/EnvironmentHelper'
import projectPrefetch from '@project/prefetch'
import basePrefetch from '@kit/utils/HydrateBasePrefetch'
import Meta from '@kit/utils/Meta'
import { reveal } from '@kit/utils/Animate'

//the first state, which is cold i.e. it wasn't fetched yet
export const COLD = 4

//something has gone out to fetch and we're waiting
export const WAIT = 0

//we have success
export const SUCCESS = 1

//we have error
export const ERROR = 2

//group together all of the prefetch stuff the base the project-specific prefetch
const prefetchResources = {...basePrefetch, ...projectPrefetch}

export const Hydrate = class Hydrate {


  /**
   * @param {*} params 
   * See above notes for the params
   */
  constructor(params, hydrationContext) {
    const instance = getCurrentInstance();
    this.instance = instance
    this.params = params

    //the shared context that all of the hydration instances use
    this.hydrationContext = hydrationContext

    //the name of the component tied to this instance
    this.name = instance.type.name || "none"

    //The Date.now() when the hydration started
    this.hydrationStarted = 0

    //The reference for the reveal
    this.revealRef = null

    //This is an object that we feed to the watchRoot function below. A specialized
    //method for revealing a component once a particular lifecycle condition is met
    this._reveal = (arg1, arg2, arg3) => {
      this.watchReveal(arg1, arg2, arg3)
    }
  }

  markTimeHydrationStarted() {
    this.hydrationStarted = Date.now()
  }
  getTimeDelta() {
    return Date.now() - this.hydrationStarted
  }

  incrementTotal() {
    this.hydrationContext.numTotal++
  }
  async incrementStack() {
    this.hydrationContext.numStack++
    if(this.hydrationContext.numStack == this.hydrationContext.numTotal) {
      await this.hydrationContext.metaManager.mapToDOM()
    }
  }


  getResult() {
    return this.result
  }
  getPrefetchErrorMessage() {
    return this.prefetchErrorMessage
  }
  getMountErrorMessage() {
    return this.mountErrorMessage
  }


  async getAllPrefetchData(ids, fetchFresh) {
    const allTasks = []
    const allData = {}
    const mainHydrationData = this.hydrationContext.mainHydrationData

    let idList = ids

    for(let i=0; i<idList.length; i++) {
      const idItem = idList[i]
  
      //If the id entry is an object, then we're expecting it to have a name property 
      //and also a param callback which outputs params to be 
      const isDynamic = idItem.constructor == Object
      const id = isDynamic ? idItem.name : idItem 
      const task = prefetchResources[id]

      let paramFunction = isDynamic ? idItem.params || null : null
      let cacheFunction = isDynamic ? idItem.cache || null : null
      const mustFetchFresh = paramFunction && fetchFresh 

      if(isDynamic && cacheFunction == null && paramFunction == null) {
        throw new Error("Error: Hydrate: prefetch: if you have a param function then you need a cache function")
      }

      if(mainHydrationData[id] && !mustFetchFresh) {
        allData[id] = mainHydrationData[id]
      } else 
      if(task) {

        let params = paramFunction ? paramFunction() : null
        const wrapped = async() => { 
          const data = await this.runTask(task, params, id, cacheFunction) 

          allData[id] = data
          mainHydrationData[id] = data
        }
        allTasks.push(wrapped())
      }
    }
    await Promise.all(allTasks)
    return allData
  }


  getAllPrefetchDataFlat(idList) {
    const allData = {}

    let missingData = false
    const mainHydrationData = this.hydrationContext.mainHydrationData

    for(let i=0; i<idList.length; i++) {
      const idItem = idList[i]
      const isDynamic = idItem.constructor == Object
      const id = isDynamic ? idItem.name : idItem 
      const data = mainHydrationData[id]

      if(data) {
        allData[id] = data
      } else 
      if(data === null || data === undefined) {
        missingData = true 
        break
      }
    }

    return missingData ? null : allData
  }

  /**
   * Runs a task or returns the cached value depending on the cache strings. This is so 
   * that as your browsing in second-load scenarios, the page will load the prefetch requirements
   * and cache them for later so that when you come back to a page it will appear instantly 
   * without reloading, and this is also that multiple components can list the same prefetch 
   * requirement, but it will only actually load once.
   * 
   * @param {function} task. Required. The task function from the prefetch object settings
   * @param {object} params. Optional. The optional parameter object 
   * @param {string} id. Required. the id string, the name of the prefetch function
   * @param {function} cache. Optional. The cache function from the prefetch object settings
   * @returns 
   */
  async runTask(task, params, id, cache) {
    let secondKeyPart = ''
      if(cache) {
        const fromCacheFunction = cache(params)
        if(fromCacheFunction.constructor == Array) {
          secondKeyPart = fromCacheFunction.join('-')
        } else 
        if(fromCacheFunction.constructor == String || !isNaN(fromCacheFunction)) {
          secondKeyPart = fromCacheFunction
        } else {
          throw new Error("Hydrate: I got a result from a prefetch cache function for id:"+id+" of type:"+(typeof fromCacheFunction))
        }
      }
    const cacheKey = `${id}-${secondKeyPart}`
    const { taskCache } = this.hydrationContext
    const data = taskCache[cacheKey]
    if(data) {
      return data
    } else {
      const freshData = await task(params)
      taskCache[cacheKey] = freshData
      return freshData
    }
  }

/**
 * Report the lifecycles to the root. 
 * 
 * @param {string} lifecycleCode. required. one of: prefetch, preload, mount
 * 
 */
  reportLifecycleToRootHydrate(lifecycleCode) {
    const { lifecycleEntries } = this.hydrationContext
    const { name } = this
    const { watch:watchRoot } = this.params

    let entry = lifecycleEntries[name]
    if(!entry) {
      entry = { num:0, prefetch:0, mount:0, preload:0 }
      lifecycleEntries[name] = entry
    }

    lifecycleEntries[name][lifecycleCode]++
    
    if(watchRoot) {
      watchRoot(lifecycleEntries, this._reveal)
    }

  }


  /**
   * Loop through and create the preload assets. Right now we're primarily concerned with images.
   * 
   * @param {array or function} preloadAssets. Required.
   * @param {object} data. Optional. The prefetch data
   * 
   */
  static async createPreloadAssets(preloadAssets, data) {
    const preloadFiles = preloadAssets.constructor == Array ? preloadAssets : preloadAssets(data)
    const len = preloadFiles.length

    for(let i=0; i<len; i++) {
      const preloadFile = preloadFiles[i]
      const src = preloadFile.constructor == Object ? preloadFile.src : preloadFile
  
      //TODO: need to figure out a way to handle preload assets other than images.
      let preloadImg = await new Promise((resolve, reject) => {
        let img_ = new Image()
        img_.onload = () => {
          resolve()
        }
        img_.onerror = () => {
          reject()
        }
        img_.src = src
        return img_
      })
    
      preloadImg = null
    }
    
  }


  /**
   * A special reveal function for use in the watch function.
   * A few goals here,
   * 1: Don't do this on the server, hence the inBrowser wrapper.
   * 2: Don't animate unless the hydration has take over a certain amount of time.
   *   See, we don't have a convenient way to know whether or not the resources have 
   *   all been preloaded before or not. We can't count on hydration instances existing
   *   between navigation events, so we can't do the usual trick of using uids as 
   *   de-duplication keys in the root hydration-context data.
   * 
   *   So we have to do this heuristically. Typically if the resources have been 
   *   preloaded before then new hydration will go really really fast, like under 20ms. 
   *   We do a simple time comparison, compare it to a threshold and voila we can tell
   *   whether or not to perform the animation or skip it.
   *   
   * @param {DOMElement} element. Required. The dom-element
   * @param {function} condition. Required. The condition function. Returns true and false 
   *    based on the entries passed into the watch callback.
   * @param {object} options. Optional.
   *   {
   *     //The threshold of time. If the time elapsed is less than, then it's considered
   *     //to be an already-loaded situation. Otherwise, perform the reveal animation.
   *     threshold: 20
   * 
   *     //Animejs wants to place display:none on the element, probably something about opacity.
   *     //Anyway, this will set the display to foil that. Default is flex
   *     display: flex
   *    
   *     //This will add in an extra delay. Default is 0.
   *     delay: 0
   *   }
   */
  async watchReveal(templateRef, condition, options) {

    if(inBrowser) {

    const { threshold, display, delay } = options || {}
    const _threshold = threshold || 20
    const _display = display || "flex"
    const _delay = delay || 0

      if(condition()) {
        await nextTick()
        const _element = templateRef.value.getInnerWrapper()
        templateRef.value.setRevealed(true)
        this.revealRef.value = true
        const skipAnimation = (this.getTimeDelta() < _threshold)
        reveal(_element, skipAnimation, _display, _delay)
      }
    }
  }
    

  /**
   * hydrate
   * Perform all of universal acrobatics for both the server and client in both 
   * first-load and second-load scenarios.
   * 
   * prefetch: ------------------------
   * 
   * prefetch:[PrefetchItem, PrefetchItem ... ]
   * 
   * PrefetchItem:
   *  String: key matching one of the keys in the object returned in the prefetch.js module, which contains all of the
   *  available prefetch services that a project can use,
   * 
   *  or
   *  
   *  Object: e.g.:
   * 
   *     {
   *       name:"section", 
   *       params() {
   *         return { which: route.name }
   *       },
   *       cache(params) {
   *         return params.which
   *       } 
   *     }
   *  
   *  If it's an object, then it needs three fields:
   *  name: The key matching one of the keys in the prefetch.js object.
   *  params: an object of optional parameters. This object will be fed to the matching function in prefetech.js
   *  cache: a caching string made from the parameter object. This is so that multiple hydrations can list the same 
   *  prefetch service, but it will only be requested from the server once.
   *  
   * 
   * preload: -------------------------
   * PreloadItem: 
   *   a url string
   *   e.g. https://xyz.com/some-file-to-preload.png, https://xyz.com/some-file-to-preload.js
   *   in which case it will take action depending on the .png, .js extension
   * 
   *   Or an object:
   *   { src="https://xyz.com/some-file-to-preload.png" ... other options here }
   *   any other options must be keys with string values, and those will be the attribute-value pairs 
   *   that will be added to the html tag.
   *   
   *   Example 1:
   *   preload: [ PreloadItem1, PreloadItem2 ]
   * 
   *   Example 2:
   *   preload: ( prefetchData ) => {  
   *      //do things with prefetch, return an array  
   *      return [ PreloadItem1, PreloadItem2 ]
   *   }
   * 
   * extraJS: -----------------------
   *   
   *   Works the same as the preload option: 
   *   It can be an array or a function that takes the prefetch data as the argument, and returns the data as an array.
   *   In either case, the array is an array of object | string. Each of which represents an extra javascript dependency to 
   *   be written to the page either in the head or the body sections. 
   *   
   *   If it's a string, it will be treated like a src include and appended to the end of the body.
   *
   *   If object, all of the properties head,proxy,code,src will be taken into account. It will need 
   *   either a src or code property and the other properties will be turned into 
   *   attribute-value pairs for the script tag. 
   * 
   *   head: true or false.
   * 
   *      If true, this script tag will appear in the head
   *      If false, this script tag will appear at the end of the body tag where javascript files are ofen located.
   * 
   *   proxy: string or null/undefined.
   * 
   *      If string, then we're going to use the proxy endpoint on the server to avoid these damned cors problems.
   *      The string is a type of proxy that we can do. The allowed values are:
   * 
   *         "google-cse"  this will proxy the google cse code at https://cse.google.com/cse.js
   * 
   *      If you use the proxy flag, the src will be just the url parameters for the end,
   *         e.g. mything=val1&mything2=val2
   *          
   *      For proxy "google-cse", use this string for the src `google_cse_id=${getEnv("GOOGLE_CSE_ID")}`
   * 
   *   code: string or null
   * 
   *      If string, then this is interpreted as a block of javascript which will be written to the page.
   *      If null, then it will default to src
   * 
   *   src: 
   * 
   *      If string, then this will be the src for the script. 
   *      If this is null and code is null, then nothing will be done with that extraJS record.
   *      If you're using the proxy flag, then just include the get parameters here.
   *         e.g. mything=val1&mything2=val2
   * 
   *   The src can be something from another domain, or it can be a file that you put into the assets/vendor folder.
   *   If the latter, then the src has to be like /vendor/mycoolfile.js and it can't be dynamic. 
   *   The reason for this is that we're doing string replacements for /vendor/(.*?).js on the webpack side,
   *   so that they become /vendor/mycoolfile.js. This works in tandem with another webpack thing that copies all of the 
   *   assets/vendor files over to the public folder, and this allows us to have javascript files that we can include with 
   *   script tags, but are outside of the build files and are publicly available.
   *   
   *   On first-load situations, xjs will be included as script elements in the body or head. On second-load situations, 
   *   these will be loaded dynamically. We'll guard against duplicates so they won't get loaded uneccessarily. 
   *   
   *   Note that if you change or upload new files to assets/vendors, you'll need to rebuild the project with the setting 
   *   FRONTEND_BUILD: true.
   *   
   *   Note that there's no way to unload javascript files once they've been loaded. So don't abuse this ability.
   * 
   * 
   * externalCSS: ---------------------
   *    
   *   An array of objects or string, which will be interpreted as css urls to include in the head of the page as link elements
   *   Object options:
   *   
   *     { type:'webfont', url:'https://xyz' }
   *     { type:'css', url:'https://xyz' } 
   * 
   *   If object, then 'webfont' is going to apply some optimizations from here:
   *   https://csswizardry.com/2020/05/the-fastest-google-fonts/ 
   *   So that the font is not render-blocking for better lighthouse scores.
   *   Note that you do not need the &display=swap, e.g.:
   *      https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap
   *   So just use the url:
   *      https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900
   *   And then the Hydrate code will take care of the rest. 
   * 
   *   String options: 
   * 
   *     'https://xyz'
   * 
   *   If string, then it's going to treated as a regular render-blocking css file
   * 
   * 
   * watch: ---------------------------
   * 
   * A callback that takes lifecycle state and a special watchReveal function as the parameters. 
   * Example:
   * 
   * const { prefetch, preload } = hydrate({ 
   *   prefetch:["home"],
   *   preload:[
   *     pathname('@images/ICS-Isometric-Computer.png'),
   *     pathname('@images/ICS-corner-backgrounds_green-stripes.png'),
   *     pathname('@images/ICS-corner-backgrounds_green2-dots.png')
   *   ],
   *   watch({ LeadFeature }, watchReveal ) {
   *     watchReveal(rootEl.value, () => LeadFeature.preload == 1)
   *   }
   * })
   * 
   * watchReveal is a function that gets passed in which coordinates with a component called Reveal.
   * <Reveal ref="rootEl">
   *   stuff goes here.
   * </Reveal> 
   * 
   * The idea is this. LeadFeature gets everything preloaded. So the images start loading before the page 
   * processes, so that they go faster. When the preload assets are loaded and created, then the preload 
   * part of this hydration's lifecycle is done, and so what happens is that this gets logged in a state 
   * object cataloguing all of the lifecycle events. There's only one LeadFeature in the component tree, 
   * so we're saying that as soon as the one LeadFeature component gets preloaded we're going to fire 
   * off the watchReveal function, which will trigger a reveal animation in the Reveal component. 
   * The goal here is to get rid of all the ugly interstitial loading states where images aren't loaded yet.
   * So we wait for the images and things to get loaded and THEN we show the stuff inside the Reveal component.
   * 
   * 
   * The "P" utility function solves two problems.
   * 1. Lets us query the prefetch data safely without any awful 'can't access property of undefined'
   *    and so we avoid whole awful messes of computed properties.
   * 
   * 2. Lets us avoid tedious repetition of nested property paths by aliasing with either a string or an integer,
   *   e.g. P(14)
   * 
   * To use the "P" utility function:
   * in setup:
   * 
   * Make a function, not a computed property
   * const bios = () => {
   *   if(prefetch.value && prefetch.value.aboutUs) {
   *    //do stuff here, return array
   *   }
   *   return array
   * }
   * 
   * Then, in your template, use some integer or string to refer to the 
   * results. 14 in this case. It's arbitrary.
   * 
   *  <div v-if="P(14,bios)" class="sb sb-explicit sb-v sb-g30">
   *     <div v-for="bioRow in P(14)" class="sb sb-h sb-ltm-h2v">
   *     </div>
   *  </div>
   * 
   * Another template example. The P utility helps us avoid repeating all
   * those hideous pathways.
   * 
   *  <QuoteFeature v-if="P(13, () => prefetch.aboutUs.quote[0])"
   *     :backgroundImage="`${P(13).background_image.guid}`" 
   *     :backgroundOverlayStyle="P(13).background_overlay_style"
   *     :scrollFxOptions="P(13).scroll_options"
   *     :attribute="P(13).attribution"
   *     :colorCode="P(13).color_code"
   *     :ariaImgLabel="P(13).aria_image_label">
   *       {{ P(13).quote_text }}
   *   </QuoteFeature>
   * 
   * 
   */
  hydrate() {
    const { priority, prefetch:prefetchData, mountData, mounted, metadata, preload:preloadAssets, extraJS, externalCSS } = this.params
    const { mainHydrationData, metaManager } = this.hydrationContext

    this.markTimeHydrationStarted()

    this.prefetchErrorMessage = ''
    this.mountErrorMessage = ''
    this.priority = priority || 0
    this.result = null

    metaManager.recordFullURL()
    metaManager.setPriority(this.priority)

    this.prev = null
    this.next = null

    const refreshState = ref(COLD)
    const prefetch = ref(null)
    const preload = ref(false)
    const mount = ref(null)
    const perr = ref(false)
    const merr = ref(null)
    const reveal = ref(false) 
    const didMount = ref(false)
    this.revealRef = reveal

    //A handy template utility
    //
    const pStore = {}
    const P = (param1, param2) => {

      if(typeof param1 === "function") {
        const pathFunc = param1
        let result = null 
        try {
          result = prefetch.value ? pathFunc() : null
        } catch(e) {
          //take no action. just swallow the error
        }    
        return result
      } else {

        const idx = param1 
        const pathFunc = param2

        if(pathFunc) {
        let result = null 
          try {
            result = prefetch.value ? pathFunc() : null
          } catch(e) {
            //take no action. just swallow the error
          }

          pStore[idx+""] = result
          return result
        } else {
          return pStore[idx+""] || null
        }

      }
    }

    //hydrate is called by different components in the component tree. However, we only want to 
    //map the meta-data to the DOM once, after all of the prefetching data is available. So we increment 
    //the number of hydrations here, and when the number of prefetch completions 
    this.incrementTotal()

    //This is another resource returned by the hydrate function. It lets the component 
    //refresh the hydration data, and this is commonly done in situation where the page's
    //data is linked to some variable in the url path like/this/:someArticle
    //This increments the total, refreshes the prefetch data anew, and then increments the
    //stack which forces the metadata to refresh.
    const refresh = async() => {

      refreshState.value = WAIT

      //little edge-case here. I called refresh as part of a reactive blah blah 
      //and get an error from recordFullURL complaining that inject can't be used 
      //outside the setup function. Tracked it down to EnvironmentHelper which uses
      //the useRoute function. So we're just doing this.
      const url = inBrowser ? window.location.href : "" 

      metaManager.recordFullURL(url)
      this.incrementTotal()

      let data = null

      if(prefetchData) {
        try {
          data = await this.getAllPrefetchData(prefetchData, true)
          prefetch.value = data
          refreshState.value = SUCCESS
        } catch(e) {
          this.prefetchErrorMessage = e.message
          perr.value = true
          refreshState.value = ERROR
        }
      } else {
        refreshState.value = SUCCESS
      }

      if(metadata) {
        metadata(metaManager, data)
      }

      if(prefetchData) {
        let timer = setInterval(async() => {
          if(data || perr.value) {
            clearInterval(timer)
            await this.incrementStack()
          }
        },100)

      } else {

        await this.incrementStack()
      }

    }


    if(SSR) {

      onBeforeMount(async() => {

        //set the data from the server on our Meta manager utility, if such data exists
        if(mainHydrationData && mainHydrationData.__metadata) {
          metaManager.setData(mainHydrationData.__metadata, mainHydrationData.__metadedup)        
        }

        if(extraJS) {
          const extraJSFiles = extraJS.constructor == Array ? extraJS : extraJS(data)
          metaManager.addExtraJSFiles(extraJSFiles)
        }

        //if there's a prefetch data-hook, then this hydration is expecting
        //data. It might be there if this was a direct page load, but if this is 
        //a navigation between components, then it will not be loaded yet. So in that
        //case, we're going to fetch it. calculate the meta-data in any case.
        if(prefetchData) {
          
          //first, we have to try and fetch this data from the cache of prefetch data 
          //that was added to the page during the SSR rendering. This is important when 
          //the page first loads. If we do an await here, we're going to get hydration 
          //mismatch errors. This SSR process is really sensitive right here.
          let data = this.getAllPrefetchDataFlat(prefetchData)

          //Ok so when we're in a second-page-load situation, we're probably going to 
          //have to fetch the data anew. Sure, you landed and a page from the outside and 
          //got the full SSR output string, but then you clicked a button and navigated 
          //somewhere else and now we have to get the data for that new page. It's going 
          //to be different from the data that the page was initially loaded with.
          //so it all the data comes back from the cache but we're past the first-page-load
          //then we're going to zero this out so that it fetches it 
          let fresh = false 
          if(data && getNav(this.hydrationContext) > 1) {
            fresh = true
            data = null
          }
    
          //if any data is missing for any of the ids that we fed to getAllPrefetchDataFlat,
          //then we're going to assume that the page is not a first-load page. In that case,
          //vue is mutating the dom with new components like usual and it's safe to await 
          //data-services.
          if(!data) {
            try {
              data = await this.getAllPrefetchData(prefetchData, fresh)
            } catch(e) {
              this.prefetchErrorMessage = e.message
              perr.value = true
            }
          }

          prefetch.value = data
          if(metadata) {
            metadata(metaManager, data)
          }

        } else
        if(metadata) {
          metadata(metaManager, null)
        }

      })


      //the two states for the client
      const prefetchState = computed(() => {
        if(prefetch.value) {
          return SUCCESS
        } else 
        if(perr.value) {
          return ERROR
        } else {
          return WAIT
        }
      })
      const mountState = computed(() => {
        if(mount.value) {
          return SUCCESS
        } else 
        if(merr.value) {
          return ERROR
        } else {
          return WAIT
        }
      })


      //Run the mountData hook if there's any auxilliary data-services that need to get pinged 
      //when the page starts up. The "leaves" of the component-tree get this first, the root gets 
      //it last, and that's the deal with the incrementStack stuff. It lets us call the mapToDOM 
      //when all the data and components are loaded. If we naively said "run mapToDOM when id=App 
      //gets the onMounted" then we're going to ignore the possibility that other child components 
      //are still loading data internally. Can't have that. We have to wait until the root is mounted
      //and then we can map all of the metadata to the dom.
      onMounted(async() => {

        if(mountData) {
          try {
            mount.value = await mountData()
          } catch(e) {
            this.mountErrorMessage = e.message
            merr.value = true
          }
        }

        //map the meta-data to the dom.
        //You're saying wait a minute this is SSR what's the point?
        //It is, but many navigations don't request a whole new page from the server, they're "second-load", 
        //not "first-load", so we have to make sure that the meta data and preload resources are still being 
        //added to the page Ok so why the timer? Ugh- because prefetch isn't guaranteed to exist and the watch 
        //method doesn't work here. Don't ask me why. TODO: why?
        if(prefetchData) {

          let timer = setInterval(async() => {
            if(prefetch.value) {

              this.reportLifecycleToRootHydrate("prefetch")

              //hook in here and create the preload assets
              if(preloadAssets) {
                Hydrate.createPreloadAssets(preloadAssets, prefetch.value).then(() => {
                  preload.value = true
                  this.reportLifecycleToRootHydrate("preload")
                })
              }

              clearInterval(timer)
              await this.incrementStack()
              await nextTick()

              //finally, call the mounted hook. All the data should be ready to go and the page should 
              //be hydrated at this point. safe to trigger animations and interactions and fancy stuff now.
              if(mounted) {
                mounted()
              }
              
            } 
          },100)

        } else {

          if(preloadAssets) {
            await Hydrate.createPreloadAssets(preloadAssets, null)
            preload.value = true
            this.reportLifecycleToRootHydrate("preload")
          }

          await this.incrementStack()

          //finally, call the mounted hook. All the data should be ready to go and the page should 
          //be hydrated at this point. safe to trigger animations and interactions and fancy stuff now.
          if(mounted) {
            mounted()
          }

        }

        didMount.value = true

      })

      //Here's the onServerPrefetch hook, which does the data-loading and then fetches the metatdata.
      //This always fires on the server-side, never on the client-side. Doesn't matter if it's a first 
      //second-load situation. The goal of this hook is to put together a json object with all of the 
      //data from the prefetch data-services that were specified. That way when the page-loads in a 
      //first-load situation, those services don't have to fire client-side, because the data is already 
      //written to the page. And it's not just the data, we're then going to use the data and get all 
      //of the preload files and extra-js files and put those on the meta-manager as well, that way we 
      //can not only have the data preloaded, but we can mark files are resources for preloading as well. 
      //The whole idea is that on first-load, the page should have as much stuff as possible ready to
      //rock and roll right away.
      //Here's an example. You want a fancy header image to be available as soon as possible. But you 
      //don't know what the image is, because it comes from your wordpress endpoint along with the 
      //article text. So you want to mark that data-service as prefetch. Then you use can use that 
      //data to find the url to the image and mark that as a preload file. 
      onServerPrefetch(async() => {

        let data = {}

        //TODO: This works, but I don't know if this refresh flag is necessary ever since I moved
        //the a scheme there the Hydrate instance use a local context in the render function on the server.
        //This was an issue when the data was persisting. I'm leaving it here because at some point 
        //we're going to want to tackle caching issues and it will be useful for that.
        //Ok so here's what we're going to do. If we're on the server, we're going to force a refresh 
        //of the dynamic prefetch tasks.
        if(prefetchData) {
          const refresh = !inBrowser
          data = await this.getAllPrefetchData(prefetchData, refresh)
        }

        prefetch.value = data

        //Add in the preload assets. Calculate the urls from the prefetch data if the preloadAssets is a function
        if(preloadAssets) {
          const preloadFiles = preloadAssets.constructor == Array ? preloadAssets : preloadAssets(data)
          metaManager.addPreloadFiles(preloadFiles)
          mainHydrationData.__metadata = metaManager.getMetaData()
        }
        
        //Add in the extra-js files. Calculate the urls from the prefetch data if the extraJS is a function.
        if(extraJS) {
          const extraJSFiles = extraJS.constructor == Array ? extraJS : extraJS(data)
          metaManager.addExtraJSFiles(extraJSFiles)
          mainHydrationData.__metadata = metaManager.getMetaData()
        }

        //add in the external css files.
        if(externalCSS) {
          const xCSS = externalCSS.constructor == Array ? externalCSS : externalCSS(data)
          metaManager.addExternalCSS(xCSS)
          mainHydrationData.__metadata = metaManager.getMetaData()
        }

        if(metadata) {
          metadata(metaManager, data)
          //The first component to do server-side prefetch rendering will 
          //begin writing to the meta-data objects in the shared Meta instance in the 
          //hydration context. The subsequent components will continue to write to it. 
          //This is to say that we can just keep overwriting these __metadata references 
          //on the mainHydrationData. Each call to getMetaData() will return the data from 
          //the Meta module, which is up-to-date.
          mainHydrationData.__metadata = metaManager.getMetaData()
          mainHydrationData.__metadedup = metaManager.getDuplicationJSON()
        }

      })

      this.result = { prefetch, mount, didMount, prefetchState, mountState, preload, reveal, refresh, refreshState, P }

    } 
    
    //else, we're in ordinary non-SSR. In this case everything is just 
    //rendered and loaded on the client-side. 
    else {

      onBeforeMount(async() => {

        if(prefetch.value) {
          prefetch.value = null
        }

        if(extraJS) {
          const extraJSFiles = extraJS.constructor == Array ? extraJS : extraJS(data)
          metaManager.addExtraJSFiles(extraJSFiles)
        }

        if(prefetchData) {

          try {
          const data = await this.getAllPrefetchData(prefetchData, true)
            //just like in SSR, set the prefetch value before we call the metadata callback
            //and that way any computed properties based on the prefetch data will be 
            //available inside the metadata function.
            prefetch.value = data

            this.reportLifecycleToRootHydrate("prefetch")
            //hook in here and create the preload assets
            if(preloadAssets) {
              Hydrate.createPreloadAssets(preloadAssets, prefetch.value).then(() => {
                preload.value = true
                this.reportLifecycleToRootHydrate("preload")
              })
            }

            if(metadata) {
              metadata(metaManager, data)
            }
          } catch(e) {
            this.prefetchErrorMessage = e.message
            perr.value = true
          }

        } 
        //else, no prefetch data, so just run the metadata function
        else {

          if(preloadAssets) {
            await Hydrate.createPreloadAssets(preloadAssets, null)
            preload.value = true
            this.reportLifecycleToRootHydrate("preload")
          }

          if(metadata) {
            metadata(metaManager, null)
          }
        }

      })

      //The two states for the client
      const prefetchState = computed(() => {
        if(prefetch.value) {
          return SUCCESS
        } else 
        if(perr.value) {
          return ERROR
        } else {
          return WAIT
        }
      })

      const mountState = computed(() => {
        if(mount.value) {
          return SUCCESS
        } else 
        if(merr.value) {
          return ERROR
        } else {
          return WAIT
        }
      })

      onMounted(async() => {

        if(mountData) {
          try {
            mount.value = await mountData()
          } catch(e) {
            this.mountErrorMessage = e.message
            merr.value = true
          }
        }

        //increment the stack, same as we do in the SSR case.
        if(prefetchData) {

          let timer = setInterval(async() => {
            if(prefetch.value) {
              clearInterval(timer)
              await this.incrementStack()

              if(mounted) {
                mounted()
              }
            } 
          },100)
        } else {

          await this.incrementStack()
          await nextTick()
          
          if(mounted) {
            mounted()
          }
        }

        didMount.value = true
        
      })

      this.result = { prefetch, mount, didMount, prefetchState, mountState, preload, reveal, refresh, refreshState, P }
    }

  }

}

/**
 * @param {object} hydrationContext Required. The context provided by the hydrationContext() method
 * @returns a method to be injected into the Vue app that will make hydration capabilities available to all components
 *  
 */
export const hydrationFactory = (hydrationContext) => {

  const hydrateMethod = (obj) => {
    const h = new Hydrate(obj, hydrationContext)
    h.hydrate()
    const res = h.getResult()
    return res
  } 

  return hydrateMethod
}


/**
 * @returns a shared hydration context for all of the instances of Hydrate to use
 * The deal is that we can't just have these be class-level data members because 
 * this gets imported to the entry-server where those variables will persist in memory.
 * So we have to come up with a context that will be purely local to the render function,
 * then we won't have any issue. So this provides the app with a single context so 
 * that that Hydrate instances can coordinate with each other.
 * 
 */
export const hydrationContext = () => {

  let mainHydrationData = {}

  if(inBrowser) {
    const hydrationInfoElement = document.querySelector('[data-hydration-info]')
    if(hydrationInfoElement) {
      mainHydrationData = JSON.parse(hydrationInfoElement.textContent)
    } 
  }

  const ctx = {
    mainHydrationData,
    numTotal: 0,
    numStack: 0,
    navStack: 0,
    taskCache: {},
    
    //The lifecycle events prefetch, mount and preload are reported 
    //to the context and organized by component-name. This information
    //is provided to the 
    lifecycleEntries: {
      //MyComponent:{ num:0, prefetch:0, mount:0, preload:0}
    }
  }

  const metaManager = new Meta(ctx)
  ctx.metaManager = metaManager

  return ctx

}

/**
 * @param {*} ctx Required. Context object provided by the hydrationContext function
 * @returns the mainHydration data from the context
 * 
 */
export const hydrationData = (ctx) => {
  return ctx.mainHydrationData
}

/**
 * @param {*} ctx Required. Context object provided by the hydrationContext function 
 * @returns an object containing all of the meta-data strings, to be used during SSR rendering.
 * 
 */
export const hydrationGetMeta = (ctx) => {
  const { metaManager } = ctx 
  return {
    head: metaManager.renderHeadString(),
    htmlAttrs: metaManager.renderHTMLAttributes(),
    headAttrs: metaManager.renderHeadAttributes(), 
    bodyAttrs: metaManager.renderBodyAttributes(),
    bodyScripts: metaManager.renderEndOfBodyString(),
    appAttrs: metaManager.renderAppAttributes(),
    favIconAttr: metaManager.renderFavIconAttribute()
  }
}

/**
 * 
 * @param {*} ctx Required. Context object provided by the hydrationContext function 
 * Manage the navStack variable from the context. This used by the Meta instance to determine
 * whether or not a particular meta-data property needs to be refreshed
 *  
 */
export const incrementNav = (ctx) => {
  ctx.lifecycleEntries = {}
  ctx.navStack++
}
export const getNav = (ctx) => {
  return ctx.navStack
}


/**
 * Use this to place the reveal-class on an element. This just handles the 
 * inBrowser so that it works properly with SSR. See we want the server to 
 * return "unrevealed" markup. We don't want the rendered document to have 
 * funky things going on with opacity or whatnot. Bad for SEO, screen-readers
 * etc.
 * 
 * The browser however can do those acrobatics, so if we're on the browser,
 * then go ahead and return the reveal class, e.g. reveal_fadeIn_slideDown_50px
 * 
 * @param {*} revealClass 
 * @param {*} otherClasses 
 * @returns the array of classes depending on if we're in the browser or not
 * 
 */
 export const revealClasses = (revealClass, otherClasses) => {
  if(inBrowser) {
    return [revealClass, ...otherClasses]
  } else {
    return otherClasses
  }
}