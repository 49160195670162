import {
  createMemoryHistory,
  createRouter as _createRouter,
  createWebHistory
} from 'vue-router'
import { routes } from '@project/routes'
import modifyRouter from '@project/modify/router.js'
import { dispatchEvent } from '@kit/utils/EventBus'
import { incrementNav } from '@kit/utils/Hydrate'
import { gaPageView } from '@kit/utils/Analytics'
import { inBrowser } from '@/kit/utils/EnvironmentHelper'
import Parallax from '@/kit/utils/Parallax'


let _router = null

export function createRouter(hydration) {
  if(!_router) {

    //the very last thing is the 404 catch-all
    routes.push({
      name:"not-found",
      path: '/:pathMatch(.*)*',
      component: () => import(/* webpackChunkName: "not-found" */'@project/404.vue')
    })

    if(typeof window == 'undefined') {
      _router = _createRouter({
        // use appropriate history implementation for server/client
        history: createMemoryHistory(),
        routes,
      })
    } else {
      _router = _createRouter({
        // use appropriate history implementation for server/client
        history: createWebHistory(),
        routes,
      })
    }


    _router.afterEach(({fullPath}, _from) => {
      gaPageView(fullPath)

      //upon navigation, we're going to force a refresh of the parallax effects
      if(inBrowser) {
        Parallax.forceRefresh()
      }
    })

    _router.beforeEach((to, from, next) => {

      //this will increment an index in our meta-manager so that it know what meta-information 
      //is the most up-to-date.
      incrementNav(hydration)

      let resetScrollPosition = true;
  
        //if we're switching between two urls where they both
        //belong to different sections of the page, then don't 
        //reset the scrolling.
        //There's some pages where the url parameters are linked to multiple sections of the page, 
        //like a paginated table. We want the url to change along with the page number as we 
        //page back and forth, but obviously we don't want to scroll to the top of the page each
        //time we hit the back and forward button on the paginated table.
        if(
          to.meta.paramsMappedToMultipleSections 
          && from.meta.paramsMappedToMultipleSections
          && to.name == from.name) {
          resetScrollPosition = false
        }
  
      dispatchEvent("navigateToNewPage", { resetScrollPosition });
      next()
    })

    modifyRouter(_router)
  }
  return _router
}