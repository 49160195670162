<script>
import { defineComponent, computed, inject, ref, watch, nextTick } from 'vue'
import { pathname } from '@kit/utils/Formats'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import IconButton from '@kit/components/IconButton.vue'
import { WAIT } from '@kit/utils/Hydrate'
import { mergeClassesTheme } from '@kit/utils/Formats'
import '@css/index.css'

/**
 The theme is an object like this that lives in the "searchResults" slot of the search-wiget theme.

  {

    //show the thumbnails? 
    showThumbnails: true

    //The mergeable classes for the outer wrapper. See mergeClassesTheme 
    outerClasses: { append:"some-cool-class" },

    //The mergeable classes for the prev-next buttons. See mergeClassesTheme 
    buttonClasses: { append:"some-cool-class" },

    //The mergeable classes for the loading wrapper. See mergeClassesTheme 
    loadingClasses: { append:"some-cool-class" },

    //the mergeable classes for the found-results
    foundResultsClasses: "some class",

    //the mergeable classes for the results block. See mergeClassesTheme
    resultsBlockClasses: { override:"some-cool-class2" },

    //the mergeable clases for each the result element. See mergeClassesTheme
    resultElementClasses: { append:"cool-search-result" },

    //the theme for the buttons, and the icon for each
    prevNextButton: blueButton,
    prevIcon:"rounded-single-chevron-left",
    nextIcon:"rounded-single-chevron-right"

  }

 **/

export default defineComponent({
  name: "SearchResults",
  props:{
    "theme":{ required:true },
    "searchResultsRoute":{ required:false, default:null }, 
    "inModal": { required:false, default:false }
  },
  setup(props, ctx) {
    const hydrate = inject("hydrate")
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    let loadingWatchFF = true

    const inMobile = computed(() => {
      return store.state.inMobile
    })

    //Are we using another page for the search results
    const useSearchRoute = computed(() => {
      return !!props.searchResultsRoute
    })

    const currentPage = ref(0)
    const externalSearchTerm = ref(null)

    const currentRoutePageZeroIndex = computed(() => {
      const pageInt = parseInt(route.params.page)
      return (isNaN(pageInt) ? 1 : 1) - 1
    })

    //The current start index for the search task. 
    //The pages are 1-index, and google's endpoint has a startIndex parameter is also 1-indexed.
    const currentStartIndex = computed(() => {
      return (currentRoutePageZeroIndex.value*10)+1
    })

    const currentSearchTerm = computed(() => {
      if(externalSearchTerm.value) {
        return externalSearchTerm.value
      } else 
      if(route.params.searchTerm) {
        return decodeURIComponent(route.params.searchTerm)
      } 
      return null
      
    })


    const searchInfoFromRoute = computed(() => {
      return `${currentRoutePageZeroIndex.value}_${currentSearchTerm.value}`
    })
    const searchInfoFromUI = computed(() => {
      return `${currentPage.value}_${currentSearchTerm.value}`
    })

    const { prefetch, refresh, refreshState, prefetchState } = hydrate({
      prefetch:[{
        name:"googleCSE",
        params() {
          return {
            searchTerm: currentSearchTerm.value, 
            startFrom: currentStartIndex.value,
          }
        },
        cache({searchTerm, startFrom}) {
          return `${searchTerm}${startFrom}`
        }
      }],
      async mounted() {
        //get the current page from the route to begin with.
        currentPage.value = currentRoutePageZeroIndex.value
      }
    })

    const searchResults = computed(() => {
      return prefetch.value ? prefetch.value.googleCSE : null
    })

    const loading = computed(() => {
      return refreshState.value == WAIT || prefetchState.value == WAIT
    })

    const hasPrev = computed(() => {
      let _hasPrev = false
      if(searchResults.value) {
        _hasPrev = !!searchResults.value.queries.previousPage
      }
      return _hasPrev
    })
  
    const hasNext = computed(() => {
      let _hasNext = false
      if(searchResults.value) {
        _hasNext = !!searchResults.value.queries.nextPage
      }
      return _hasNext
    })

    const handleNext = () => {
      currentPage.value++
    }
    const handlePrev = () => {
      currentPage.value--
    } 


    /**
     * This is an external function that is meant to be called by one of the search-widget 
     * components in the event that this component is being used in something like a modal window.
     * 
     * @param {String, required} term 
     */
    const searchWithTerm = async(term) => {

      //Just set the term, and the watcher will take care of the rest. 
      externalSearchTerm.value = term

      await nextTick()

      //and we're going to return a promise which will wait until the loading is done.
      let interval = null
      return new Promise(
        (resolve) => {

          interval = setInterval(() => {

            if(!loading.value) {
              clearInterval(interval)
              interval = null
              resolve()
            }

          }, 50)

        });
    }

    //When the user pages forward or backward or changes the search term
    watch(searchInfoFromUI, (_newVal) => {

      if(!currentSearchTerm.value) {
        return
      }

      //if we're using this component as a standalone page, then we're going to be
      //changing the search parameters by changing the url.
      if(useSearchRoute.value) {
        router.push(`${props.searchResultsRoute}/${currentSearchTerm.value}/${currentPage.value+1}`)
      } 
    
      //else, we're using this just as a modal content area, and there's no coordination with
      //the url and in that case just refresh directly.
      else {
        refresh()
      }
    })

    //When the search info from the route changes, the will trigger a refresh
    //this gets it information from the route, which is to say that this 
    //is used in cases where the switching pages by mutating the url.
    watch(searchInfoFromRoute, () => {
      if(useSearchRoute.value) {

        if(!currentSearchTerm.value) {
          return
        }

        refresh()
      }
    })

    /////////////
    //  A P I  //
    /////////////

    
    watch(loading, async(newVal, oldVal) => { 
      if((newVal && !oldVal) || (newVal && loadingWatchFF)) {
        loadingWatchFF = false
        await nextTick()
        ctx.emit("loadStart")
      } else
      if(!newVal && oldVal) {
        await nextTick()
        ctx.emit("loadEnd")
      }
    }, { immediate:true })
    const getSearchTerm = () => {
      return currentSearchTerm.value
    }
     const getSearchResults = () => {
      return searchResults.value
    }
 
    return { 
      pathname, loading, inMobile, useSearchRoute, externalSearchTerm,
      searchResults, currentRoutePageZeroIndex, currentSearchTerm, 
      searchInfoFromRoute, searchInfoFromUI, currentStartIndex, currentPage,
      hasPrev, hasNext, handlePrev, handleNext, mergeClassesTheme, 
      searchWithTerm, getSearchResults, getSearchTerm
    }
  },
  components: { 
    IconButton
  },
  emits: ['loadStart','loadEnd']

});
</script>

<style scoped>
  /* There's a problem with nesting sb-content-strict height and the sb-gx classes */
  .search-results-block {
    top:20px;
  }
</style>

<template>

  <!-- If we're in the modal, then we're going to handle the greediness of the 
  vertical elements a bit differently -->
  <section :class="mergeClassesTheme( inModal ? 'sb sb-v sb-greedy' : 'sb sb-v', theme.outerClasses)">
    <div :class="inModal ? 'sb sb-v sb-greedy-1 sb-content-strict-height' : 'sb sb-v'">

      <template v-if="searchResults && !loading">
        
        <template v-if="searchResults.items && searchResults.items.length > 0">

          <div :class="mergeClassesTheme('sb sb-text', theme.foundResultsClasses)" v-if="searchResults.items && searchResults.items.length > 0">
            <slot name="results-found" v-bind="{ searchTerm: currentSearchTerm, numItems: searchResults.items.length }"/>
          </div>

          <!-- If we're in the modal, then we're going to make sure that this
          is the scrolling element that takes up the height greedily -->
          <ul :class="mergeClassesTheme( inModal ? 'sb sb-v sb-greedy sb-content-strict-height search-results-block' : 'sb sb-v sb-g20', theme.resultsBlockClasses)">
            <li v-for="result in searchResults.items" :class="mergeClassesTheme('sb sb-h sb-greedy-1 sb-gr sb-g10', theme.resultElementClasses)">
              <template v-if="theme.showThumbnails && result.pagemap.cse_thumbnail && result.pagemap.cse_thumbnail.length > 0">
                <slot name="result-with-thumbnail" v-bind="result"/>
              </template> 
              <template v-else>
                <slot name="result-no-thumbnail" v-bind="result"/>
              </template>
            </li>
          </ul>

        </template>
        
        <template v-else>
          <slot name="no-results" v-bind="{ searchTerm: currentSearchTerm }"/>
        </template>

      </template>
      <div v-else-if="loading" :class="mergeClassesTheme('sb', theme.loadingClasses)">
        <slot name="loading" />
      </div>
    </div>

  </section>

  <div :class="mergeClassesTheme('sb sb-h sb-g30 sb-align-ch', theme.buttonClasses)">
      <IconButton :theme="theme.prevNextButton" 
      :icon="theme.prevIcon"
      :active="hasPrev" 
      :loading="false" 
      @buttonClick="handlePrev()"/>

      <IconButton :theme="theme.prevNextButton" 
      :icon="theme.nextIcon"  
      :active="hasNext" 
      :loading="false" 
      @buttonClick="handleNext()"/>
    </div>

</template>
