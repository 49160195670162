/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\

  EventBus

  A simple utility to provide a central event touch-point for all 
  the components. 
  
  Sometimes you just want to dispatch events instead of set states on 
  the store. Problem with the store is then you always have to set the 
  state back. It's too clunky for events.

  Author Alex Lowe

\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const kvp = {}

/**
 * 
 * @param {string} event. the event string 
 * @param {function} callback. the callback function
 * @returns 
 */
export const addEvent = (event, callback) => {
  if(!kvp[event]) {
    kvp[event] = {}
    kvp[`${event}_uidStack`] = 0
  }

  const eventBus = kvp[event]
  const eventUID = `${event}_uidStack`
  kvp[eventUID]++
  const nextUID = `${event}/${kvp[eventUID]}`
  eventBus[nextUID] = { callback }
  return nextUID
}

export const removeEvent = (uid) => {
  const arr = uid.split("/")
  const event = arr[0]
  const eventBus = kvp[event]
  if(eventBus) {
    delete eventBus[uid]
  }
}


export const dispatchEvent = (event, info) => {
  const eventBus = kvp[event]
  if(eventBus) {
    for(let uid in eventBus) {
      const { callback } = eventBus[uid]
      callback(info)
    }
  }
}