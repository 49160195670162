<script>
import { defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  name: "Portal",
  props: {
    "to": { required:true, default:false },
  },
  setup(props) {

    const root = ref(null)

    onMounted(() => {

      const domNode = root.value
      const target = document.getElementById(props.to)
      const childNodes = domNode.childNodes
      const len = domNode.childNodes 

      for(let i=0; i<len; i++) {
        const child = childNodes[0]
        domNode.removeChild(child)
        target.appendChild(child)
      }
       
    })

    return { root }

  }
});
</script>


<template>
    
  <div ref="root" style="position:absolute; display:none; width:0px; height:0px;">
    <slot></slot>
  </div>

</template>