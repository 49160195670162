import { getEnv, inBrowser } from '@kit/utils/EnvironmentHelper'

export const googleRecaptchaConfig = () => {
  return { 
    siteKey: getEnv("GOOGLE_RECAPTCHA_SITE_KEY"), 
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true
    }
  }
}
