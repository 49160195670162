import { ButtonIconActiveHeight } from '@kit/components/IconButton.vue'
import { greenButton, 
  navMainSearchButton, roundCloseButton, 
  navSearchWidgetButton, prevNextSearchResultsButton
} from '@project/theme-buttons'
import { ModalPositionCenter } from '@kit/components/Modal.vue'


  export const icsModal = {
    closeButton: {
      ...greenButton, 
      icon:"rounded-close-x_50", 
      iconDimension: {
        style: ButtonIconActiveHeight,
        active: "20px"
      },
      background: {
        ...greenButton.background,
        borderRadius: "50%"   
      },
    }
  }

  

  export const searchResultsModal = {

    suspendScrolling: true,
    
    backgroundStyle: "opacity:0.8; background-color:#FFFFFF;",
    
    windowStyles: "background:#FFFFFF; box-shadow:6px 6px 5px #999999; border-radius:10px; border-left:2px solid #DDDDDD; border-top:2px solid #DDDDDD;",

    vw: 80,
    vh: 80,
    mvw: 80,
    mvh: 80,
    position: ModalPositionCenter,

    closeButton: roundCloseButton
  }

  export const searchResults = {

    //show the thumbnails for the search results?
    showThumbnails: false,

    //The mergable classes for the button container
    buttonClasses: "ics-search-results-prevnext-wrapper",

    //the mergable classes for the results-block
    resultsBlockClasses: "ics-results-block",

    //the mergeable clases for each the result element
    resultElementClasses: "ics-search-result",

    //The mergeable classes for the loading wrapper. See mergeClassesTheme 
    loadingClasses: "ics-search-loading",

    //the theme for the buttons, and the icon for each
    prevNextButton: prevNextSearchResultsButton,

    prevIcon:"rounded-single-chevron-left",
    nextIcon:"rounded-single-chevron-right",
  }

  //The theme for the search results component in the modal.
  export const searchResultsInModal = {
    ...searchResults,
    outerClasses: "sb-greedy ics-search-results-outer",
  }


  export const searchWidgetMain = {
  
    //The height of the widget
    mainWidgetHeight:60,

    //The height of the drop-shadow at the bottom. 
    shadowAdjustment:5,

    //Show the thumbnails
    showThumbnails: false,

    //the options for the navigation item in the top menu.
    navComponent: {

      outerClasses: "sb sb-h sb-explicit sb-alt2 sb-align-c ics-nav-main-searchoption",
      
      button: navMainSearchButton,

    },

    //the place to put the classes and styles for the search widget modal
    searchComponent: {
      
      modalMode:false,
      //The two headlines in the search modal window
      topHeadline: "Search",
      mainHeadline: "Search ICS",
      mainHeadlineClasses: "ics-main-search-widget-headline",

      completionWrapperClasses:"ics-main-search-completion-wrapper",

      completionResultClasses:"ics-main-completion-result",

      //non-modal style
      //searchButton: {...orangeButton, text:"GO" },
      searchButton: navSearchWidgetButton
      //{ ...magnifyingGlassButton, outerClasses:"ics-search-modal-search-nonmodal-button" },
        
    },

    //The options for the search-results.
    resultsComponent: {

      //the theme for the search
      searchResults: searchResultsInModal,

      //the modal theme, for use in the event that route is null
      modal: searchResultsModal
    }
  }
 
   
  export const searchWidgetMobile = {

    ...searchWidgetMain,

    isMobile: true,

    //the options for the navigation item in the top menu.
    navComponent: {
      button: { ...greenButton, 
        icon:"rounded-magnifying-glass",
        foreground: "#FFFFFF",
        background: {  
          ...greenButton.background,
          padding:      "20px", 
          borderRadius: "5px"    
        },
        iconDimension: {
          style: ButtonIconActiveHeight,
          active: '30px'
        },
        outerClasses:"sb-explicit ics-mobile-search-button",
      },


      //the mergeable classes for the outer-wrapper
      outerClasses: "ics-mobile-search-wrapper",

      completionWrapperClasses:"ics-mobile-search-completion-wrapper",

      completionResultClasses:"ics-mobile-completion-result",

      completionUnderPosition: false,

      //the mergeable classes for the headline
      headlineClasses: "ics-mobile-search-widget-headline",
    
      headline:"Search ICS"

    },
    

  } 
  

  /*
  export const searchWidgetMain = {
  
    //The height of the widget
    mainWidgetHeight:60,

    //The height of the drop-shadow at the bottom. 
    shadowAdjustment:5,

    //Show the thumbnails
    showThumbnails: false,

    //The search button
    widgetButton:  {...orangeButton, text:"GO" },
  
    //The buttons in the modal window.
    modalButtons: greenButton,

    //The modal theme.
    modal: icsModal
  }
  

   
  export const searchWidgetMobile = {
    
    //The search button
    widgetButton: greenButton,
      
    //The buttons in the modal window.
    modalButtons: greenButton,

    //Show the thumbnails
    showThumbnails: false,

    //The modal theme.
    modal: icsModal
  } 
  */