import Config from '@kit/utils/Config'
import { watch, ref, triggerRef } from 'vue'
import { endChar } from '@kit/utils/Formats'

/*
  Themer utility.

  The usage is a little complex, but the idea is that it lets us set up
  a complex set of objects that define the theme and behavior of complex components
  so that they can be easily reskinned and reused. We do some acrobatics with 
  reactive refs here so that we can reactively reskin objects if we have to

  import { myModalTheme } from @project/themes
  
  <myModal :theme="myModalTheme"/>

  Then in MyModal.vue:

  //make the default config object outside the vue component
  const defaultConfig = new Config()
  defaultConfig.setSettings({
    "themeProp1":{ "color":"blue" },
    "themeProp2":42
  })

  props:{
    //the theme. Optional. 
    "theme":{ required: false }

    //exposed theme properties
    "themeProp1": { required: false }
    "themeProp2": { required: false } 
  }

  setup function:

  const themer = inject("themer")
  const { getProp, setProp } = themer({ props, defaultConfig })

  getProp is used to fetch the properties:
  
  <some-component color="getProp('themeProp1.color')"/>

  if you want to fetch a subtheme this way, you have to use

  getProp('path.to.subtheme','theme')

  TODO: This is more nuanced. Get into more detail here.
  if you want to fetch a class string, use 'class'. If you want to fetch a style string, use 'style'
  TODO: The 'class' and 'style' merge options don't make any sense.

  And then you can use setProp() the same 



  Here's the deal. Flexible components with many different kinds of modes and 
  behaviors are hard. You end up with like 100 properties. Well, that's just kind of nuts.

  It will occur to you to just wrap everything into some kind of theme object,
  that way you can just pass in a single object which will have all those options 
  baked in, and you can the maintain a set of files with theme objects. ES6 makes 
  this easier to do with the spread operator. Not a bad plan.

  Plan gets tricker when you have to reskin the theme or parts of it. What to do?
  Could do something where the entire theme is reactive. Problem there is performance.
  Deep reactivity on these theme objects isn't practical. 

  Anther idea is shallow-refs. Make the theme sort-of reactive at least on a shallow 
  level with the shallowRefs function. I've tried that and it actually works ok.

  Ok, but there's another prerogative here, which is a chain-of-precedence between the 
  theme object, whatever props overrides you might have on your component, and a 
  default theme object. For some property "x": props.x || theme.x || defaultTheme.x
  
  Now, the trick is, making this kind of chain-of-precedence work with reactivity.
  Well, that's why we have this wrapper class ThemeWrapper, and each instance contains
  a reactive ref() object. Then the component gets a getProp and setProp function. getProp
  is reactive because it consumes the ref. setProp updates the config object and the ref.

  See right at the bottom: the themer function. That it wired into the app so you can 
  access it in your components with inject("themer")

  Couple of bugaboos:
  1. You can reskin a whole theme at once if you want, although it's not going to work 
  for nested themes. Would require a recursive chain of all the refs being ticked up
  ...eh maybe another day

  2. There's places where this is used in animation. So if you're changing themes on the
  fly, in theory you could do it during an animation and really screw things up.


  S

  
*/


class ThemeWrapper {

  constructor(themeObj) {
    this._isWrapper = true
    this._ref = ref(1)

    if(themeObj._isConfig) {
      this._obj = themeObj._config
      this._config = themeObj
    } else {
      this._obj = themeObj
      this._config = new Config()
      this._config.setSettings(themeObj)
    }

    this._nestedThemeKVP = {}
  }
  

  //reset the config with a new object and tick up the ref.
  resetConfig(newObj) {
    this._config.resetConfig(newObj)
    this._ref.value++
  }
  
  //Set a property. If the new property is a theme object,
  //then you have to set the isTheme to true.
  //This is so that it can get turned into a wrapper, or update
  //the current wrapper and tick up the ref contained therein
  setProp(property, newVal, isTheme) {
    if(newVal && newVal._isWrapper) {
      throw new Error("Error: setProp isn't meant to be used this way. You don't pass in ThemeWrapper objects")
    }

    //If we're fetching a theme property, then 
    //then we're going to check to see if it's been converted to a 
    //wrapper yet. If it hasn't, then we can just reset the property 
    //like normal. But if it has been converted, then we're going to 
    //reset it. We're going to assume that the newVal is a new theme 
    //object.
    const nT = this._config.getSetting(property)
    
    if(nT && (nT._isWrapper || isTheme)) {
      nT.resetConfig(newVal)
    } else 
    if((!nT || !nT._isWrapper) && isTheme) {
      const wrapper = ThemeWrapper(newVal)
      this._config.resetSetting(property, wrapper )
    } else {
      this._config.resetSetting(property, newVal )
      this._ref.value++
    }

  }

}


/** 
 * @param {*} themeObj 
 * convert a theme object into a wrapper that exposes a refresh function 
 * and also contains a reactive ref.
 * 
 * Do the same to the array of child themes. We have to do that so
 * the when you assign a new object to a child theme with setProp,
 * it will know to tick up the reactive ref assigned to that particular
 * child theme.
 * 
 */
const themerHandleTheme = (themeObj, _arrOfChildThemePaths) => {
  //If it's already been given this treatment, then let it pass.
  if(!themeObj._isWrapper) { 
    const themeContainer = new ThemeWrapper(themeObj)
    return themeContainer
  } else {
    return themeObj
  }
}


/**
 * @param {*} { props, defaultConfig }
 * @returns undefined
 * 
 * This will ingest the properties as well as a default config object.
 * This is for use inside a themable component and returns an 
 * object that exposes getProp and setProp 
 * 
 */
const themerHandleProps = ({ props, defaultConfig }) => {

  //config variable that the getProp uses.
  //let themeConfig = props.theme._config
  //We can just use the defaultConfig if there really is no theme passed through 
  let propsTheme = props.theme || new ThemeWrapper(defaultConfig)

  //if the props theme that was passed through is just a plain object, then
  //we're going to turn it into a full reactive theme object.
  if(!propsTheme._isWrapper) {
    propsTheme = new ThemeWrapper(propsTheme)
  }

  let themeConfig = propsTheme._config

  let propsConfig = new Config()
  propsConfig.setSettings(props)

  //the reactive ref for changes to this theme
  const localReskin = ref(1)
  const parentReskin = propsTheme._ref

  if(!propsTheme._isWrapper) {
    throw new Error("Unknown error. Theme has no reactive ref.")
  }

  //Get a property. This will respect the chain of importance.
  //The overrides that you place in the props will have precendence.
  //Next will be the theme, and last will be the default theme.
  //
  //If merge is left out, then this will behave respecting the chain of precendence.
  //If merge is truthy, then this will assume that the property points to a string
  //in the theme/props and in that case it will merge the property from the props 
  //behind the property from the theme in a single string. In addition, if merge is
  //"style" then this will make sure that the two different merged strings are separated
  //with a semicolon ";" in keeping with the syntax of css style strings.
  //
  //TODO. Does this merge thing really make any sense?
  const getProp = (property, merge) => {

    if(parentReskin.value && localReskin.value) {
      const fromTheme = themeConfig.getSetting(property)
      
      //If we're supposed to merge the style or class, then merge
      if(merge == "style" || merge == "class") {
        let mergeStart = fromTheme || defaultConfig.getSetting(property)
        const mergeEnd = propsConfig.getSetting(property) || ""
        if(merge="style") {
          mergeStart = endChar(mergeStart, ";")
        }
        return `${mergeStart} ${mergeEnd}`.trim()
      } else

      //If it's a theme, then what we're going to do is see if  
      //we need to convert it to a theme-wrapper before sending it along
      if(merge == "theme") {

        //respect the chain of precedence. Return the value from the props if there is one
        const fromProps = propsConfig.getSetting(property)
        if(fromProps) {
          if(!fromProps._isWrapper) {
            const wrapper = new ThemeWrapper(fromProps)
            propsConfig.resetSetting(property, wrapper)
            return wrapper
          } else {
            return fromProps
          }
        }   

        //the next one is the value from the theme. return it if there is one
        if(fromTheme) {
          if(!fromTheme._isWrapper) {
            const wrapper = new ThemeWrapper(fromTheme)
            themeConfig.resetSetting(property, wrapper)
            return wrapper
          } else {
            return fromTheme
          }
        }

        //lastly, return the default value if there is one.
        const fromDefault = defaultConfig.getSetting(property)
        if(fromDefault) {
          if(!fromDefault._isWrapper) {
            const wrapper = new ThemeWrapper(fromDefault)
            defaultConfig.resetSetting(property, wrapper)
            return wrapper
          } else {
            return fromDefault
          }
        }     

      } 
      
      //Else, just return the property.
      else {

        //We do this so that falsy values from the theme can override truthy default values.
        const r1 = propsConfig.getSetting(property)
        const r2 = defaultConfig.getSetting(property)

        if(r1 !== undefined) {
          return r1
        } else 
        if(fromTheme !== undefined) {
          return fromTheme
        } else 
        if(r2 !== undefined) {
          return r2
        }

      }
    }
  }

  //Set a property, used for re-theming. 
  //If it's a subtheme, then you have to pass true to isTheme.
  const setProp = (property, newVal, isTheme) => {

    if(newVal && newVal._isWrapper) {
      throw new Error("Error: setProp isn't meant to be used this way. You don't pass in ThemeWrapper objects")
    }

    //If we're fetching a theme property, then 
    //then we're going to check to see if it's been converted to a 
    //wrapper yet. If it hasn't, then we can just reset the property 
    //like normal. But if it has been converted, then we're going to 
    //reset it. We're going to assume that the newVal is a new theme 
    //object.
    const nT = getProp(property)
    
    if(nT && (nT._isWrapper || isTheme)) {
      nT.resetConfig(newVal)
    } else 
    if((!nT || !nT._isWrapper) && isTheme) {
      const wrapper = ThemeWrapper(newVal)
      themeConfig.resetSetting(property, wrapper )
    } else {
      themeConfig.resetSetting(property, newVal )
      localReskin.value++
    }

  }
 
  return { getProp, setProp }

}


/**
 * @param {*} argsObj 
 * Either a theme object, or an object with props and defaultConfig.
 * 
 * If you're in a parent component and you need to create a child component with a theme,
 * you pass the theme objects through this function.
 * 
 * const themer = inject("themer")
 * const myTheme = themer(theCoolTheme)
 * 
 * And you pass myTheme to the themeable component:
 * 
 * <MyThing :theme="myTheme">
 * 
 * In your setup function, if you need to change the theme in child component,
 * you call the setProp:
 * 
 * watch(prefetch, async(newVal) => {
 *  
 *  if(newVal) {
 *    await nextTick()
 *    theme.setProp('backgroundColor', prefetch.coolData.backgroundColor) 
 *  }
 * 
 * })
 * 
 * Another trick. You can pass child themes. So let's say that you have a theme
 * for a lazy-img inside your main theme at the location: lazyImgTheme. 
 * Do it like this:
 * :theme="getProp('lazyImgTheme', 'theme')"
 * 
 *  <LazyImg v-if="getProp('activeImage')" 
 *     :src="image" 
 *     :theme="getProp('lazyImgTheme', 'theme')"
 *     :alt="getProp('imageAriaLabel')"
 *     :class="mergeClassesTheme('sb', getProp('imageClasses'))" 
 *     :style="getProp('imageStyle')"/>
 * 
 * @returns 
 */
export const themer = (argsObj, argsObj2) => {
  if(!argsObj.props) {
    return themerHandleTheme(argsObj, argsObj2)
  } else {
    return themerHandleProps(argsObj)
  }
}



/**
 * The factory for provide
 * 
 */
export const themerFactory = () => {
  const themerMethod = (obj1, obj2) => {
    const themerOutput = themer(obj1, obj2)
    return themerOutput
  }
  return themerMethod
}


