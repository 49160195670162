
//Here's the api connector to communicate with the wordpress gallery endpoint:
//https://localhost/cms-service/index.php/wp-json/gallery/v1/id/45

import { get } from '@kit/utils/APIV1.js'

export const section = async(which) => {
  if(which == "support-services") {
    return get(`section/id/154`)
  } else 
  if(which == "consulting-services") {
    return get(`section/id/177`)
  } else 
  if(which == "managed-services") {
    return get(`section/id/167`)
  }
}