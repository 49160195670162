import { LogLevel, PublicClientApplication } from '@azure/msal-browser'
import { getEnv } from '@kit/utils/EnvironmentHelper'

//Thank you
//https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-browser-samples/vue3-sample-app/src/authConfig.ts
//https://stackoverflow.com/questions/72733282/error-aadsts50194-application-xxxxxxxx-is-not-configured-as-a-multi-tenant-a

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: getEnv('MSAL_CLIENT_ID'),
    authority: `https://login.microsoftonline.com/${getEnv('AZURE_TENANT_ID')}/`,
    // Must be registered as a SPA redirectURI on your app registration
    redirectUri: getEnv('MSAL_POST_LOGIN_REDIRECT_URL'),
    // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: getEnv('MSAL_POST_LOGOUT_REDIRECT_URL')
  },
  cache: {
    cacheLocation: 'localStorage'
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose
    }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};