import { msalPlugin } from "@kit/utils/auth/msal/MSALPlugin"
import { msalInstance } from "@kit/utils/auth/msal/MSALConfig"
import { EventType } from "@azure/msal-browser"
import { CustomNavigationClient } from "@kit/utils/auth/msal/MSALNavClient"

//Thank you
//https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-browser-samples/vue3-sample-app/src/main.ts

const createMSAL = (app, router) => {

  // The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigationClient = new CustomNavigationClient(router);
  msalInstance.setNavigationClient(navigationClient);

  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
  }
  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  app.use(msalPlugin, msalInstance);
}

export default createMSAL

