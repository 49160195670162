<script>
import { defineComponent, watch, onMounted, onUnmounted, ref, inject } from 'vue'
import "@kit/assets/css/superbox-core-x.css"
import "@kit/assets/css/basic.css"
import "@project/assets/css/index.css"

import NavBar from '@components/NavBar.vue'
import Footer from '@components/Footer.vue'
import SideNavDrawer from '@components/SideNavDrawer.vue'
import { inBrowser, SSR, getEnv, setMobileThresholdFromApp, incrementResize } from '@kit/utils/EnvironmentHelper'
import { useStore } from 'vuex'
import { addEvent } from '@kit/utils/EventBus'
import { pathname } from '@kit/utils/Formats'
import { meta as modMeta, externalCSS as _modXCSS, extraJS as _modXJS} from '@project/modify/hydrate'
import Parallax from '@kit/utils/Parallax'
import '@images/favicon.png'

const wCtx = typeof window != 'undefined' ? window : null

export default defineComponent({
  name: "App",
  setup() {

    let dCache = null
    let dInterval = null
    const store = useStore()
    const hydrate = inject("hydrate")

    const mediaQuery = wCtx ? wCtx.matchMedia('(max-width : 960px)') : null
    
    const atThreshold = ref(false)
    const modXCSS = _modXCSS || []
    const modXJS = _modXJS || []

    //configure the parallax
    Parallax.config({
      debug: {
        showAreas:false
      },
      height: {
        query:"main",
        poll: {
          behavior: {
            stableThreshold: 1
          }
        }
      }
    })

    const debounced = (value) => {
      atThreshold.value = value
    }

    const debouncer = (value) => {
      if(!dInterval) {
        debounced(value)

        dInterval = setInterval(() => {
          if(dCache !== null) {
            debounced(dCache)
            dCache = null
          } else {
            clearInterval(dInterval)
            dInterval = null
          }
        },500)
        
      } else {
        dCache = value
      }
    }

    const screenResized = () => {
      incrementResize()
      const atThreshold = mediaQuery.matches
      setMobileThresholdFromApp(atThreshold)
      debouncer(mediaQuery.matches)
    }

    onMounted(async() => {
      if(wCtx) {

        addEvent("navigateToNewPage", ( info ) => {
          if(info.resetScrollPosition) {
            wCtx.scrollTo(0,0)
          }
        })

        wCtx.addEventListener('resize', screenResized, { passive:true })

        screenResized()

      }
    })
    onUnmounted(() => {
      if(inBrowser) {
        wCtx.removeEventListener('resize', screenResized, { passive:true })
      }
    })

    hydrate({
      metadata(meta) {
        meta.addMetaItem([
          { charset:'utf-8' },
          { name:'viewport', content: 'width=device-width, initial-scale=1.0' },
          { "http-equiv":'X-UA-Compatible', content: 'IE=edge'},
          { property:"og:type", content:"website" },
          { name:"google-site-verification", content:getEnv('GOOGLE_CSE_SITE_VERIFICATION')}
        ])
        meta.addHTMLAttribute({ name: "lang", value:"en" })
        meta.addAppAttribute([
          { name:"class", value:"sb sb-v sb-greedy-1"},
          { name:"style", value:"height:100%"}
        ])
        meta.addFavIcon(pathname('@images/favicon.png'))
        modMeta(meta)
      },
      extraJS: [{ proxy:"google-cse", src:`google_cse_id=${getEnv("GOOGLE_CSE_ID")}`, async:"async" }, ...modXJS],
      externalCSS: modXCSS
    })


    watch(atThreshold, (newVal, oldVal) => {
      if(newVal && !oldVal) {
        store.dispatch("screenThreshold", newVal)
      } else 
      if(!newVal && oldVal) {
        store.dispatch("screenThreshold", newVal)
      }
    }, {immediate:true})

    return { inBrowser, SSR }

  },

  components: {
    NavBar,
    PageFooter: Footer,
    SideNavDrawer
  },
});
</script>

<style scoped>
  .do-not-show {
    position:fixed; 
    width:0px; 
    height:0px; 
    display:none; 
    overflow:hidden;
  }
</style>

<template>
  <NavBar/>
  <router-view/>  
  <PageFooter/>
  <SideNavDrawer/> 
  <div class="do-not-show">
    <div class="gcse-searchbox" data-autoCompleteMaxCompletions="5"></div>
  </div>
  <div class="do-not-show">
    <div class="gcse-searchresults"></div>
  </div>
</template>

