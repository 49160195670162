/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\

  Simple vector utility borrowed from an old project 
  of mine from 10 years ago
  
  Author Alex Lowe

\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const V3 = class V3 {
 
  constructor() {
      if(arguments.length < 3) {
      throw new Error('Error: V3 --> constructor: you have to have at least three arguments, all numbers.')
      }
  
  this.i = arguments[0];
  this.j = arguments[1];
  this.k = arguments[2];
  this._dim = 3;
  }

 /**
  * return this dotted with another 3-vector.
  *
  */
  dot(vec3) {
  return this.i*vec3.i + this.j*vec3.j + this.k*vec3.k;
  }
   
 /**
  * return this crossed with another 3-vector.
  *
  */
  cross(vec3) {
  return new V3( (this.j*vec3.k - vec3.j*this.k), -1*(this.i*vec3.k - vec3.i*this.k), (this.i*vec3.j - vec3.i*this.j));
  }


  /**
   * return new vector resulting from adding vec3 to this one.
   *
   */
  plus(vec3) {
  return new V3(this.i+vec3.i, this.j+vec3.j, this.k+vec3.k);
  }


  /**
   * return new vector resulting from subtracting vec3 from this one.
   *
   */
  minus(vec3) {
  return new V3(this.i-vec3.i, this.j-vec3.j, this.k-vec3.k);
  }


  /**
   * rescale the vector.
   *
   */
  rescale(sc) {
  this.i *= sc;
  this.j *= sc;
  this.k *= sc;
  }


  /**
  * return the magnitude.
  *
  */
  getMag() {
  return Math.sqrt(this.i*this.i + this.j*this.j + this.k*this.k);
  }

  /**
  * set the magnitude.
  *
  */
  mag(newMag) {
  var m = this.getMag();
    if(m != 0) {
    this.i = newMag*this.i/m;
    this.j = newMag*this.j/m;
    this.k = newMag*this.k/m;
    } else {
    this.i = 0.5774;
    this.j = 0.5774;
    this.k = 0.5774;
    }
  }

  /**
  * return a clone. the newMag is optional. if set, it will 
  * return a vector pointing in the same direction, but with
  * a magnitude of newMag.
  *
  */
  clone(newMag) {
    if(!newMag) {
    return new V3(this.i, this.j, this.k);
    } else {
    var v3 = new V3(this.i, this.j, this.k);
    v3.mag(newMag);
    return v3;
    }
  }

}


export const V2 = class V2 {

  constructor() {
    if(arguments.length < 2) {
    throw new Error('Error: Vector --> constructor: you have to have at least two arguments, both numbers.')
    }

    this.i = arguments[0];
    this.j = arguments[1];
    this.k = 0; 	
    this._dim = 2;
  }


   /**
    * return this dotted with another 3-vector.
    *
    */
  dot(vec2) {
  return this.i*vec2.i + this.j*vec2.j;
  }


  angleBetween(vec2) {
  var m1 = this.getMag();
  var m2 = vec2.getMag();
  var d = this.dot(vec2);
  var angle = Math.acos(d/(m1*m2));
      if(this.cross(vec2).k < 0) {
      angle = -angle;
      } 
  return angle;
  }
  
   
  /**
   * rotate the vector. in degrees.
   *
   */
  rotate(ang) {
  var iO = this.i;
  var jO = this.j;
  var a = 0.017453*ang;
  
  this.i = iO*Math.cos(a) - jO*Math.sin(a);
  this.j = iO*Math.sin(a) + jO*Math.cos(a);
  }
   
   
  /**
   * A proj B 
   *
   */
  proj(vec2) {
  return (this.dot(vec2))/vec2.getMag();
  }
   
   
  /**
   * return this crossed with another 3-vector.
   *
   */
  cross(vec2) {
  return new V3( (this.j*vec2.k - vec2.j*this.k), -1*(this.i*vec2.k - vec2.i*this.k), (this.i*vec2.j - vec2.i*this.j));
  }
   
   
  /**
   * return new vector resulting from adding vec3 to this one.
   *
   */
  plus(vec2) {
  return new V2(this.i+vec2.i, this.j+vec2.j);
  }
   

  /**
   * return new vector resulting from subtracting vec3 from this one.
   *
   */
  minus(vec2) {
  return new V2(this.i-vec2.i, this.j-vec2.j);
  }
   
   
  /**
   * rescale the vector.
   *
   */
  rescale(sc) {
  this.i *= sc;
  this.j *= sc;
  this.k *= sc;
  }
   
   
  /**
   * return the magnitude.
   *
   */
  getMag() {
  return Math.sqrt(this.i*this.i + this.j*this.j);
  }

  /**
   * set the magnitude.
   *
   */
  mag(newMag) {
  var m = this.getMag();
    if(m != 0) {
    this.i = newMag*this.i/m;
    this.j = newMag*this.j/m;
    } else {
    this.i = 0.7071;
    this.j = 0.7071;
    }
  }
   

  /**
   * return a clone. the newMag is optional. if set, it will 
   * return a vector pointing in the same direction, but with
   * a magnitude of newMag.
   *
   */
  clone(newMag) {
    if(!newMag) {
    return new V2(this.i, this.j);
    } else {
    var v2 = new V2(this.i, this.j);
    v2.mag(newMag);
    return v2;
    }
  }
  
};
