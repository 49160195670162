import { createSSRApp } from "vue"
//import App from "@kit/components/App.vue"
import VueGtag from "vue-gtag"
import { store } from './store'
import Vue3TouchEvents from "vue3-touch-events"
import { createRouter } from './router'
import { modifyApp, modifyProvideApp }from './project/modify/app.js'
import { hydrationFactory, hydrationContext } from '@kit/utils/Hydrate'
import { themerFactory } from "@/kit/utils/Themer"
import { googleAnalyticsConfig } from "@kit/utils/Analytics"
import { googleRecaptchaConfig } from "@kit/utils/Recaptcha"
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { checkEnv } from "@/kit/utils/EnvironmentHelper"


const start = async () => {

  checkEnv()

  //get either the default App or the provided App from the project
  const AppRoot = modifyProvideApp() || require("@kit/components/App.vue").default; // || App

  const app = createSSRApp(AppRoot)
  const hydration = hydrationContext()
  const router = createRouter(hydration)

  app.use(router)
  app.use(store)
  app.use(Vue3TouchEvents)
  app.use(VueGtag, googleAnalyticsConfig())
  app.use(VueReCaptcha, googleRecaptchaConfig())

  app.provide('hydrate', hydrationFactory(hydration))
  app.provide('themer', themerFactory())

  modifyApp(app, router)

  await router.isReady()

  app.mount("#app")
};

start();

