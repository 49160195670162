<script>

///////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  IconButton
//
//  A themeable button. You pass in a theme object with a particular schema. See notes below.
//  Any of the theme's top-level properties can be overriden by a component property of the same 
//  name. Works in SSR. Has a rich theme with lots of bells and whistles.
//
//  This was tough to write. There's so many permutations of styles and layout configurations, but 
//  I really want to break free of too much reliance on font-awesome and ui frameworks because of 
//  cost (font-awesome isn't free), bloat and ssr issues (e.g. primevue doesn't work in SSR).
//
//  So here was have a slick workflow- you can put your icons into the components/icons folder 
//  as slim little js modules that only contain a path and a viewBox string. Look in there for 
//  examples.
//  
//  You'll notice that the theme properties aren't reactive. We calculate the various css values 
//  once and that's it. This is on purpose. The button has these button-y things that it's 
//  supposed to do like flash and go inactive and such, and it handles all these things internally. 
//
//  Author Alex Lowe
//
///////////////////////////////////////////////////////////////////////////////////////////////////////

import { defineComponent, toRef, ref, watch, nextTick, computed, onMounted, onBeforeUnmount, reactive } from 'vue'
import { sleep } from '@kit/utils/Sleep'
import { inBrowser } from '@kit/utils/EnvironmentHelper'
import { animate } from '@kit/utils/Animate'
import { pointerEventToXY } from '@kit/utils/Math'
import { mergeClassesTheme, mergeStylesTheme } from '@kit/utils/Formats'
import { useRouter } from 'vue-router'

//It will hug the text on the right and both the icon and the text will obey the justification
export const ButtonIconPositionHugRight = 1

//It will hug the text on the left and both the icon and the text will obey the justification
export const ButtonIconPositionHugLeft = 2 

//It will go to the far right of the button
export const ButtonIconPositionFarRight = 3 

//It will go to the far left of the button
export const ButtonIconPositionFarLeft = 4

//Used for occasions where the text is centered in the button.
//It will sit to the right of the text as an absolutely positioned element
export const ButtonIconPositionShiftRight = 8

//Used for occasions where the text is centered in the button.
//It will sit to the left of the text as an absolutely positioned element
export const ButtonIconPositionShiftLeft = 9

//Align to the left. If the button has no text, then the icon will align to the left.
export const ButtonAlignLeft = 5

//Align to the right. If the button has no text, then the icon will align to the right
export const ButtonAlignRight = 6

//Align to the center. If the button has no text, then the icon will align to the center
export const ButtonAlignCenter = 7

//The standard flash, which changes the colors of the back and foreground for a split-second
export const ButtonFlashStandard = 10 

//A flash style where there's a flash element that slides left to right
export const ButtonFlashSlideLeft = 11

//A flash style where there's a flash element that expands radially 
export const ButtonFlashRadial = 12

//A hover style where it just changes the colors instantly
export const ButtonHoverFlash = 13

//A hover style where it animates the colors
export const ButtonHoverAnimate = 14

//A method for sizing the icon. This will make it as big as the text allows i.e. height: 100%. preserves aspect
export const ButtonIconPassiveHeight = 15

//A method for sizing the icon. This will give it an "active", i.e. explicit, height. preserves aspect
export const ButtonIconActiveHeight = 16

//A method for sizing the icon. This will give it an "active", i.e. explicit, width. preserves aspect.
export const ButtonIconActiveWidth = 17

//TODO implement this
//A method for placing the loading spinner. This will cover the icon only. This will failover to ButtonLoaderCoverFull in the event that there is no icon.
export const ButtonLoaderCoverIcon = 18 

//TODO implement this
//A method for placing the loading spinner. This will cover the full button.
export const ButtonLoaderCoverFull = 19

/**
    //Here's the different possibilities for the button theme. 
    //The foreground, background, iconPosition, align, flash and loader properties
    //can be overriden by the corresponding properties.

    const buttonTheme = {

      //Optional.
      //Colors for the text and icon
      foreground: {

        //Required
        active:   "#FFFFFF", 

        //Optional. Defaults to #FFFFFF
        inactive: "#FFFFFF",

        //Optional.
        flash:    "#FFFFFF",

        //Optional. Overriden by the top-level "loading" set
        loading:  "#FFFFFF", 

        //Optional. The hover color
        hover: "#0066CC",

      },

      //Can also be like this, in which case all the foreground colors 
      //will be the one that you enter.
      //foreground: "#FFFFFF"
      
      //Optional
      //Colors and styles for the background.
      background: {

        //Required.
        active:       "#49ADAB",

        //Optional. defaults to #CCCCCC
        inactive:     "#CCCCCC",

        //Optional. overriden by the top-level "flash" set.
        flash:        "#4bd1ce", 

        //Optional. defaults to #CCCCCC overriden by the top-level "loading" set. 
        loading:      "#CCCCCC",

        //Optional. the hover color
        hover:        "#FFFF00",
        
        //Optional. defaults to 10px 
        borderRadius: "10px",   

        //Optional. defaults to 10px
        padding:      "10px",

        //Optional. defaults to false.
        //use this to have no background at all.
        none:        true
      },

      //Can also be like this, in which case all the background colors 
      //will be the one that you enter, and the padding and border-radius 
      //will just go to their default values.
      //background: "#0066CC",

      //the mergeable classes for the outermost wrapper of the button
      outerClasses:"some class",

      //Optional. default is ButtonIconPositionHugLeft
      iconPosition: ButtonIconPositionHugLeft,
      
      //Optional. default is ButtonAlignCenter
      align: ButtonAlignCenter,

      //Optional. The explicit width of the button. 
      //admits any css string. This will also add the sb-explict css class to the root.
      width: "120px",

      //Optional. default is ButtonFlashStandard
      //If flash is just a number, then it will use whatever flash is specified 
      //in the background.flash value.
      flash: ButtonFlashRadial,

      //Else, flash can be like this, in which case the fill will override the
      //the value in background.flash
      // flash: {
      //   //required. 
      //   style: ButtonFlashSlideLeft,
      //   fill: "#0066CC"
      // }

      //Optional. default is ButtonHoverAnimate
      //The flash 
      hover: ButtonHoverAnimate,

      //Optional. the icon. One of the file names in components/icons
      //The icon file has a simple structure. It's a javascript file that contains 
      //two exports: a viewBox and a path export:
      //viewBox: string. the SVG's viewBox attribute
      //path: string or array. The path data string, or an array of path data strings. 
      //  if the latter, then the icon will have multiple paths. 
      icon: "rounded-close-x_50",

      //The mergeable icon style to be used direclty on the svg element
      iconStyle: "transform:rotate(45deg)",

      //Optional. default is false.
      //where is the icon file located. If true, this will come from 
      //the project at project/icons. If false, this will come from the base 
      //project at kit/assets/icons.
      iconFromProject: true,

      //Optional. default is null, in which case it will be just ButtonIconPassiveHeight
      //if ButtonIconPassiveHeight, then this will be as high as the text allows, e.g. height:100%
      //if ButtonIconActiveHeight, then this icon will have a height that you give it- the "active" property. aspect ratio will be preserved
      //if ButtonIconActiveWidth, then this icon will have a width that you give it- the "active" property. aspect radio will be preserved
      //Note there is no ButtonPassiveWidth. That would be an icon that takes up the full width and that's too weird to accomodate.
      iconDimension: {
        style: ButtonIconActiveHeight,
        active: "20px"
      }

      //where is the spinner gif file located. If true, this will come from 
      //the project at project/assets/images/spinners. If false, this will come from the base 
      //project at kit/assets/images/spinners.
      spinnerFromProject: true,

      //Optional. the text
      text: "Close",

      //Optional. is this a frugally widthed element? Defaults to false.
      //This will affect the layout of the button
      isFrugal: false,

      //Optional. will the mouse/touch event propagate?
      allowPropagate: true,

      //Optional. The loader info.
      loader: {

        //optional. if present, it will override the background.loading setting.
        //If not present, then it will just use the 
        fill:"#CCCCCC", 

        //Required. The wheel settings. 
        wheel: {
          
          //Optional. The placement of the loading spinner. One of the ButtonLoaderCover* options.
          //default is ButtonLoaderCoverFull
          placement: ButtonLoaderCoverFull,

          //Required. The animating gif  
          gif:"Spinner-0.4s-310px-ffffff-transparent",
          
          //Optional. The spinner style
          spinnerStyle:"width:120%; height:120%; top:-10%; left:-10%; background-size:contain; background-position:center; position:absolute"
        }
      }
    }

  You can do things like add additional hover animations with regular CSS if you want

  .my-button:hover {
    background-color:#e67829 !important;
  }
  .my-button {
    transition: background-color 0.3s linear;
  }

 **/



export default defineComponent({
  name: "IconButton",
  props: {

    //The background override property corresponding to the theme property of the same name.
    //The colors for the foreground. 
    foreground: {
      required: false, 
      default: null
    },

    //The foreground override property corresponding to the theme property of the same name.
    //The colors and styles for the background.
    background: {
      required: false, 
      default: null,
    },

    //The width override property corresponding to the theme property of the same name.
    //The width of the button. This will add the sb-explicit class to the root.
    width: {
      required: false,
      default: null
    },

    //The align override property corresponding to the theme property of the same name.
    //How the text aligns. Will also affect the icon-position
    //if it's a HugLeft or HugRight.
    align: {
      type: Number, 
      required: false,
      default: 0
    },

    //The flash override property corresponding to the theme property of the same name.
    flash: {
      required: false, 
      default: null
    },

    //The hover override property corresponding to the theme property of the same name.
    hover: {
      type:Number,
      required: false,
      default: 0
    },

    //The loader override property corresponding to the theme property of the same name.
    loader: {
      required: false, 
      default: null
    },

    //The icon override property corresponding to the theme property of the same name.
    //The name has to be one of the components in the kit/components/icons/ folder, without the "Box" suffix
    //if true, the
    icon: {
      type: String,
      required: false
    },

    //The mergeable icon style to be used directly on the svg element
    iconStyle: {
      type: String,
      required: false
    },

    //The iconFromProject override property corresponding to the theme property of the same name.
    //Does the icon come the project, or is it one of the stock icons from the wordpress base?
    iconFromProject: {
      type: Boolean, 
      required: false,
      default: false
    },

    //The iconPosition override property corresponding to the theme property of the same name.
    //The position of the icon, if this is an icon-and-text button.
    //possible values are any of the ButtonIconPosition constants above
    iconPosition: {
      type: Number, 
      required: false, 
      default: 0
    },

    //The iconDimension override property corresponding to the theme property of the same name.
    //The dimension settings of the icon.
    iconDimension: {
      type: Object, 
      required: false,
      default: null
    },

    //The spinnerFromProject override property corresponding to the theme property of the same name.
    //The name has to be one of the components in the kit/components/icons/ folder, without the "Box" suffix
    spinnerFromProject: {
      type: Boolean, 
      required: false,
      default: false
    },

    //The text override property corresponding to the theme property of the same name.
    //The text for the button
    text: {
      type: String,
      require: false
    },

    //The isFrugal override property corresponding to the theme property of the same name.
    //Is the button a frugal element? This will affect 
    //how the button is laid out.
    isFrugal: {
      type: Boolean,
      required: false,
      default: false
    },

    //The allowPropagate override property corresponding to the theme property of the same name.
    //allow event propagation?
    allowPropagate: {
      type: Boolean,
      default: true
    },

    //The theme object
    theme: {
      type: Object, 
      required: true
    },

    //the active property
    active: {
      type: Boolean,
      required: false,
      default: true
    },

    //the loading property
    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    //Mergeable classes for the button
    outerClasses: {
      required: false, 
      default: null
    },

    //The href property. If included, then this will behave like a link
    href: {
      type: String, 
      required: false,
      default: "#"
    },

    //the target attribute, for link
    target: {
      type: String, 
      required: false,
      default:"_self"
    },

    //The to parameter, just like router buttons. the button will 
    //behave like a router button if you use this.
    to: {
      required: false, 
      default: null
    }
  },
  setup(props, context) {

    const { theme } = props

    const defaults = {
      hover: ButtonHoverAnimate,
      align: ButtonAlignCenter,
      iconPosition: ButtonIconPositionHugLeft,
      outerClasses: []
    }

    //the router
    const router = useRouter()

    //Get a set of options. Give the props the priority.
    const getOptionSet = (which) => {
      return props[which] || theme[which] || defaults[which]
    }

    //The reactive property for the active state
    const active_ = toRef(props, 'active')

    //The reactive property for the loading state
    const loading_ = toRef(props, 'loading')

    //The root template ref
    const root = ref(null)

    //The flash element 
    const flashEl = ref(null)

    //The hover element 
    const hoverGlass = ref(null)

    //The icon path and viewbox
    const iconPath = ref("")
    const iconViewBox = ref("")

    //The background style object
    let backgroundStyleObj = null

    //the flash element
    let flashElDomNode = null

    //state-variable to keep animations from piling up
    let flashInMotion = false

    //state-variable to keep animations from piling up
    let hoverInMotion = false

    //the hover-cache to keep track of occluded hover animations
    let hoverCache = 0
    
    //Get the top-level theme option sets.
    const skin = {
      foreground : getOptionSet("foreground"),
      background : getOptionSet("background"),
      loader : getOptionSet("loader"),
      flash : getOptionSet("flash"),
      width : getOptionSet("width"),
      hover : getOptionSet("hover"),
      icon : getOptionSet("icon"),
      iconStyle : getOptionSet("iconStyle"),
      text : getOptionSet("text"),
      isFrugal : getOptionSet("isFrugal"),
      allowPropagate : getOptionSet("allowPropagate"),
      align : getOptionSet("align"),
      iconPosition : getOptionSet("iconPosition"),
      iconFromProject : getOptionSet("iconFromProject"),
      spinnerFromProject : getOptionSet("spinnerFromProject"),
      iconDimension : getOptionSet("iconDimension"),
      outerClasses : getOptionSet("outerClasses"),
    }

    let textOnly = null
    let iconOnly = null 
    let iconAndText = null
    let iconPassive = null
    let iconSVGWidth = null
    let iconSVGHeight = null

    let factive = null
    let finactive = null
    let floading = null
    let fflash = null
    let fhover = null

    let bactive = null
    let binactive = null
    let padding = null
    let borderRadius = null
    let bloading = null
    let bhover = null
    let bNone = false

    let flashStyle = null
    let flashFill = null

    //does it have an extra flash element?
    let hasFlashElement = null

    //Do we have any hover effects?
    let hasHover = null

    //The placement of the loading spinner.
    let spinnerPlacement = null

    //the background style for the loading pane.
    let loadingBackgroundStyle = null

    let loadingGif = null

    const layout = ref(null)


    /**
     * Update the fill of both the backgroun and foreground
     * according to whatever state we're currently in.
     * 
     */
     const updateFill = () => {
      let backgroundColor = bactive
      let foregroundColor = factive

      if(!active_.value) {
        backgroundColor = binactive
        foregroundColor = finactive
      } else 
      if(loading_.value) {
        backgroundColor = bloading
        foregroundColor = floading
      }

      if(backgroundStyleObj) {
        if(backgroundColor && !bNone) {
          backgroundStyleObj.backgroundColor = backgroundColor
        }
        if(foregroundColor) {
          backgroundStyleObj.color = foregroundColor
        }
      }
    } 
    

    /**
     * The icon was  imported, so we're going to record the references 
     * and then update the fill.
     * 
     */
     const iconWasImported = async(shapes, viewBox) => {
      iconPath.value = shapes
      iconViewBox.value = viewBox
      await nextTick()
      updateFill()
    }


    /**
     * The super-box wrapper classes for the root
     * 
     */
    const calculateLayout = () => {

      const { iconPosition, iconDimension, outerClasses } = skin

      let rootClasses = mergeClassesTheme("sb sb-h sb-gr sb-alt2 sb-align-cv z1", outerClasses)
      let iconClasses = "sb z2"
      let _iconStyle = ""

      //Make adjustments for the active and passive icon-sizing methods.
      if(iconPassive) {
        _iconStyle = "width:auto; height:100%; "
      } else 
      if(iconDimension && iconDimension.style == ButtonIconActiveHeight) {
        _iconStyle = `width:auto; height:${iconDimension.active}; `
      } else 
      if(iconDimension && iconDimension.style == ButtonIconActiveWidth) {
        _iconStyle = `height:auto; width:${iconDimension.active}; `
      } else {
        _iconStyle = "width:auto; height:100%; "
      }

      //if we have an explicit width for the button, then make sure that we get this css class.
      if(skin.width) {
        rootClasses += " sb-explicit"
      }

      //If it's just an icon, then we're going to shift it around 
      //depending on the "align" property, and the iconPosition won't 
      //make any difference. Of course, if this really is just a frugal
      //element, then that means we're not going to do any of this, and 
      //the button is just going to be a simple box around an icon.
      //The same is true of the text within the button.
      if(iconOnly || textOnly) {
        iconClasses = "sb z2"

        if(!skin.isFrugal) {
        
          if(skin.align == ButtonAlignRight) {
            rootClasses += " sb-align-2left"
          } else 
          if(skin.align == ButtonAlignLeft) {
            rootClasses += " sb-align-2right"
          } else {
            rootClasses += " sb-align-ch"
          }
        }
      } 

      //If it's an icon and text, then we have a bunch of possibilities that
      //have to be accounted for.
      else 
      if(iconAndText) {
        iconClasses = "sb z2"

        //take care of the alignment first.
        //this doesn't matter if it's a frugally-widthed button
        if(!skin.isFrugal) {

          if(skin.align == ButtonAlignLeft) {
            
            if(iconPosition == ButtonIconPositionFarRight) {
              rootClasses += " sb-content-space-between"
            } else
            if(iconPosition == ButtonIconPositionShiftRight || iconPosition == ButtonIconPositionHugRight) {
              rootClasses += " sb-align-2left"
              _iconStyle += "margin-left:10px"
            } else {
              rootClasses += " sb-align-2left"
              _iconStyle += "margin-right:10px"
            }
          } else 
          if(skin.align == ButtonAlignRight) {
            if(iconPosition == ButtonIconPositionFarLeft) {
              rootClasses += " sb-content-space-between"
            } else
            if(iconPosition == ButtonIconPositionShiftLeft || iconPosition == ButtonIconPositionHugLeft) {
              rootClasses += " sb-align-2right"
              _iconStyle += "margin-right:10px"
            } else {
              rootClasses += " sb-align-2right"
              _iconStyle += "margin-left:10px"
            }

          } else 
          if(skin.align == ButtonAlignCenter) {
            rootClasses += " sb-align-ch"
          
            if(iconPosition == ButtonIconPositionShiftLeft) {
              iconClasses = "sb-abox"
              _iconStyle += "top:0px; left:auto; right:100%; transform:translateX(-10px);" 
            } else 
            if(iconPosition == ButtonIconPositionShiftRight) {
              iconClasses = "sb-abox"
              _iconStyle += "top:0px; left:100%; transform:translateX(10px)" 
            } else 
            if(iconPosition == ButtonIconPositionFarLeft) {
              iconClasses = "sb-abox"
              _iconStyle = "top:0px; left:0px;"
            } else 
            if(iconPosition == ButtonIconPositionFarRight) {
              iconClasses = "sb-abox"
              _iconStyle = "top:0px; left:auto; right:0px;"
            } else 
            if(iconPosition == ButtonIconPositionHugLeft) {
              _iconStyle += "margin-right:10px;"
            } else 
            if(iconPosition == ButtonIconPositionHugRight) {
              _iconStyle += "margin-left:10px;"
            }
          
          }

        }

      }

      //merge the calculated style with the iconStyle from the theme
      _iconStyle = mergeStylesTheme(_iconStyle, skin.iconStyle)

      return { rootClasses, iconClasses, iconStyle:_iconStyle }
    }


    /**
     * @param {Object} Required. The key-value pairs of the different parts of the theme to replace. 
     *
     */
    const reskin = (skinObj) => {

      //reset the skin properties according to the properties of the object
      if(skinObj) {
        var keys = Object.keys(skinObj)
        for(var i=0; i<keys.length; i++) {
          var key = keys[i]
          if(skinObj.hasOwnProperty(key)) {
            var skinItem = skinObj[key]
            if(skinItem) {
              theme[key] = skinItem
              skin[key] = getOptionSet(key)
            }
          }
        }
      }

      const { background, foreground, loader, icon, text, iconFromProject, flash, iconDimension } = skin

      //These are the different configurations that the button can be in. 
      textOnly = !icon
      iconOnly = icon && !text
      iconAndText = icon && text
      
      //is the icon passively sized?
      iconPassive = (!iconDimension || (iconDimension && iconDimension.style == ButtonIconPassiveHeight))
      iconSVGWidth = (iconPassive || (iconDimension && iconDimension.style == ButtonIconActiveHeight)) ? "10" : null
      iconSVGHeight = iconSVGWidth == "" ? "10" : null

      //Colors and styles for the fore and background
      factive = null
      finactive = null
      floading = null
      fflash = null
      fhover = null

      bactive = null
      binactive = null
      padding = null
      borderRadius = null
      bloading = null
      bhover = null
      bNone = false

      if(background.constructor == Object) {
        bactive = background.active 
        binactive = background.inactive || "#CCCCCC"
        padding = background.padding || "10px"
        borderRadius = background.borderRadius || "10px"
        bloading = background.loading || "#CCCCCC"  
        bhover = background.hover 
        bNone = background.none || false
      } else {
        bactive = binactive = bloading = background
        padding = "10px"
        borderRadius = "10px"
      }

      if(foreground.constructor == Object) {
        factive = foreground.active
        finactive = foreground.inactive || "#FFFFFF"
        floading = foreground.loading || "#FFFFFF"
        fflash = foreground.flash
        fhover = foreground.hover
      } else {
        factive = finactive = floading = fflash = foreground
      }

      flashStyle = ButtonFlashStandard 
      flashFill = null

      if(flash && flash.style) {
        flashStyle = flash.style 
      } else 
      if(flash) {
        flashStyle = flash
      }
      if(flash && flash.fill) {
        flashFill = flash.fill
      } else {
        flashFill = background.flash
      }

      //does it have an extra flash element?
      hasFlashElement = (flashStyle == ButtonFlashSlideLeft || flashStyle == ButtonFlashRadial)

      //Do we have any hover effects?
      hasHover = bhover || fhover

      //handle the loader fill
      if(loader && loader.fill) {
        bloading = loader.fill
      }

      //The placement of the loading spinner.
      spinnerPlacement = (loader && loader.wheel && loader.wheel.placement) ? loader.wheel.placement : ButtonLoaderCoverFull

      //the background style for the loading pane.
      loadingBackgroundStyle = `border-radius:${borderRadius}; background-color:${bloading}`

      //reft
      if(icon) {
        if(iconFromProject) {
          import(`@project/icons/${icon}.js`).then(val => {
            //the icon is just stored as a module with a path and viewBox string
            const { shapes, viewBox} = val
            iconWasImported(shapes, viewBox)
          })
        } else {
          import(`@kit/assets/icons/${icon}.js`).then(val => {
            //the icon is just stored as a module with a path and viewBox string
            const { shapes, viewBox} = val
            iconWasImported(shapes, viewBox)
          })
        }
      }

      if(loader && loader.wheel && inBrowser) {
        const { gif } = loader.wheel
        if(skin.spinnerFromProject) {
          loadingGif = require(`@project/assets/images/spinners/${gif}.gif`);
        } else {
          loadingGif = require(`@kit/assets/images/spinners/${gif}.gif`);
        }
      }
    
      //refresh the layout
      layout.value = calculateLayout()
    }

    //build the skin immediately
    reskin()

    /**
     * On mount, were going to record the refernce to the root 
     * and then we're going to adjust the style according to the theme 
     * and then finally update the fill color.
     * 
     */
    onMounted(async() => {
      //record some style references
      await nextTick()

      if(root.value) {
        backgroundStyleObj = root.value.style
      }
      if(flashEl.value) {
        flashElDomNode = flashEl.value
      }

      if(skin.width) {
        backgroundStyleObj.width = skin.width
      }
      if(borderRadius) {
        backgroundStyleObj.borderRadius = borderRadius
      }
      if(padding) {
        backgroundStyleObj.padding = padding
      }

      if(active_.value) {
        backgroundStyleObj.cursor = "pointer"
      } else {
        backgroundStyleObj.cursor = "default"
      }

      if(hasHover) {
        hoverGlass.value.addEventListener("mouseover", handleHoverOn)
        hoverGlass.value.addEventListener("mouseout", handleHoverOff)
      }

      updateFill()
    })


    /**
     * Remove the event listeners
     * 
     */
    onBeforeUnmount(() => {
      if(hasHover) {
        hoverGlass.value.removeEventListener("mouseover", handleHoverOn)
        hoverGlass.value.removeEventListener("mouseout", handleHoverOff)
      }
    })



    /**
     * change the fill for either background or foreground.
     * 
     */
    const changeFill = (isForeground, newColor) => {
      if(backgroundStyleObj) {
        if(isForeground) {
          backgroundStyleObj.color = newColor
        } else {
          if(!bNone) {
            backgroundStyleObj.backgroundColor = newColor
          }
        }
      }
    }


    /**
     * The aria role, which changes depending on the use of the href and the to properties
     * 
     */
    const ariaRole = computed(() => {
      if(props.href != "#" || props.to != null) {
        return "link"
      } else {
        return "button"
      }
    })

    

    /**
     * Is the icon's path an array?
     * 
     */
    const iconIsMultiPath = computed(() => {
      const shapes = iconPath.value 
      if(shapes) {
        if(shapes.constructor == Array) {
          return true
        } else 
        if(shapes.constructor == String || shapes.constructor == Object) {
          return false
        }
      }
      return false
    })




    /**
     * resolve the hover state
     * 
     */
    const resolveHoverCache = () => {
      if(hoverCache == 1) {
        handleHoverOn(null)
      } else 
      if(hoverCache == 2) {
        handleHoverOff(null)
      }
      hoverCache = null
    }


    /**
     * Do the hover
     * 
     */
    const handleHoverOn = async(_e) => {

      //If there's already an animation in motion, then we're 
      //going to cache this, and we're going to have to wait 
      //until the animation is over before we handle it.
      if(hoverInMotion || flashInMotion || !active_.value || loading_.value) {
        hoverCache = 1
        return
      }

      if(skin.hover == ButtonHoverFlash) {
        if(bhover && !bNone) {
          backgroundStyleObj.backgroundColor = bhover
        }
        if(fhover) {
          backgroundStyleObj.color = fhover
        }
      } else 
      if(skin.hover == ButtonHoverAnimate && bactive && !bNone) {
          
        const anObj = {
          targets:root.value,
          duration:300,
          easing:"linear"
        }

        if(bhover) {
          anObj.backgroundColor = bhover
        }
        if(fhover) {
          anObj.color = fhover
        }

        await animate(anObj)
      }

      hoverInMotion = false

      //a hover event may have happened while this animation
      //was running, so we're going to handle that now
      if(hoverCache) {
        resolveHoverCache()
      }

    }
    const handleHoverOff = async(_e) => {

      //If there's already an animation in motion, then we're 
      //going to cache this, and we're going to have to wait 
      //until the animation is over before we handle it.
      if(hoverInMotion || flashInMotion || !active_.value || loading_.value) {
        hoverCache = 2
        return
      }

      let backgroundColor = bactive
      let foregroundColor = factive

      if(!active_.value) {
        backgroundColor = binactive
        foregroundColor = finactive
      } else 
      if(loading_.value) {
        backgroundColor = bloading
        foregroundColor = floading
      }

      if(skin.hover == ButtonHoverFlash) {
        if(backgroundColor && !bNone) {
          backgroundStyleObj.backgroundColor = backgroundColor
        }
        if(foregroundColor) {
          backgroundStyleObj.color = foregroundColor
        }
      } else 
      if(skin.hover == ButtonHoverAnimate && bactive && !bNone) {
          
        const anObj = {
          targets:root.value,
          duration:300,
          easing:"linear"
        }

        if(bhover) {
          anObj.backgroundColor = backgroundColor
        }
        if(fhover) {
          anObj.color = foregroundColor
        }

        await animate(anObj)
      }

      hoverInMotion = false
      
      //a hover event may have happened while this animation
      //was running, so we're going to handle that now
      if(hoverCache) {
        resolveHoverCache()
      }
    }


    /**
     * do the link and route mechanics.
     * 
     */
    const handleLinkAndRouteBehavior = () => {
      if(props.href != "#") {
        if(props.target == "_blank") {
          const anchor = document.createElement('a');
          anchor.href = props.href
          anchor.target="_blank"
          anchor.click();
        } else {
          window.location.replace(props.href)
        }
      } else 
      if(props.to != null) {
        router.push(props.to)
      }
    }


    /**
     * On click, do the jazz with the events
     * 
     */
    const handleClick = (e) => {
      //if we're not allowing the propagation, then stop it right here.
      if(!skin.allowPropagate) {
        e.stopPropagation();
      }
      
      //In any case, we're going to prevent the default behavior because this thing is actually a link element
      e.preventDefault(); 
    }


    /**
     * On click, we're going to flash the color.
     * 
     */
    const handleMousedown = async(e) => {

      //if we're not allowing the propagation, then stop it right here.
      if(!skin.allowPropagate) {
        e.stopPropagation();
      }
      
      //In any case, we're going to prevent the default behavior because this thing is actually a link element
      e.preventDefault();

      //If not active, then bail out.
      if(!active_.value) {
        return
      }

      if(skin.foreground.flash && fflash) {
        changeFill(true, fflash)
      }

      //If there's not a flashFill, then just bail out.
      if(!flashFill || bNone) {
        updateFill()
        context.emit("buttonClick",e)
        return
      } 

      //If it's the standard flash style, then we're just going to
      //show the flash color for a split second.
      else
      if(flashStyle == ButtonFlashStandard) {
        if(skin.background.flash) {
          changeFill(false, flashFill)
        }
        await sleep(100)
      } 
      
      //else, it's the slide left style. So start the element on the left 
      //and expand it, then fade it out. looks pretty slick.
      else 
      if(flashStyle == ButtonFlashSlideLeft) {

        if(!flashInMotion) {
          flashInMotion = true
          flashElDomNode.setAttribute("style",`opacity:1; left:0px; top:0px; height:100%; width:0px; background-color:${flashFill};`)
          await animate({
            targets: flashElDomNode,
            width:"100%",
            easing:"linear",
            duration:150
          })
          await animate({
            targets: flashElDomNode,
            opacity:"0",
            easing:"linear",
            duration:150
          })
          flashInMotion = false

          //a hover event may have happened while this animation
          //was running, so we're going to handle that now
          if(hoverCache) {
            resolveHoverCache()
          }
        }
      } 

      //If the radial style, then we're going to draw a circle and spread it out
      //and fade out. looks pretty slick
      else 
      if(flashStyle == ButtonFlashRadial) {

      const rect = root.value.getBoundingClientRect()
      const {x:pageX, y:pageY } = pointerEventToXY(e)
      const { width, height, left, top } = rect

    
      const x = left
      const y = top + (window.scrollY || document.documentElement.scrollTop)
      const mX = pageX - x 
      const mY = pageY - y 

      //a faster animation looks a little nicer for small buttons
      const growDuration = width < 100 ? 200 : 300;
    
      const rX = Math.max(width - mX, mX)
      const rY = Math.max(height - mY, mY)
      const radius = Math.sqrt(rX*rX + rY*rY)
      const diameter = radius * 2
      // find which is bigger, the mX or w-Mx
      // |--------------w  --------------| //
      // |----mX---|
      //           |--------w - mX ------|

        if(!flashInMotion) {
          flashInMotion = true
          flashElDomNode.setAttribute("style",`opacity:1; transform:translate(-50%, -50%); border-radius:50%; left:${mX}px; top:${mY}px; height:0%; width:0%; background-color:${flashFill};`)
          await animate({
            targets: flashElDomNode,
            width:diameter+"px",
            height:diameter+"px",
            easing:"linear",
            duration:growDuration,
            opacity:0
          })
          //I just rolled this animation up into the other one.
          //it's just a matter of taste, really.
          // await animate({
          //   targets: flashElDomNode,
          //   opacity:"0",
          //   easing:"linear",
          //   duration:150
          // })
          flashInMotion = false

          //a hover event may have happened while this animation
          //was running, so we're going to handle that now
          if(hoverCache) {
            resolveHoverCache()
          }

        }
      }

      updateFill()
      context.emit("buttonClick",e)
      handleLinkAndRouteBehavior()

    }


    /**
     * Reset the states.  
     * 
     */
    watch(active_, async(newVal, _oldVal) => {
      await nextTick()
      updateFill()

      if(newVal) {
        backgroundStyleObj.cursor = "pointer"

        //a hover event may have happened while this animation
        //was running, so we're going to handle that now
        if(hoverCache) {
          resolveHoverCache()
        }

      } else {
        backgroundStyleObj.cursor = "default"
      }
    })  
    watch(loading_, async(newVal, _oldVal) => {
      await nextTick()
      updateFill()

      if(newVal) {
        backgroundStyleObj.cursor = "default"
      } else {
        if(active_.value) {
          backgroundStyleObj.cursor = "pointer"

          //a hover event may have happened while this animation
          //was running, so we're going to handle that now
          if(hoverCache) {
            resolveHoverCache()
          }

        } else {
          backgroundStyleObj.cursor = "default"
        }
      }
    }) 


    context.expose({ reskin })
    
    return { 
      reskin,
      iconPath,
      iconViewBox,
      iconIsMultiPath,
      handleClick, 
      handleMousedown,
      active_, 
      loading_, 
      skin,
      root,
      flashEl,
      layout,
      loadingGif,
      hasHover, 
      hoverGlass,
      iconPassive,
      loadingBackgroundStyle,
      hasFlashElement,
      ariaRole,
      textOnly, 
      iconOnly, 
      iconAndText,
      iconSVGWidth,
      iconSVGHeight,
      spinnerPlacement,
      ButtonIconPositionHugRight,
      ButtonIconPositionHugLeft,
      ButtonIconPositionFarRight, 
      ButtonIconPositionFarLeft,
      ButtonIconPositionShiftLeft,
      ButtonIconPositionShiftRight,
      ButtonAlignLeft,
      ButtonAlignRight,
      ButtonAlignCenter
    }
  }
});
</script>

<style scoped>
  .z0 {
    z-index: -1 !important;
  }
  .z1 {
    z-index: 1 !important;
  }
  .z2 {
    z-index: 2 !important;
  }
  .z3 {
    z-index: 3 !important;
  }
</style>


<template>
  
  <a :aria-role="ariaRole" :href="href" style="overflow:hidden;" ref="root" @mousedown="handleMousedown" @click="handleClick" :class="layout.rootClasses">
    
    <template v-if="iconOnly">

      <div ref="flashEl" class="sb-abox z0" v-if="hasFlashElement"></div>
      <template v-if="iconPath">
        <svg 
          :class="layout.iconClasses" :style="layout.iconStyle" 
          :width="iconSVGWidth"
          :height="iconSVGHeight"
          xmlns="http://www.w3.org/2000/svg" 
          :viewBox="iconViewBox"
          fill="currentColor">
            <template v-if="iconIsMultiPath">
              <template v-for="shape in iconPath">
                <template v-if="shape.type == 'path'">
                  <path :d="shape.data"/>
                </template>
                <template v-else-if="shape.type == 'polygon'">
                  <polygon :points="shape.data"/>
                </template>
              </template>
            </template>
            <template v-else>
              <path v-if="iconPath.type == 'path'" :d="iconPath.data"/>
              <polygon v-else-if="iconPath.type == 'polygon'" :points="iconPath.data" />
            </template>
        </svg>
      </template>

      <span v-if="loading && skin.loader" class="sb-spread z2" :style="loadingBackgroundStyle">
        <i v-if="loadingGif" :style="`background-image:url(${loadingGif}); ${skin.loader.wheel.spinnerStyle || ''}`"></i>
      </span>
      <div v-if="hasHover" ref="hoverGlass" class="sb-spread z3"></div>

    </template>


    <template v-else-if="textOnly">

      <div ref="flashEl" class="sb-abox z0" v-if="hasFlashElement"></div>
      <div v-html="skin.text" class="z1"></div>
      <span v-if="loading && loader" class="sb-spread z2" :style="loadingBackgroundStyle">
        <i v-if="loadingGif" :style="`background-image:url(${loadingGif}); ${skin.loader.wheel.spinnerStyle || ''}`"></i>
      </span>
      <div v-if="hasHover" ref="hoverGlass" class="sb-spread z3"></div>
    
      <span v-if="loading && skin.loader" class="sb-spread z2" :style="loadingBackgroundStyle">
        <i v-if="loadingGif" :style="`background-image:url(${loadingGif}); ${skin.loader.wheel.spinnerStyle || ''}`"></i>
      </span>
      <div v-if="hasHover" ref="hoverGlass" class="sb-spread z3"></div>


    </template>


    <template v-else-if="iconAndText">

      <div ref="flashEl" class="sb-abox z0" v-if="hasFlashElement"></div>

      <template v-if="skin.iconPosition == ButtonIconPositionHugLeft || (skin.iconPosition == ButtonIconPositionFarLeft && skin.align != ButtonAlignCenter) || (skin.iconPosition == ButtonIconPositionShiftLeft && skin.align != ButtonAlignCenter)">
        <svg 
          v-if="iconPath"
          :class="layout.iconClasses" 
          :style="layout.iconStyle"
          :width="iconSVGWidth"
          :height="iconSVGHeight"
          xmlns="http://www.w3.org/2000/svg" 
          :viewBox="iconViewBox"
          fill="currentColor">
            <template v-if="iconIsMultiPath">
              <template v-for="shape in iconPath">
                <template v-if="shape.type == 'path'">
                  <path :d="shape.data"/>
                </template>
                <template v-else-if="shape.type == 'polygon'">
                  <polygon :points="shape.data"/>
                </template>
              </template>
            </template>
            <template v-else>
              <path v-if="iconPath.type == 'path'" :d="iconPath.data"/>
              <polygon v-else-if="iconPath.type == 'polygon'" :points="iconPath.data" />
            </template>
        </svg>
        <span class="sb z1" v-html="skin.text"></span>
      </template>

      <template v-else-if="skin.iconPosition == ButtonIconPositionFarLeft && skin.align == ButtonAlignCenter">
        <div class="sb-abox" style="left:10px; top:10px; bottom:10px; width:auto;">
          <svg 
            v-if="iconPath"
            :class="layout.iconClasses" 
            :style="layout.iconStyle" 
            :width="iconSVGWidth"
            :height="iconSVGHeight"
            xmlns="http://www.w3.org/2000/svg" 
            :viewBox="iconViewBox"
            fill="currentColor">
            <template v-if="iconIsMultiPath">
              <template v-for="shape in iconPath">
                <template v-if="shape.type == 'path'">
                  <path :d="shape.data"/>
                </template>
                <template v-else-if="shape.type == 'polygon'">
                  <polygon :points="shape.data"/>
                </template>
              </template>
            </template>
            <template v-else>
              <path v-if="iconPath.type == 'path'" :d="iconPath.data"/>
              <polygon v-else-if="iconPath.type == 'polygon'" :points="iconPath.data" />
            </template>
          </svg>
        </div>
        <span class="sb z1" v-html="skin.text"></span>
      </template>

      <template v-else-if="skin.iconPosition == ButtonIconPositionHugRight || (skin.iconPosition == ButtonIconPositionFarRight && skin.align != ButtonAlignCenter) || (skin.iconPosition == ButtonIconPositionShiftRight && skin.align != ButtonAlignCenter)">
        <span class="sb z1" v-html="skin.text"></span>
        <svg 
          v-if="iconPath"
          :class="layout.iconClasses" 
          :style="layout.iconStyle" 
          :width="iconSVGWidth"
          :height="iconSVGHeight"
          xmlns="http://www.w3.org/2000/svg" 
          :viewBox="iconViewBox"
          fill="currentColor">
            <template v-if="iconIsMultiPath">
              <template v-for="shape in iconPath">
                <template v-if="shape.type == 'path'">
                  <path :d="shape.data"/>
                </template>
                <template v-else-if="shape.type == 'polygon'">
                  <polygon :points="shape.data"/>
                </template>
              </template>
            </template>
            <template v-else>
              <path v-if="iconPath.type == 'path'" :d="iconPath.data"/>
              <polygon v-else-if="iconPath.type == 'polygon'" :points="iconPath.data" />
            </template>
        </svg>
      </template>

      <template v-else-if="skin.iconPosition == ButtonIconPositionFarRight && skin.align == ButtonAlignCenter">
        <span class="sb z1" v-html="skin.text"></span>
        <div class="sb-abox" style="left:auto; top:10px; right:10px; bottom:10px; width:auto;">
        <svg 
          v-if="iconPath"
          :class="layout.iconClasses" 
          :style="layout.iconStyle" 
          :width="iconSVGWidth"
          :height="iconSVGHeight"
          xmlns="http://www.w3.org/2000/svg" 
          :viewBox="iconViewBox"
          fill="currentColor">
            <template v-if="iconIsMultiPath">
              <template v-for="shape in iconPath">
                <template v-if="shape.type == 'path'">
                  <path :d="shape.data"/>
                </template>
                <template v-else-if="shape.type == 'polygon'">
                  <polygon :points="shape.data"/>
                </template>
              </template>
            </template>
            <template v-else>
              <path v-if="iconPath.type == 'path'" :d="iconPath.data"/>
              <polygon v-else-if="iconPath.type == 'polygon'" :points="iconPath.data" />
            </template>
        </svg>
        </div>
      </template>

      <template v-else-if="skin.align == ButtonAlignCenter && skin.iconPosition == ButtonIconPositionShiftLeft">
        <div class="sb sb-text z1">
          <svg 
            v-if="iconPath"
            :class="layout.iconClasses" 
            :style="layout.iconStyle" 
            :width="iconSVGWidth"
            :height="iconSVGHeight"
            xmlns="http://www.w3.org/2000/svg" 
            :viewBox="iconViewBox"
            fill="currentColor">
            <template v-if="iconIsMultiPath">
              <template v-for="shape in iconPath">
                <template v-if="shape.type == 'path'">
                  <path :d="shape.data"/>
                </template>
                <template v-else-if="shape.type == 'polygon'">
                  <polygon :points="shape.data"/>
                </template>
              </template>
            </template>
            <template v-else>
              <path v-if="iconPath.type == 'path'" :d="iconPath.data"/>
              <polygon v-else-if="iconPath.type == 'polygon'" :points="iconPath.data" />
            </template>
          </svg>
          <span v-html="skin.text"></span>
        </div>
      </template>

      <template v-else-if="skin.align == ButtonAlignCenter && skin.iconPosition == ButtonIconPositionShiftRight">
        <div class="sb sb-text z1">
          <span v-html="skin.text"></span>
          <svg 
            v-if="iconPath"
            :class="layout.iconClasses" 
            :style="layout.iconStyle" 
            :width="iconSVGWidth"
            :height="iconSVGHeight"
            xmlns="http://www.w3.org/2000/svg" 
            :viewBox="iconViewBox"
            fill="currentColor">
            <template v-if="iconIsMultiPath">
              <template v-for="shape in iconPath">
                <template v-if="shape.type == 'path'">
                  <path :d="shape.data"/>
                </template>
                <template v-else-if="shape.type == 'polygon'">
                  <polygon :points="shape.data"/>
                </template>
              </template>
            </template>
            <template v-else>
              <path v-if="iconPath.type == 'path'" :d="iconPath.data"/>
              <polygon v-else-if="iconPath.type == 'polygon'" :points="iconPath.data" />
            </template>
          </svg>
        </div>
      </template>

      <template v-else>
        <svg 
          v-if="iconPath"
          :class="layout.iconClasses" 
          :style="layout.iconStyle" 
          :width="iconSVGWidth"
          :height="iconSVGHeight"
          xmlns="http://www.w3.org/2000/svg" 
          :viewBox="iconViewBox"
          fill="currentColor">
            <template v-if="iconIsMultiPath">
              <template v-for="shape in iconPath">
                <template v-if="shape.type == 'path'">
                  <path :d="shape.data"/>
                </template>
                <template v-else-if="shape.type == 'polygon'">
                  <polygon :points="shape.data"/>
                </template>
              </template>
            </template>
            <template v-else>
              <path v-if="iconPath.type == 'path'" :d="iconPath.data"/>
              <polygon v-else-if="iconPath.type == 'polygon'" :points="iconPath.data" />
            </template>
        </svg>
        <span class="sb z1" v-html="skin.text"></span>
      </template>

      <span v-if="loading && skin.loader" class="sb-spread z2" :style="loadingBackgroundStyle">
        <i v-if="loadingGif" :style="`background-image:url(${loadingGif}); ${skin.loader.wheel.spinnerStyle || ''}`"></i>
      </span>
      <div v-if="hasHover" ref="hoverGlass" class="sb-spread z3"></div>

    </template>

  </a>

</template>