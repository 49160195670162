export const lazyImgTheme = {
  loader: {
    wheel: "default",
    fromProject: true,
  },
  loadingStyle: "object-fit:contain; width:100%; height:auto; padding: 30%;",
}

export const lazyImgTheme2 = {
  loader: {
    wheel: "Spinner-04s-200px",
    fromProject: false,
  },
  loadingStyle: "object-fit:contain; width:100%; height:auto; padding: 30px;",
}