/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\

  Helpers for environment variables
  This exposes a couple of handy variables which we often use, 
  as well as a general-use getEnv function. 

  The way this works is that each project has a list of the client-safe 
  environment variable in the clientSafeVars.js file. During the webpack 
  phase, these variables are fetched from the .env file and inserted into 
  an object which is then injected to the _envVars variable by webpack. 

  Note that the client-safe environment variables are all availabe at 
  runtime. So each project can pull the variables it needs at its 
  convenience with the getEnv function. But this also means that you 
  really can't put sensitive variables in the clientSafeVars lists.

  Author Alex Lowe

\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
import { useRoute } from 'vue-router'

const required = ["HOST","PROJECT","FRONTEND_BUILD","FRONTEND_OVERRIDE","FRONTEND_SSR"]
const envVars = _envVars

export const getEnv = (envVarName, defaultVal) => {
  return envVars[envVarName] || defaultVal
}

export const checkEnv = () => {
  const missing = []

  for(let i=0; i<required.length; i++) {
    const r = required[i]
    if(!getEnv(r, null)) {
      missing.push(r)
    }
  }

  if(missing.length > 0) {
    throw Error(`ERROR: Missing one or more environment variables from the client: ${missing.join(', ')}. 
    One the development environment. Consult the safety file and ensure that these variables are shared with the client.`)
  }

}

//The app mode. On the client, this gets set from webpack files, and it works 
//with the getEnv function. On the server, we set this in ssr-dev and ssr-prod
//Kind of annoying to do it that way but then again environmental variables 
//are always annoying.
let _appMode = getEnv("appMode","dev")
export const appMode = () => { return _appMode } 
export let isAppDev = () => { return appMode() == "dev" }
export let isAppProd = () => { return appMode() == "prod" }
export const setAppModeFromServer = (appModeFromServer) => {
  _appMode = appModeFromServer
}

//The mobile threshold. This is set from the App component. There's a watcher
//tied to a media-query object.
let _mobileThreshold = false
export const inMobileThreshold = () => { return _mobileThreshold }
export const setMobileThresholdFromApp = (mobileThreshold) => {
  _mobileThreshold = mobileThreshold
}

//This is where the resize index is stored and incremented. This is so that things 
//like the Scroller, Parallax and MouseOut tools, anything that depends on calculated 
//bounds can be refreshed with the intersection observer technique, which lets us do those
//things without any dom-thrashing.
let _resizeIndex = 0 
export const incrementResize = () => {
  _resizeIndex++
  if(_resizeIndex > 100000) {
    _resizeIndex = 0
  }
}
export const getResize = () => {
  return _resizeIndex
}

export const SSR = getEnv('SSR')
export const inBrowser = (typeof window !== 'undefined')
export const mainMode = getEnv("MAIN_MODE")
export const isLocationLocal = mainMode == "local"
export const isLocationDev = mainMode == "dev"
export const isLocationProd = mainMode == "prod"
export const inMobile = (inBrowser && 'ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/))
//export const baseURL = mainMode == "dev" ? `https://${getEnv("PROJECT").toLowerCase()}.${getEnv('HOST')}` : `https://${getEnv('HOST')}`


//https://stackoverflow.com/questions/74313025/is-it-possible-to-retrieve-the-full-viewport-height-on-ios-safari-via-js


export const getScrollY = () => {
  if(inBrowser) {
    if(inMobile) {
      return window.scrollY || document.body.scrollTop
    } else {
      return window.pageYOffset || 
            document.body.scrollTop ||
            document.documentElement.scrollTop || 0;
    }
  }
  return 0
}

export const getViewportHeight = () => {
  if(inBrowser) {
    if(inMobile) {
      //try clientHeight first. There's weird behavior in safari where it
      //want's to also have the url bar and contracting ui bar count as height.
      //this is not typically what we want. //document.documentElement.clientHeight ||
      //there's also outerHeight and bunch of other crap that doesn't seem to work, like clientHeight.
      //This availHeight actually works
      //Thank you https://dmitripavlutin.com/screen-window-page-sizes/
      return window.screen.availHeight || window.innerHeight
    } else {
      return window.innerHeight
    }
  }
  return 0
}


const baseURLKVP = {
  "local":"https://localhost",
  "dev": (getEnv("PROJECT_AS_SUBDOMAIN") == "true" 
      ? `https://${getEnv("PROJECT").toLowerCase()}.${getEnv('HOST')}`
      : `https://${getEnv('HOST')}`),
  "prod": `https://${getEnv('HOST')}`
}
export const baseURL = baseURLKVP[mainMode]

export const getFullURL = () => {
  const route = useRoute()
  if(route) {
    const path = route.path 
    return path == "/" ? baseURL : `${baseURL}${path}` 
  } else 
  if(inBrowser) {
    return window.location.href
  } else {
    throw new Error("getFullURL: I can't do this. You're probably using this serverside outside of the setup function.")
  }
}

export default function() {}