/*
----------------------------------------------------------------------------------------------
--
-- Parallax 
-- 
-- A library to handle parallax effects 
--
-- Author Alex Lowe
--
-- simple-parallax-js scaled the images and didn't do perspective. 
-- it just scaled, which looked ugly
--
-- parallax-js looked glorious, but it didn't handle the most obvious use-case,
-- which is scrolling. It does everything BUT scrolling. ARG
--
-- Saw a CSS-only solution, but you get these terrible overflow side-effects that
-- are impossible to mitigate, so that didn't work.
-- Here's the codepen where this was at.
-- https://codepen.io/bennettfeely/pen/RNwMyy
--
-- Everything else was jquery, which I'm not going near, or react, which I'm not using
--
-- So it wasn't my first choice by far to write this code, but as is so frequently
-- the case, all of the dropin solutions that promise to do everything just don't work out.
--
To Use:

  const p = new Parallax()
  p.register(img.value, { type:"basic", depth:0.05, mdepth:0.15 })

  onBeforeUnMount(() => {
    p.destroy()
  })

type: basic 
  The type of parallax. Currently this is the only one and we don't even use this 
  information yet

depth: the depth for the depth effect. optional. defaults to 0.05

mdepth: the depth for mobile. optional. defaults to 0.15. Usually this is like 3x the
  normal depth because the mobile browser window has a different aspect.

This will scale the image and move it around to fake a farther-away perspective
effect. The parallax area is a height pH, the parallax area has a y-coordinate
in the document's coordinate system at pY. The viewport area is a a height vH, 
and the viewport has a y-coordinate vY.

It's easiest to think of a zero-scale scenario (i.e. background at infinity, i.e. a
  perfectly fixed position.) In that case, the image must be as high as the viewport
area, and so it must scale pSc (pSc = vH/pH) and it must have a y-coordinate in the
document's coordinate system of vY. That is to say, it's always flush to the top of
the viewport, and always big enough to cover the viewport. So no matter how you're 
scrolling, the image always appears to be stationary. The real-world version of this 
just introduces a linear scaling factor.

As far as the pure geometry goes, there's three tricks:

1: Calculate the coordinate of the viewport in the document's frame. That's vY. That's 
   simply the scrolling amount, scrollY.

2: We have vY, but it's in the document's coordinate system. We need to transform it 
   to the parallax's coodinate system. Formally, this is a passive transformation, 
   and we can do that easily because we know the ycoordinate of the parallax area. 
   that's pY. We use getBoundingClientRect + scrollY to get that. (Thank you, S.O. 
   see the notes below)

3: We have to be careful now because we also have resize events in which we will need
   to recalculate the parallax math. We have to reset the scale and position before 
   we use getBoundingClientRect, because otherwise we get a feedback effect of 
   feeding the transform back to itself. 

Mobile is another story. Getting it work acceptably was pretty hard. This article 
provided the key to doing it. Basically we need to use requestAnimationFrame and do this
clever technique of constantly easing the calculated scrollingY that we use to match the
real-world value coming from the hardware. We don't get animation frames at the same 
rate that the user sees when they're scrolling the page up and down. Even 
requestAnimationFrame doesn't match it, so just setting the y-position every frame gives
us an ugly choppy effect. Applying the ease smooths it out so that it's always animating
instead of jumping from one scroll value to the next. 

https://www.digitalocean.com/community/tutorials/implementing-a-scroll-based-animation-with-javascript

Troubleshooting.
If the parallax doesn't work, try logging the getWindowScrollY, getWindowHeight functions.



-----------------------------------------------------------------------------------------------
*/


import { inMobile, getViewportHeight } from '@kit/utils/EnvironmentHelper'
import { getBoundingClientRect } from './BoundingClientRect'
import Scroller from '@kit/utils/Scroller'
import Config from './Config'

const wCtx = typeof window != 'undefined' ? window : null

const DOMEL_PROP0 = '__0mvm72gds'
const DOMEL_PROP_INTERSECT = '__i3i8dcgwa'

class Parallax {

  static inBrowser = typeof window !== 'undefined'
  static listenersAdded = false
  static scroller = null
  static numParallaxes = 0
  static firstParallax = null 
  static lastParallax = null
  static dInterval = null
  static dCache = null
  static scrollY = 0

  static heightChange = null
  static heightChangeSavedY = 0
  static heightChangeStack = 0
  static heightChangeLimit = 2
  static heightChangeRefreshed = false
  
  static configHelper = new Config({
    debug:{
      showAreas:false
    },
    height: {
      query: "body",
      poll: {
        interval:500,
        behavior: {
          name:"continuous",
          stableThreshold: 3
        }
      }
    }
  })

  constructor() {
    this.parallaxes = []
    this.destroyed = false
  }

  /**
   * Get current absolute window scroll position
   * https://stackoverflow.com/questions/70752798/forced-reflow-violation-and-page-offset-is-it-normal
   * 
   * Not sure how to do this without causing a reflow. This is for another day.
   */
  
  static getWindowYScroll() {
    if(inMobile) {
      return wCtx.scrollY || document.body.scrollTop
    } else {
      return window.pageYOffset || 
            document.body.scrollTop ||
            document.documentElement.scrollTop || 0;
    }
  }
 
  static addParallaxToChain(fxInfo) {
    if(Parallax.numParallaxes == 0) {
      Parallax.firstParallax = fxInfo
      Parallax.lastParallax = fxInfo
      Parallax.addListeners()
    } else {

      if(Parallax.lastParallax) {
        Parallax.lastParallax.next = fxInfo
      }
      fxInfo.prev = Parallax.lastParallax
      Parallax.lastParallax = fxInfo

    }
    Parallax.numParallaxes++
  }
  static removeParallaxFromChain(fxInfo) {
    if(fxInfo.prev) {
      fxInfo.prev.next = fxInfo.next
    } else {
      Parallax.firstParallax = fxInfo.next
    }
    if(fxInfo.next) {
      fxInfo.next.prev = fxInfo.prev
    } else {
      Parallax.lastParallax = fxInfo.prev
    }

    Parallax.numParallaxes--

    Parallax.scroller.unregisterNode(fxInfo._el.parentNode)

    if(!Parallax.firstParallax && !Parallax.lastParallax) {
      Parallax.removeListeners()
    }

    //destroy some references at the very end
    fxInfo._el[DOMEL_PROP0] = null
    fxInfo.plx = null
    fxInfo._el = null
    fxInfo.prev = null 
    fxInfo.next = null
  }


  //Call this function before an animation happens on a parallax element.
  static async animationLockBounds(elementToLock, _yDelta) {

    const yDelta = _yDelta || 0
    
    //Loop through, find all of the parallaxes whose
    //that belong in the ancestry of the elementToLock. 
    let fxInfo = Parallax.firstParallax
    while(fxInfo) {
      const plxEl = fxInfo._el 
      let plxParent = plxEl
      while(plxParent) {

        //Great, we found one that's in the ancestry of the elementToLock
        //So now what we're going to do is get the boundingClientRect. Internally,
        //it will cache it, and then we're setting boundsCache on the element so that 
        //it knows that it has to retrieve the cached bounds. This is so that the 
        //animation doesn't cause the getBoundingClientRect to report interstitial values 
        //for the bounding elements.
        //
        //This is used in the following way: On reveal, the leader element of the page is dropped off
        //at a translateY = -50px, then it animates down to 0 and fades in. It looks cool, but it presents
        //us with a problem, which is what bounds does the parallax use? Well, on its own, the 
        //getBoundingClientRect will detect that -50 translation and use that when calculating the parallax.
        //Gaaa! we don't want that because it will leave a visible gap when it animates down the translateY = 0.
        //So we override this by nudging the top/y rectangle boundaries over by the same amount. 50px.
        //That way, our parallax THINKS it's just sitting at y=0 instead of y=-50.
        if(plxParent == elementToLock) {

          if(!fxInfo.boundsCache) {
            fxInfo.boundsCache = await getBoundingClientRect(plxEl.parentNode, false)
          }

          const b = await getBoundingClientRect(plxEl.parentNode, true)

          b.top += yDelta
          b.bottom += yDelta 
          b.y += yDelta
          fxInfo.boundsCache = b
          break
        }
        plxParent = plxParent.parentNode
      }
      fxInfo = fxInfo.next
    }

  }

  static animationUnlockBounds(elementToLock) {

    //Loop through, find all of the parallaxes whose
    //that belong in the ancestry of the elementToLock. 
    let fxInfo = Parallax.firstParallax
    while(fxInfo) {
      const plxEl = fxInfo._el 
      let plxParent = plxEl
      while(plxParent) {
        //Great, we found one. Set this state so that it knows 
        //to retrieve fresh bounds data.
        if(plxParent == elementToLock) {
          fxInfo.boundsCache = null
          break
        }
        plxParent = plxParent.parentNode
      }
      fxInfo = fxInfo.next
    }

  }


  /**
   * Run the parallax. 
   * 
   * This is a passive transformation between the document coordinate system (dSys)
   * to the parallax area coordinate system, which is an div element on the page (pSys)
   * 
   * The image has to be at least as high as the viewport, and at least as wide as the parent container.
   * 
   * Then the next task is to perform the coordinate transformation. In dSys,
   * the image is situated at y = scrollY, and the pSys is translated by an amount
   * pY. The transformed coordinate is pY - scrollY, and then we apply the scale 
   * factor to give the illusion of depth.
   * 
   * 
   *     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~      _ _ _ _          _ _ _ _ _
   *     |                        login |         ^                ^
   *     |         MY HOMEPAGE          |         |                |
   *     |                              |         |                |
   *     |                              |         |  ScrollY       |
   *     |                              |         |                |
   *     |                              |         |                |  ImgY
   *     |                              |         |                |
   *     |                              |         |                |
   * ____|______________________________|____  _ _v_ _             |
   * |   |         Viewport             |    |    ^                |
   * |   |                              |    |    | ImgY'          |
   * |   |                              |    |    V                V
   * |   |      ******************      |    | * * * *         * * * * *
   * |   |      *                *      |    |
   * |   |      *                *      |    | 
   * |   |      *    My Image    *      |    |   We transform the image up by ImgY',
   * |   |      *                *      |    |   which is the passively tranformed coordinates in the 
   * |   |      *                *      |    |   viewport frame, and this is just ImgY - ScrollY.
   * |   |      ******************      |    |
   * |   |                              |    |
   * |___|______________________________|____|
   *     |                              |
   *     |                              |
   *     |                              |
   *     |                              |
   *     |                              |
   *     |                              |
   *     |                              |
   *     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
   * 
   */
  static async runParallax(fxInfo, _scrollY, forceTransform) {
      
    const { _el, depth, mdepth, _transformed } = fxInfo
    const depthRatio = inMobile ? 1 - mdepth : 1 - depth

    //if there's no container, bail out
    const parentNode = _el.parentNode
    if(!parentNode) {
      return
    } 

    fxInfo._scrollY = _scrollY

    //This is an asynchronous function, so that means that when we're running it a bunch of times, t1 t2 t3...tn
    //we're on t2 even though t1 could still be grinding away in the background (await getBoundingClientRect)
    if(fxInfo._motion) {
      return
    }    
    //else, we're going to bail out if the parallax area is not intersecting the viewport.
    //no need to waste painting resources if it's not visible on the screen.
    else {
      if(!parentNode[DOMEL_PROP_INTERSECT] && !forceTransform) {
        return
      }
    } 

    //Get the scroller y value so that we can transform the coordinate system. The trouble
    //is that it's not super-clear if there's forced reflows that happen with this function.
    //I tried a few other things but nothing ever panned out. This worked the best.
    let scrollerYValue = Parallax.getWindowYScroll()

    if(!_transformed || forceTransform) {

      fxInfo._motion = true
      fxInfo._transformed = true

      //apply the bounds cache. This is from the animation(Lock/Unlock)Bounds, which happens
      //when the element is animated.
      let { top, width } = fxInfo.boundsCache || await getBoundingClientRect(parentNode, false)

      let wHeight = getViewportHeight()

      scrollerYValue = Parallax.getWindowYScroll()
      const yCoord = top + scrollerYValue

      //get the dimensions of the image. we're using the filter function to make sure that
      //if the image hasn't loaded and if the width and height are 0, then those bounds won't get cached.

      //Get the bound. if we're in a background scenario, then use the dimensions that were fed in.
      //else, use the width and height from the image element.
      let background = fxInfo.background 
      let imgWidth = 0 
      let imgHeight = 0

      if(background) {
        imgWidth = background.width
        imgHeight = background.height
        _el.style.width = imgWidth+"px"
        _el.style.height = imgHeight+"px"
      } else {
        imgWidth = _el.width
        imgHeight = _el.height
      }
      /////////////////////////

      //bail out if the image is 0-height, meaning it hasn't loaded yet.
      if(imgHeight == 0) {
        fxInfo._motion = false
        return
      }

      //adjust the scale of the image. there are two prerogatives in play here. 
      //1: the image needs to be as high as the viewport.
      //2: the image needs to be as wide as the parent container.
      //needs to at least match both. It can be bigger than either one, but it has to be
      //big enough to match both.
      let scaleAmount = wHeight / imgHeight

      //if we've scaled up the image, but it still isn't big enough to cover the width of the 
      //container element, then we need to increase the width until it does cover it
      const scaledImgWidth = scaleAmount*imgWidth
      if(width > scaledImgWidth) {
        scaleAmount = width/imgWidth
      }

      fxInfo._scale = parseFloat(scaleAmount.toFixed(2))
      fxInfo._yPosition = yCoord
      _el.style.transformOrigin = 'left top'

      fxInfo._motion = false
    }

    //different way of doing this, using the matrix. Not sure if this faster
    //matrix(scaleX(),skewY(),skewX(),scaleY(),translateX(),translateY())
    const pY = fxInfo._yPosition
    let finalYPosition = -(pY - scrollerYValue)*depthRatio

    //Handy for debugging
    //console.log("parallax tick pY:"+fxInfo._yPosition+" scrollerY:"+scrollerYValue+" depthRatio:"+depthRatio+" finalY:"+finalYPosition)

    finalYPosition = parseFloat(finalYPosition.toFixed(2))
    _el.style.transform = `translateY(${finalYPosition}px) scale(${fxInfo._scale})`
    
  }


  static async runParallaxChain(scrollY, forceTransform) {
    let fxInfo = Parallax.firstParallax
    while(fxInfo) {
      await Parallax.runParallax(fxInfo, scrollY, forceTransform)
      fxInfo = fxInfo.next
    }
  }

  static async debouncer(value) {
    if(!Parallax.dInterval) {
      await Parallax.runParallaxChain(value, true)

      Parallax.dInterval = setInterval(async() => {
        if(Parallax.dCache !== null) {
          await Parallax.runParallaxChain(Parallax.dCache, true)
          Parallax.dCache = null
        } else {
          clearInterval(Parallax.dInterval)
          Parallax.dInterval = null
        }
      },500)
      
    } else {
      Parallax.dCache = value
    }
  }

  static screenResized() {
    const scrollY = Parallax.getWindowYScroll()
    Parallax.debouncer(scrollY)
  }


  static async forceRefresh() {
    const scrollY = Parallax.getWindowYScroll()
    await Parallax.runParallaxChain(scrollY, true)
  }


  static async screenScrolled() {
    const scrollY = Parallax.getWindowYScroll()
    Parallax.runParallaxChain(scrollY)
  }

  //Great trick for this.
  //https://www.digitalocean.com/community/tutorials/implementing-a-scroll-based-animation-with-javascript
  static async mobileTick() {
    const newY = Parallax.getWindowYScroll()
    const oldY = Parallax.scrollY

    const diff = newY - oldY

    // `delta` is the value for adding to the `current` scroll position
    // If `diff < 0.1`, make `delta = 0`, so the animation would not be endless
    //const delta = Math.abs(diff) < 0.1 ? 0 : diff * 0.6
    const abs = Math.abs(diff)
    let ease = 0
    if(abs > 50) {
      ease = 0.9
    } else 
    if(abs < 0.1) {
      ease = 0
    } else {
      ease = (abs/50)*0.9
    }

    const delta = diff*ease

    if(delta) {
      const scY = oldY + delta
      Parallax.scrollY = scY 
      await Parallax.runParallaxChain(scY)
    } else {
      cancelAnimationFrame(Parallax.animationFrameID)
      Parallax.animationFrameID = null
    }

  }


  static startMobileBehavior() {
    if(!Parallax.animationFrameID) {
      const doScroll = () => {
        Parallax.mobileTick()
        Parallax.animationFrameID = requestAnimationFrame(doScroll)
      }
      Parallax.animationFrameID = requestAnimationFrame(doScroll)
    }
  }


  static addListeners() {
    if(Parallax.inBrowser && !Parallax.listenersAdded) {
      Parallax.listenersAdded = true

      if(wCtx) {
        const showAreasDebug = Parallax.configHelper.getSetting("debug.showAreas")
        Parallax.scroller = new Scroller({showAreasDebug})

        if(inMobile) {
          document.body.addEventListener('touchmove', Parallax.startMobileBehavior, { passive:true })
        } else {
          wCtx.addEventListener('resize', Parallax.screenResized, { passive:true })
          wCtx.addEventListener('scroll', Parallax.screenScrolled, { passive:true })    
        }
      }
      Parallax.screenResized()
      Parallax.pollScreenSize()

    }
  }

  static pollScreenSize() {
    if(!Parallax.heightChange) {

      //fetch some settings from the config
      const pollInterval = Parallax.configHelper.getSetting("height.poll.interval")
      Parallax.heightChangeLimit = Parallax.configHelper.getSetting("height.poll.behavior.stableThreshold")
      const heightContainerQuery = Parallax.configHelper.getSetting("height.query")

      const main = document.querySelector(heightContainerQuery)

      Parallax.heightChange = setInterval(async() => {

        const prevY = Parallax.heightChangeSavedY
        const bounds = await getBoundingClientRect(main) 
        const wH = bounds.height
        Parallax.heightChangeSavedY = wH

        if(prevY == wH) {

          //only do this if we haven't refreshed yet.
          if(!Parallax.heightChangeRefreshed) {
          
            Parallax.heightChangeStack++
          
            if(Parallax.heightChangeLimit >= Parallax.heightChangeStack) {
              Parallax.heightChangeRefreshed = true
              Parallax.heightChangeStack = 0
              Parallax.screenResized()
              // clearInterval(Parallax.heightChange)
              // Parallax.heightChange = true
            }

          }

        } 
        
        //if they're not equal, then reet the stack and the refreshed state
        else {
          Parallax.heightChangeStack = 0
          Parallax.heightChangeRefreshed = false
        }

      }, pollInterval)
    }
  }

  static removeScreenSizePoll() {
    if(Parallax.heightChange) {
      clearInterval(Parallax.heightChange)
      Parallax.heightChange = null
    }
  }


  static removeListeners() {
    if(Parallax.inBrowser && Parallax.listenersAdded) {
      Parallax.listenersAdded = false

      if(wCtx) {

        if(Parallax.scroller) {
          Parallax.scroller.destroy()
          Parallax.scroller = null
        }

        if(inMobile) {
          document.body.removeEventListener('touchmove', Parallax.startMobileBehavior)
          if(Parallax.animationFrameID) {
            cancelAnimationFrame(Parallax.animationFrameID)
            Parallax.animationFrameID = null
          }
        } else {
          wCtx.removeEventListener('resize', Parallax.screenResized)
          wCtx.removeEventListener('scroll', Parallax.screenScrolled)     
        }

        Parallax.removeScreenSizePoll()

      }

    }
  }



/////////////
//         //
//  A P I  //
//         //
/////////////

  /**
   * @param {*} obj 
   * height: {
   *   query:"main",
   *   poll: {
   *     interval:500,
   * 
   *     //Run the poll continuously. 
   *     //When the height stops changing, wait 3 ticks to make sure it stopped changing before 
   *     //refreshing the parallaxes
   *     //The idea here is that whenever something on the page loads, the height of the document is 
   *     //generally going to change. And that will affect the parallax arithmetic. So we watch 
   *     //for changes in height.
   *     behavior: {
   *       type:"continuous"
   *       stableThreshold: 3
   *     }
   * 
   *     //wait for the height to grow once. Wait at least 
   *     //10 ticks. Then wait 5 ticks for it to stablize
   *     behavior: {
   *       type:"normal",
   *       min: 10,
   *       stableThreshold: 5
   *     }
   * 
   *   }
   * }
   * 
   */
  static config(obj) {
    if(Parallax.inBrowser) {
      Parallax.configHelper.setSettings(obj)
    }
  }



  /**
   * @param {string, DOMNode} querySelectorOrNode (required) the query selector or element to trigger the scroll effect.
   * 
   */
  register(querySelectorOrNode, fxOptionsObj) {

    if(this.destroyed) {
      return
    }
    
    if (Parallax.inBrowser) {

      let whichOnes = typeof querySelectorOrNode == "string" 
        //if string, then we'll treat it as a query selector
        ? document.querySelectorAll(querySelectorOrNode)
        //else, treat it as a node
        : [querySelectorOrNode]

      const len = whichOnes.length
      for (let i = 0; i < len; i++) {

        const element = whichOnes[i]          
        const fxDataString = element.getAttribute("data-parallaxfx")
        let fxInfo = null 

        if(fxDataString) {
          fxInfo = JSON.parse(fxDataString)
        } else 
        if(fxOptionsObj) {
          fxInfo = fxOptionsObj
        }

        fxInfo.plx = this

        if (!fxInfo) {
          throw new Error(
            "Whoops. Malformed animation data for this element: " + fxDataString + " here's the querySelectorOrNode: " + querySelectorOrNode
          )
        }

        if(!fxInfo.depth) {
          fxInfo.depth = 0.05
        }
        if(!fxInfo.mdepth) {
          fxInfo.mdepth = 0.15
        }

        fxInfo._el = element  
        fxInfo.prev = null 
        fxInfo.last = null
        element[DOMEL_PROP0] = fxInfo

        //Add in the listener
        Parallax.addParallaxToChain(fxInfo)

        //add this in for intersection.
        Parallax.scroller.register(element.parentNode, {showAreasDebug:true, "th":0.1, type:"simpleAsync", 
          //if the 
          async intersectOn() {
            element.parentNode[DOMEL_PROP_INTERSECT] = true
            await Parallax.forceRefresh()
          },
          async intersectOff() {
            element.parentNode[DOMEL_PROP_INTERSECT] = false
            await Parallax.forceRefresh()
          }
        })
      

      }
      
    }
    
  }


  /**
   * @param {string, DOMNode} querySelectorOrNode (required) the query selector or element to trigger the scroll effect.
   * 
   * This will just erase the info object attached to the dom-node.
   * This is specifically meant for our lazy-loader component so that it behaves properly
   * when you navigate from one page to another.
   * 
   */
  destroy() {

    if(this.destroyed) {
      return
    }
    this.destroyed = true
    
    if (Parallax.inBrowser) {
      let fxInfo = Parallax.firstParallax
      while(fxInfo) {
        const next = fxInfo.next
        if(fxInfo.plx == this) {
          Parallax.removeParallaxFromChain(fxInfo)          
        }
        fxInfo = next
      }

    }
    
  }

}


export default Parallax