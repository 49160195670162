export const routes = [
  {
    name: 'root',
    path: '/',
    component: () => import(/* webpackChunkName: "root" */'@pages/Home.vue'),
    meta: {
      navColors: {
        navText: "dark-blue",
        navOptions: "deep-grey-green"
      }
    }
  },
  {
    name: 'services',
    path: '/services',
    component: () => import(/* webpackChunkName: "root" */'@pages/Services.vue'),
    meta: {
      navColors: {
        navText: "dark-blue",
        navOptions: "deep-grey-green"
      }
    }
  },
  {
    name: 'typography',
    path: '/typography',
    component: () => import(/* webpackChunkName: "root" */'@pages/Typography.vue'),
    meta: {
      navColors: {
        navText: "dark-blue",
        navOptions: "deep-grey-green"
      }
    }
  },
]