
<script>
import { defineComponent, computed, watch, ref, onUnmounted, onMounted } from 'vue'
import { useStore } from 'vuex'
import anime from "animejs"
import { addEvent, removeEvent } from '@kit/utils/EventBus'
import { bodyScrollOn, bodyScrollOff } from '@kit/utils/Scroller'
import { mergeClassesTheme } from '@kit/utils/Formats'

/*The theme is really simple:

  {
    //the height of the top nav. This will be sized to accomdate it.
    topNavHeight: "90px",

    //the mergeable classes for the outer wrapper.
    outerClasses: "some-class"

    //the mergeable classes for the inner wrapper
    innerClasses: "some-class" 
  }

*/


export default defineComponent({
  name: "SizeNavDrawerBase",
  props:["theme"],
  setup(props) {
    const store = useStore()
    const sideDrawer = ref(null)

    const drawerOut = computed(() => {
      return store.state.drawerOut
    })


    let d0,d1,d2=null

    onMounted(() => {

      d0 = addEvent('navigateToCurrentPage', () => {

        //If the drawer is in, then we're going to set it back out
        //and if necessary, turn the body scroll back on
        if(drawerOut.value == true) {
          sideDrawer.value.style.transform = "translateX(-100%)"
          bodyScrollOn()
          store.commit("setDrawerOut", false) 
          sideDrawer.value.setAttribute("aria-hidden", true)
          sideDrawer.value.setAttribute("tab-index", -1)
        }
      })

      d1 = addEvent('navigateToNewPage', ({ resetScrollPosition }) => {

        //If the drawer is in, then we're going to set it back out
        //and if necessary, turn the body scroll back on
        if(drawerOut.value == true) {
          sideDrawer.value.style.transform = "translateX(-100%)"
          if(resetScrollPosition) {
            bodyScrollOn()
          }
          store.commit("setDrawerOut", false)
          sideDrawer.value.setAttribute("aria-hidden", true) 
          sideDrawer.value.setAttribute("tab-index", -1)
        }
      })
  
      d2 = addEvent("drawerMove", (outOrIn) => {
        slide(outOrIn)
      })

    })


    onUnmounted(() => {
      removeEvent(d0)
      removeEvent(d1)
      removeEvent(d2)
    })

    //Listen for state changes
    const inMobile = computed(() => {
      return store.state.inMobile
    })
    watch(inMobile, (newVal, oldVal) => {
      if(!newVal && oldVal) {
        sideDrawer.value.style.transform = "translateX(-100%)"
        bodyScrollOn()
        store.commit("setDrawerOut", false) 
        sideDrawer.value.setAttribute("aria-hidden", true)
        sideDrawer.value.setAttribute("tab-index", -1)
      }
    })

    let slideCache = null
    let inMotion = false

    const slide = (slideOutOrIn) => {

      if(inMotion) {
        slideCache = slideOutOrIn
        return
      } 

      inMotion = true

      if(slideOutOrIn) {
        store.commit("setDrawerOut", true)
      } else {
        store.commit("setDrawerOut", false)
      }

      //It looks way better do it right when then thing starts animating
      if(slideOutOrIn) {
        bodyScrollOff({ saveScroll:true, correctRight:true })
      } else {
        bodyScrollOn()
      }

      const obj = {
        targets: sideDrawer.value,
        duration: 500,
        easing: "easeInOutQuad",
        complete: (_an) => {
          if(slideOutOrIn) {
            sideDrawer.value.setAttribute("aria-hidden", false)
            sideDrawer.value.setAttribute("tab-index", 0)
          } else {
            sideDrawer.value.setAttribute("aria-hidden", true)
            sideDrawer.value.setAttribute("tab-index", -1)
          }
          inMotion = false
          if(slideCache !== null) {
            const cachedValue = slideCache 
            slideCache = null
            slide(cachedValue)
          }
        }
      }
      if(slideOutOrIn) {
        obj.translateX = '0%'
      } else {
        obj.translateX = '-100%'
      }
      anime(obj)
    }

    const getInitialOuterStyle = () => {
      return `top:${props.theme.topNavHeight}; transform:translateX(-100%); `
    }

    return { sideDrawer, inMobile, drawerOut, mergeClassesTheme, getInitialOuterStyle }
  },
});
</script>

<style>

.side-nav-drawer-outer {
  background-color: white;
  position:fixed; 
  top:90px; 
  right:0px; 
  left:0px; 
  bottom:0px; 
  z-index:101;
}

</style>

<template>
  <div ref="sideDrawer" 
  id="SideNavDrawer_sideDrawer" 
  aria-hidden="true" 
  tab-index="-1" 
  :class="mergeClassesTheme('side-nav-drawer-outer', theme.outerClasses)" 
  :style="getInitialOuterStyle()">
    
    <div class="sb sb-v sb-content-strict-height sb-g20 sb-greedy-1">
      <nav id="_sideDrawerScrollingElement" :class="mergeClassesTheme('sb sb-v sb-greedy', theme.innerClasses)">
        <slot name="nav-items" v-bind="{ active:drawerOut }" ></slot>
      </nav>
    </div>

  </div>
</template>