/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\

  Handy sleep function
  
  Author Alex Lowe

\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * 
 * @param {integer} ms Required. The number of ms to sleep
 * @param {*} cancelable Optional. The object which will get an cancel function placed on it
 *              to cancel the sleep, should it be necessary to do so.
 * @returns 
 */
export const sleep = (ms, cancelable) => {
  const obj = cancelable || {}
  return new Promise(
    (resolve) => {

      obj.cancel = () => {
        resolve()
      }

      return setTimeout(resolve, ms)
  });
}