export const modifyState = () => {
  return { account: null }
}
export const modifyMutations = () => {
  return {
    setAccount(state, account) {
      state.account = account
    }
  }
}
export const modifyActions = () => {
  return {
    setAccount({commit}, account) {
      commit("setAccount", account)
    },
  }
}
