/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\

  Animation-related utilities

  Author Alex Lowe

\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import anime from 'animejs'
import { sleep } from '@kit/utils/Sleep'
import Parallax from '@kit/utils/Parallax'


/**
 * Animation helper, so you can do 
 * await animate(someOptionsHere)
 * 
 * @param {*} animationObj 
 * @returns 
 * 
 */
export const animate = (animationObj) => {
  return new Promise((resolve, _reject) => {
    animationObj.complete = (_a) => {
      resolve()
    }
    anime(animationObj)
  })
}


class AnimationGroup {
  constructor(arr, inSeq) {

    //if the group was started with some items in there already, then we're going to 
    //make sure that they know about their ancestry.
    if(arr) {
      for(let i=0; i<arr.length; i++) {
        const obj = arr[i]
        if(!obj._isGroup) {
          throw new Error("I really meant for other groups to be listed here.")
        }
        obj.setParent(this)
      }
    }

    this._arr = arr || []
    this._inSeq = inSeq
    this._isGroup = true
    this._numComplete = 0
    this._parent = null
    this._onComplete = null
    this._data = {}
    this._state = {}
  }
  setParent(parent) {
    this._parent = parent
  }
  setData(data) {
    this._data = data
  }
  getState() {
    return this._state
  }
  getStateCompleted(key) {
    return this._state[key] == "completed"
  }
  getStateMotion(key) {
    return this._state[key] == "motion"
  }
  getStateNotStarted(key) {
    return !this._state[key]
  }

  updateStateCompleted(name) {
    if(name) {
      this._state[name] = "complete"
    }
  }
  updateStateStarted(name) {
    if(name) {
      this._state[name] = "motion"
    }
  }

  informCompletion() {
    if(this._parent) {
      this._parent.elementCompleted(this._data.name)
    }
    if(this._onComplete) {
      this._onComplete(this._data)
    }
  }
  informStart() {
    if(this._parent) {
      this._parent.elementStarted(this._data.name)
    }
  }
  elementCompleted(name) {
    this.updateStateCompleted(name)

    this._numComplete++
    if(this._numComplete == this._arr.length) {
      this.informCompletion()
    }
  }
  elementStarted(name) {
    this.updateStateStarted(name)
  }
  onComplete(onComplete) {
    this._onComplete = onComplete
  }


  sequence() {
    this._inSeq = true
  }
  parallel() {
    this._inSeq = false
  }
  hurry(hurryRatioOrCallback, depth) {
    const d = depth || 0
    const arr = this._arr
    for(let i=0; i<arr.length; i++) {
      const obj = arr[i]
      obj.hurry(hurryRatioOrCallback,d+1)
    }
  }
  pause() {
    const arr = this._arr
    for(let i=0; i<arr.length; i++) {
      const obj = arr[i]
      obj.pause()
    }  
  }
  resume() {
    const arr = this._arr
    for(let i=0; i<arr.length; i++) {
      const obj = arr[i]
      obj.resume()
    }  
  }
  append(animationOptionsOrGroup) {
    if(animationOptionsOrGroup._isGroup) {
      this._arr.push(animationOptionsOrGroup)
      animationOptionsOrGroup.setParent(this)
    } else {
      const an = new Animation(animationOptionsOrGroup)
      an.setParent(this)
      this._arr.push(an)
    }
  }
  async go() {
    this.informStart()
    const arr = this._arr
    const inSeq = this._inSeq
    for(let i=0; i<arr.length; i++) {
      const obj = arr[i]
      if(obj._isGroup) {
        if(inSeq) {
          await obj.go()
        } else {
          obj.go()
        }
      } else {
        if(inSeq) {
          await obj.goSeq()
        } else {
          obj.goPar()
        }
      }
    }
  }

}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\

  //two arrays of divs
  const animationGroup1 = [d0,d1,d2,d3,d4,d5]
  const animationGroup2 = [d6,d7,d8,d9,d10,d11]

  //make a sequence group and parallel group
  const group1 = Animation.sequence()
  const group2 = Animation.parallel()

  for(let i=0; i<animationGroup1.length; i++) {
    group1.append({
      targets:animationGroup1[i].value,
      translateY:'200px',
      duration:5000,
      easing:'linear',

      //you can add extra data-fields in here
      myname:"Animation group 1"
    })
  }
  for(let i=0; i<animationGroup2.length; i++) {
    group2.append({
      targets:animationGroup2[i].value,
      translateY:'200px',
      duration:5000,
      easing:'linear',

      //you can add extra data-fields in here
      myname:"Animation group 2"
    })
  }

  //run the two groups together as a sequence
  const group12 = Animation.sequence([group1,group2])
  group12.onComplete(() => {
    console.log("Animation is complete!!")
  })
  group12.go()

  // demonstration 1: hurry after 2 seconds
  // await sleep(2000)
  // group12.hurry(0.05)

  // demonstration 2: wait, pause, wait some more, then resume
  // await sleep(4300)
  // group12.pause()
  // await sleep(3000)
  // group12.resume()

  // demonstration 3: wait, pause, wait some more, then hurry
  await sleep(5000)
  group12.pause()
  await sleep(3000)
  group12.hurry(0.03)

  // demonstration 4: wait, pause, wait some more, then hurry,
  // but this time we're going to use a callback to return a more 
  // nuanced hurry ratio based on the information in the animation object.
  // We could also use the depth in the tree. depth is injected into the info 
  // that's made available for the callback.
  await sleep(5000)
  group12.pause()
  await sleep(3000)
  group12.hurry(0.03)
    group12.hurry(({depth, myname}) => {
    console.log("Ok here's the depth in the animation tree and the name: "+depth+" "+myname)
    return myname == "Animation group 1" ? 0.1 : 0.5
  })
  

\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export class Animation {
  constructor(animationOptions) {
    this._animeObj = null
    this._animationOptions = animationOptions
    this._hurryRatio = 0
    this._inMotion = false
    this._hurryOptionsOnStart = null
    this._parent = null
  }

  setParent(parent) {
    this._parent = parent
  }
  informCompletion() {
    if(this._parent) {
      this._parent.elementCompleted(this._animationOptions.name)
    }
  }
  informStart() {
    if(this._parent) {
      this._parent.elementStarted(this._animationOptions.name)
    }
  }
  
  async goSeq() {
    this.informStart()
    const ops = this._hurryOptionsOnStart || this._animationOptions

    //if there was a complete event listed in the options, then we want that 
    //to get called as well. 
    const currentOnComplete = ops.complete

    //if we're supposed to skip this one, then just set the _inMotion state
    //and do the informCompletion.
    if(ops._skipThisOne) {
      this._inMotion = false
      this.informCompletion()
      if(currentOnComplete) {
        currentOnComplete()
      }
    } 
    
    //else perform the animation within a promise, set the states and the 
    //do the informCompletion up end.
    else {

      await new Promise((resolve,_reject) => {
        ops.complete = () => {
          resolve()
          this._inMotion = false
          this.informCompletion()
          if(currentOnComplete) {
            currentOnComplete()
          }
        }
        this._inMotion = true
        this._animeObj = anime(ops)
      })

    }
  }

  goPar() {
    this.informStart()
    const ops = this._hurryOptionsOnStart || this._animationOptions

    //if there was a complete event listed in the options, then we want that 
    //to get called as well. 
    const currentOnComplete = ops.complete

    //if we're supposed to skip this one, then just set the _inMotion state
    //and do the informCompletion.
    if(ops._skipThisOne) {
      this._inMotion = false
      this.informCompletion()
      if(currentOnComplete) {
        currentOnComplete()
      }
    } 
    
    //else perform the animation within a promise, set the states and the 
    //do the informCompletion up end.
    else {

      this._inMotion = true
      ops.complete = () => {
        this._inMotion = false
        this.informCompletion()
        if(currentOnComplete) {
          currentOnComplete()
        }
      }
      this._animeObj = anime(ops)

    }
  }

  pause() {
    const animeObj = this._animeObj
    const inMotion = this._inMotion
    if(inMotion) {
      animeObj.pause()
    }
  }

  resume() {
    const animeObj = this._animeObj
    const inMotion = this._inMotion
    if(inMotion) {
      animeObj.play()
    }  
  }

  hurry(hurryRatioOrCallback, depth) {

    const inMotion = this._inMotion
    const animationOptions = this._animationOptions
    const animeObj = this._animeObj
    const currentDuration = animationOptions.duration
    const currentTime = animeObj ? animeObj.currentTime : 0

    const d = depth || 0 
    animationOptions.depth = d
    const hurryRatio = typeof hurryRatioOrCallback == "number" ? hurryRatioOrCallback : hurryRatioOrCallback(animationOptions)
    const h = hurryRatio


    //if the animation is in motion, then we're going to pause it.
    if(inMotion) {
      animeObj.pause()
    }

    //if the hurry ratio is less than 1, we're going to 
    //just skip the rest of the animation.
    if(h < 0) {
      //if it's already in motion, skip to the end
      if(inMotion) {
        animeObj.seek(currentDuration)
      }
      //else if it hasn't started, we're going to set the skip flag
      else {
        this._hurryOptionsOnStart = { _skipThisOne:true }
      }
    } else 

    //if there's no hurry ratio or it's 0, then we're going to just skip right to the end
    if(!h) {

      if(inMotion) {
        animeObj.seek(currentDuration)
      } else {
        this._hurryOptionsOnStart = {...animationOptions, duration:0 }
      }

    } else {

      //if h is a ratio, then we're going to treat it as
      //a ratio of however much time is left on the animation.
      //
      //if h is greater than 1, we're going just treat it like the new duration
      const newDuration = h < 1 ? (currentDuration - currentTime)*h : h

      //if the animation is in motion, then we're going to restart it with the new hurry duration
      if(inMotion) {
        anime({...animationOptions, duration:newDuration })
      } 
      
      //else, the animation wasn't started yet, so in that case we're just going to assign
      //the hurryOptions, which will take precedence for when the animation does start.
      else {
        this._hurryOptionsOnStart = {...animationOptions, duration:newDuration }
      }

    }
  }

  static sequence(arrayOfAnimateables) {
    return new AnimationGroup(arrayOfAnimateables, true)
  }
  static parallel(arrayOfAnimateables) {
    return new AnimationGroup(arrayOfAnimateables, false)
  }

}



/**
 * Start up a collection of "one at a time" (OA2T) actions,
 * or invoke one of the actions.
 * 
 * This will perform one of a collection of actions. It will 
 * wait until that action is done, and then if another action 
 * invoked in the meantime, it will run that action.
 * 
 * @param {arguments} Required. you list out the action objects. 
 * they go like this:
 * {
 *   name: "myAction",
 *   async action() {
 *    await animate(someStuff)
 *   }
 * } 
 *    Here's the description:
 * 
 *    @param {string, required} name 
 *    The name of the action within the action-set to run.
 * 
 *    @param {function (async), required} action 
 *    The action being performed. This will receive whatever arguments you passed into the actionArgs 
 *    parameter of the actionOA2T function.
 * 
 *    @param {function, default} element 
 *    This will return an element of your choice being handled by the action. Also receives the 
 *    parameters that you passed into the actionArgs parameter of the actionOA2T function. 
 * 
 *    So here's the idea. You might have like 20 pretty pictures that you want to animate when 
 *    you roll-over them. But how is the action supposed to tell which from which for caching 
 *    purposes? Like if you're rolling over a bunch at a time? Well easy, you return the dom-element
 *    for the picture in the "element" function of the options. It will then store all the caching
 *    info on that dom element.
 * 
 * @returns {Object} actionSet
 * 
 *
 */

const _OA2T_actionName = 'ugure83'
const _OA2T_actionArgs = '0o4937s'
const _OA2T_inMotion   = 'jjdg62d'
const _OA2T_current    = '8vej932'

/**
 * 
 * @param {Array, required} actions. The array of action object. Each one is like this:
 * name:"MyACtion",
 * element(argsObj) {
 *  return argsObj
 * },
 * async action(argsObj) {
 *  await animate(argsObj)
 * }
 * 
 * @param {Object, optional} options. The object of settings.
 * Currently, you can do this:
 * { dualSet: true }
 * And that will make this thing have nice "toggle" behavior
 *  
 * @returns {Object} the action-set to use with all of the other functions.
 * 
 */
export const actionSetOA2T = (actions, options) => {
  const len = actions.length
  const newSet = { inMotion:false, cache:null, isActionSet:true, cargs:null, options }

  for(let i=0; i<len; i++) {
    const obj = actions[i]
    const { name } = obj
    newSet[name] = obj.action
  }

  if(options && options.dualSet) {
    if(actions.length != 2) {
      throw new Error("action-set is marked as dualSet, but I don't see two elements in here.")
    }
    newSet.dualSet = true
    newSet.dualAction0 = actions[0].name
    newSet.dualAction1 = actions[1].name   
  }

  return newSet
}
const getActionNameOA2T = (actionSet, actionArgs) => {
  const ctx = _actionOA2TContext(actionSet, actionArgs)
  return ctx[_OA2T_current]
}

const _actionOA2TContext = (actionSet, actionArgs) => {
  const { options } = actionSet
  const elCallback = options ? options.element || null : null  
  if(elCallback) {
    const el = elCallback.apply(this, actionArgs)
    if(!el) {
      throw new Error("_actionOA2TGetCache: no element came from element callback")
    }
    return el
  } else {
    return actionSet
  }
}


const _setActionNameOA2T = (actionSet, actionArgs, actionName) => {
  const ctx = _actionOA2TContext(actionSet, actionArgs)
  return ctx[_OA2T_current] = actionName
}

const _actionOA2TSetCache = (actionSet, actionName, actionArgs) => { 
  const ctx = _actionOA2TContext(actionSet, actionArgs)
  ctx[_OA2T_actionName] = actionName 
  ctx[_OA2T_actionArgs] = actionArgs
}

const _actionOA2TGetCache = (actionSet, actionArgs) => {
  const ctx = _actionOA2TContext(actionSet, actionArgs)
  return {cname:ctx[_OA2T_actionName], cargs:ctx[_OA2T_actionArgs]}
}

const _actionOA2TGetMotion = (actionSet, actionArgs) => {
  const ctx = _actionOA2TContext(actionSet, actionArgs)
  return !!ctx[_OA2T_inMotion]
}

const _actionOA2TSetMotion = (actionSet, actionArgs, motion) => {
  const ctx = _actionOA2TContext(actionSet, actionArgs)
  ctx[_OA2T_inMotion] = motion
}


/** 
 * @param {Object, Required} actionSet 
 *    The action-set object returned by a call of actionSetOA2T
 *   
 * @param {String, Required} actionName
 *    The name of the action to invoke.
 * 
 * @param {Array, Optional} actionArgs
 *    The name of the arguments for the action function.
 * 
 */
export const actionOA2T = async(actionSet, actionName, actionArgs) => {

  const action = actionSet[actionName]

  //perform the dual-set behavior
  if(actionSet.dualSet) {

    //get the last action to run
    const lastAction = getActionNameOA2T(actionSet, actionArgs)
    //get the dual action for this action-name. e.g. if the action is "in", then the dual action is "out"
    const dualAction = actionName == actionSet.dualAction0 ? actionSet.dualAction1 : actionSet.dualAction0

    //Some tricky mechanics.
    //So we rollon the thing.
    //But while its animating rollon, we rolloff the thing.
    //So now rolloff is cached. Thing still animating rollon.
    //The we rollon the thing.
    //    If the thing is still animating rollon, it means rolloff is cached.
    //        -> In this case we're going to just remove the rolloff from the cache.
    //    If rolloff is not cached, its because its animating rolloff.
    //        -> In this case, rollon will be cached further down in the function. 
    const { cname } = _actionOA2TGetCache(actionSet, actionArgs)
    if(cname == dualAction) {
      _actionOA2TSetCache(actionSet, null, actionArgs)
    }

    if(!lastAction || lastAction == dualAction) {
      //then allow the animation to happen
    } 
    //else, just return here and freeze it out. don't cache. It's a bit of  judgement call not to cache 
    //the action
    else {
      return
    }
  }
  
  //If there's no action for this name, then complain
  if(!action) {
    throw new Error("actionSet. There is no animation object for "+actionName)
  } else {

    //if the action is in motion, then we're going to cache whatever action
    //that's being asked for currently.
    if(_actionOA2TGetMotion(actionSet, actionArgs)) {
      _actionOA2TSetCache(actionSet, actionName, actionArgs)
    } else {  

      _setActionNameOA2T(actionSet, actionArgs, actionName)

      //else, we're free and clear so perform the action.
      //handle the inMotion state
      _actionOA2TSetMotion(actionSet, actionArgs, true)

        if(!actionArgs) {
          await action()
        } else {
          await action.apply(this, actionArgs)
        }

      _actionOA2TSetMotion(actionSet, actionArgs, false)

      //If we get to the end and there's something in the cache, it 
      //means that an action was requested while this one was running.
      //So zero out the cache and run that action, whatever it is.
      const { cname, cargs } = _actionOA2TGetCache(actionSet, actionArgs)
      if(cname) {
        _actionOA2TSetCache(actionSet, null, actionArgs)
        actionOA2T(actionSet, cname, cargs)
      }

    }

  }
   
}


/**
 * @param {DOMElement} domNode 
 * @param {String} flashColor 
 * @param {String} normalColor 
 */
export const flashBackground = async(domNode, flashColor, normalColor) => {
    domNode.style.backgroundColor = flashColor
    await sleep(100)
    domNode.style.backgroundColor = normalColor || '' 
}

/**
 * @param {DOMElement} domNode 
 * @param {String} flashColor 
 * @param {String} normalColor 
 */
export const flashFill = async(domNode, flashColor, normalColor) => {
  domNode.style.fill = flashColor
  await sleep(100)
  domNode.style.fill = normalColor || '' 
}






/**
 * This works in concert with the reveal css classes in the basic.css document
 * e.g. reveal_fadeIn_slideDown_20px. Those classes start the element out 
 * as unrevealed and this reveal function "reverses" it and and reveals it.
 * 
 * TODO. Things like translateY are hard-coded in here. Could accommodate different
 * reveal styles.
 * 
 */
export const reveal = async(element, skipAnimation, display, delay) => {
  element.style.display = display

  if(delay > 0) {
     await sleep(delay)
  }

  if(skipAnimation) {
    element.style.transform = "translateY(0)"
    element.style.opacity = 1
  } else {

    //We have to wait a bit because the elements dimensions will typically 
    //be changing at the time this is called.
    await sleep(200)
    
    //Lock the bounds so that any parallax contained in the element returns
    //the cached bounds that we're establishing here. The 50 in the second 
    //argument matches the translation. These reveal elements already have a
    //-50 translation from the css classes, so we have to account for that here.
    await Parallax.animationLockBounds(element, 50)
    await Parallax.forceRefresh()
    element.style.transform = 'translateY(-50px)'

    await animate({
      targets:element, 
      translateY:0,
      opacity:1,
      easing:"easeOutQuad",
      duration:300
    })

    Parallax.animationUnlockBounds(element)
    
    
  }
}