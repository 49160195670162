import { cmsasset } from '@kit/utils/Formats'

export const navbar = {

  //optional. if it will shrink on scroll
  scrollShrink: true, //false,

  outerContainerClasses:"ics-nav-outer ics-navbar-boxshadow--attop",

  shrinkColor:"rgba(255,255,255,1)",

  //backgroundColor:"rgba(35, 47, 107,0.5)",
  //backgroundColor:"rgba(255,255,255,0)",
  //backgroundColor: "linear-gradient(180deg, rgba(24,25,116,0.5) 0%, rgba(24,25,116,0.5) 70%, rgba(24,25,116,0) 100%)",
  backgroundColor: "linear-gradient(-90deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0.7) 60%, rgba(0,20,100,0.3) 100%)",

  //skip animation
  animateBackgroundChange:false,

  //initialColor:"rgba(35, 47, 107,0.5)",
  //initialColor:"rgba(255,255,255,0)",
  //initialColor: "linear-gradient(180deg, rgba(24,25,116,0.5) 0%, rgba(24,25,116,0.5) 70%, rgba(24,25,116,0) 100%)",

  hamburgerButtonClasses:"ics-nav-hamburger-nav-button--attop",

  //the logo and aria text
  logoWrapperClasses:"sb-explicit ics-logo-wrapper",
  //logoSrc:pathname("@images/ICS-Logo-Light-Version.png"),
  logoSrc:cmsasset('/2023/04/Praesidio-Icon_text-only-light.png'),
  logoAriaAlt:"Infinite Consulting Services navigation logo",

  //required
  height: "70px",
  shrinkHeight: "50px",

  padding: "15px",
  shrinkPadding: "5px",

  //optional. The top gap
  topGap: "0px"
    
}

export const sideDrawer = {
  topNavHeight:"90px",
  outerClasses:"ics-side-nav-drawer"
}