//Cancel out the annoying formats for the phone-number
//https://stackoverflow.com/questions/3736807/how-do-i-remove-the-blue-styling-of-telephone-numbers-on-iphone-ios
export const meta = (meta) => {
  meta.addMetaItem([
    //<meta name="format-detection" content="telephone=no">
    { name:'format-detection', content: 'telephone=no' },
  ])
}

export const extraJS = null

export const externalCSS = [
  'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;900&display=swap',
  'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css?ver=5.7.2',
  'https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@700&display=swap',
  "https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap"
]

